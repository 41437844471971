import React, { useContext, useState } from 'react'
import { IconButton, IconType, IButtonStyles, Spinner } from 'office-ui-fabric-react'
import { GraphContext } from '../../contexts/graphContext'
import { FirebaseContext } from '../../contexts/firebaseContext'
import multiDownload from 'multi-download'

interface IProps {
  styles?: IButtonStyles
}

export default function DownloadSpreadsheetsButton(props: IProps) {
  const { graphData, graphDataIsLoading, tableNames } = useContext(GraphContext)
  const { firebase } = useContext(FirebaseContext)
  const [isLoading, setIsLoading] = useState(false)

  const onClick = async () => {
    setIsLoading(true)
    try {
      const storageRef = firebase.storage().ref(`xlsxUploads/${graphData.uploadId}/sars-cov2-spreadsheets.zip`)
      const url = await storageRef.getDownloadURL()
      multiDownload([url])
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  return (
    <div>
      {!isLoading && (
        <IconButton
          iconProps={{
            iconName: 'Download',
            iconType: IconType.default
          }}
          styles={props.styles}
          onClick={onClick}
        />
      )}
      {isLoading && <Spinner styles={props.styles} />}
    </div>
  )
}
