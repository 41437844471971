import React, { useState, useEffect } from 'react'
import { Link, DetailsList, IColumn, SelectionMode, CheckboxVisibility } from 'office-ui-fabric-react'
import { IGraphDrug } from 'krogan-types'

interface IDrugTableProps {
  drugs: {
    [name: string]: IGraphDrug
  }
  onDrugSelected?: (item: any) => void
}

function getDrugTableColumns(onItemSelected?: (item: any) => void): IColumn[] {
  return [
    {
      key: 'Compound_Name',
      fieldName: 'Compound_Name',
      name: 'Compound',
      minWidth: 100,
      maxWidth: 160,
      data: 'string',
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      onRender: item => {
        return <Link onClick={() => onItemSelected && onItemSelected(item)}>{item.Compound_Name}</Link>
      }
    },

    {
      key: 'Drug_Status',
      fieldName: 'Drug_Status',
      name: 'Drug Status',
      minWidth: 100,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Activity_Description',
      fieldName: 'Activity_Description',
      name: 'Activity Description',
      minWidth: 150,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Activity_Effect',
      fieldName: 'Activity_Effect',
      name: 'Activity Effect',
      minWidth: 150,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Reference',
      fieldName: 'Reference',
      name: 'Reference',
      minWidth: 100,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Smiles',
      fieldName: 'Smiles',
      name: 'Smiles',
      minWidth: 100,
      data: 'string',
      isResizable: true,
      isPadded: true
    },

    {
      key: 'ZINC_ID',
      fieldName: 'ZINC_ID',
      name: 'ZINC ID',
      minWidth: 100,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Purchase_notes',
      fieldName: 'Purchase_notes',
      name: 'Purchase notes',
      minWidth: 100,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Source',
      fieldName: 'Source',
      name: 'Source',
      minWidth: 70,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Target_SpecificProtein_Process',
      fieldName: 'Target_SpecificProtein_Process',
      name: 'Target Specific Protein Process',
      minWidth: 50,
      data: 'string',
      isPadded: true
    }
  ]
}

function DrugTable(props: IDrugTableProps) {
  const { drugs, onDrugSelected } = props

  const drugItems = Object.keys(drugs).map(drugId => {
    return {
      key: drugId,
      name: drugId,
      ...drugs[drugId]
    }
  })

  const [columns, setColumns] = useState(getDrugTableColumns(onDrugSelected))
  useEffect(() => {
    setColumns(getDrugTableColumns(onDrugSelected))
  }, [onDrugSelected])

  return (
    <div className="info-table-outer">
      <div className="info-table">
        <DetailsList
          key={'details_list'}
          items={drugItems}
          columns={columns}
          selectionMode={SelectionMode.single}
          checkboxVisibility={CheckboxVisibility.hidden}
          onItemInvoked={onDrugSelected}
          onShouldVirtualize={() => false}
        />
      </div>
    </div>
  )
}

export default DrugTable
