import React, { useState, useContext } from 'react'
import './index.css'
import { Pivot, PivotItem, Link, Text, Image, ImageFit, Icon } from 'office-ui-fabric-react'
import { ThemeContext } from '../../contexts/theme'
import { legendRowTwo, legendRowOne, legendRowThree } from './SarsCov2Overlay'
import Legend from '../../components/Graph/Legend'
import * as LightStyles from './GraphStyles/light'
import * as DarkStyles from './GraphStyles/dark'

export interface IInfoContentProps {}

function InfoContent(props: IInfoContentProps) {
  const [tab, setTab] = useState('background')
  const { theme } = useContext(ThemeContext)

  const onLinkClick = (item?: PivotItem) => {
    if (item && item.props.itemKey) setTab(item.props.itemKey)
  }

  return (
    <div className="info-content">
      <Pivot
        selectedKey={tab}
        onLinkClick={onLinkClick}
        styles={{
          root: {
            display: 'flex',
            flexDirection: 'row',
            height: '20%'
          },
          itemContainer: {
            height: '80%'
          },
          link: {
            flex: 1,
            paddingTop: '8px',
            paddingBottom: '8px',
            backgroundColor: 'var(--neutralLight)',
            margin: 0
          },
          linkIsSelected: {
            flex: 1,
            paddingTop: '8px',
            paddingBottom: '8px',
            backgroundColor: 'var(--neutralLighter)',
            margin: 0
          }
        }}>
        <PivotItem itemKey={'background'} headerText="Background">
          <div className="info-background-body">
            <div className="info-header">Introduction</div>
            <div>
              <b>An interactive host-pathogen protein interaction network for SARS-CoV-2</b>: The protein-protein interaction (PPI) network
              presented here was generated through the joint efforts of the QCRG (
              <Link href="http://qbi.ucsf.edu/COVID-19" target="_blank">
                QBI Coronavirus Research Group
              </Link>
              ). This work and corresponding methods can be found in the Nature publication:{' '}
              <i>A SARS-CoV-2 Protein Interaction Map Reveals Targets for Drug-Repurposing;</i> co-corresponding authors: Nevan J. Krogan,
              Marco Vignuzzi, Adolfo García-Sastre, Kevan M. Shokat, Brian K. Shoichet (
              <Link target="_blank" href="https://www.nature.com/articles/s41586-020-2286-9">
                Nature Accelerated Article Preview
              </Link>
              ).
            </div>
            <div>
              In late 2019 the emergence and rapid spread of the novel coronavirus SARS-CoV-2 (causative agent of COVID-19), lead to
              widespread global infections and the World Health Organization (WHO) declared a pandemic on{' '}
              <Link
                href="https://www.who.int/dg/speeches/detail/who-director-general-s-opening-remarks-at-the-media-briefing-on-covid-19---11-march-2020"
                target="_blank">
                March 11, 2020
              </Link>
              . With no antiviral drugs and no available vaccine to combat the virus, countries around the world enacted physical distancing
              programs, the only proven defense for stalling the spread of infection and mitigating the catastrophic death rate. In addition
              to the devastating toll on human health, the profound cultural and economic effect of the pandemic and social distancing
              efforts highlighted an acute and urgent need for efficacious vaccines and drug treatment strategies. Quickly, investigators
              around the world began clinical trials of various drugs known to combat other pathogens with the hopes that one or more might
              treat COVID-19, though our limited molecular knowledge of SARS-CoV-2 pathogenesis obstructed these efforts. To devise
              effective therapeutic strategies that counteract SARS-CoV-2 infection and COVID-19 pathology, it is crucial to understand the
              molecular details for how the virus hijacks host cell machinery, and to apply this knowledge towards developing new drugs and
              repurposing existing ones.
            </div>
            <div>
              During infection, viral proteins interact with host products to promote their own replication. In parallel, sentinel programs
              in the host cell activate innate defenses that the cell uses to protect itself and prevent the spread of disease to other host
              cells. Therefore, physical viral-host protein-protein interactions (PPIs) represent a combination of dependency factors (host
              products the virus needs to replicate) and restriction factors (products the host uses to defend itself and prevent viral
              spread). Identifying SARS-CoV-2 viral protein interactors is thus an important first step to clarifying the molecular
              pathology of infection. Towards this end, our group individually cloned, tagged, and expressed SARS-CoV-2
              (2019-nCoV/USA-WA1/2020) proteins in human cells and identified the human proteins physically associated with each bait using
              a proteomics technique called affinity-purification mass spectrometry (AP-MS). In total, we captured and identified 332 high
              confidence host-pathogen PPIs for 27 (26 wild type and 1 mutant) SARS-CoV-2 bait proteins and map them in the interactive
              network provided here. Among these, we identify 66 druggable human proteins targeted by 69 compounds (29 FDA-approved drugs,
              12 drugs in clinical trials, and 28 preclinical compounds). Note that SARS-CoV-2 encodes 29 protein products, three of which
              we were unable to obtain high quality PPI data for, therefore they are not present in this network.
            </div>
            <div>
              In our interactive SARS-CoV-2 network all edges (blue and black lines) are clickable. Blue edges are based on our MS data and
              will provide interaction measurements from proteomics identifications and scoring. Black edges are human-human PPIs and are
              curated from{' '}
              <Link href="https://mips.helmholtz-muenchen.de/corum/" target="_blank">
                CORUM
              </Link>
              ,{' '}
              <Link href="https://www.ebi.ac.uk/intact/" target="_blank">
                IntAct
              </Link>
              , and{' '}
              <Link href="https://reactome.org/" target="_blank">
                Reactome
              </Link>{' '}
              (as of 2020-04). All <b>human prey nodes (grey and yellow circles)</b> are clickable and will provide information mined from{' '}
              <Link href="https://www.uniprot.org/" target="_blank">
                Uniprot
              </Link>
              ,{' '}
              <Link href="https://www.ebi.ac.uk/chembl/" target="_blank">
                CHEMBL
              </Link>{' '}
              and{' '}
              <Link href="https://www.guidetopharmacology.org/" target="_blank">
                IUPHAR
              </Link>{' '}
              databases (as of 2020-04). In cases where the human prey protein was identified as a drug target by the QCRG team, we have
              provided additional relevant drug targeting information and chemical structures. All{' '}
              <b>SARS-CoV-2 bait nodes (red diamonds)</b> are clickable and provide data mined from literature references and the{' '}
              <Link href="https://www.rcsb.org/" target="_blank">
                PDB
              </Link>{' '}
              (as of 2020-04). Note that all SARS-CoV-2 protein and gene functions described for each of the 26 wild type and single mutant
              baits are based on the functions of homologous genes from other coronavirus species (mainly SARS-CoV and MERS-CoV). An
              in-depth literature review for each SARS-CoV-2 bait subnetwork and the corresponding references can be found in the
              Supplemental Discussion of the publication above. The SARS-CoV-2 baits in this study were designed from sequence alignments
              provided by{' '}
              <Link href="https://www.tandfonline.com/doi/full/10.1080/22221751.2020.1719902" target="_blank">
                Chan et. al. 2020
              </Link>{' '}
              and{' '}
              <Link href="https://www.nature.com/articles/s41586-020-2008-3" target="_blank">
                Wu et. al. 2020
              </Link>
              , and though we are reasonably sure of the gene annotations, we want to be clear that not every protein has been verified to
              be expressed or functional during SARS-CoV-2 infections, either <i>in vitro</i> or <i>in vivo</i>.  We want to stress that
              this network represents the first step towards understanding the molecular interactions of SARS-CoV-2 and ongoing research is
              being done to fill in more structural, global, and mechanistic details.
            </div>

            <div className="info-header">Phosphoproteomics Analysis of SARS-CoV-2 Infected Cells</div>
            <div>
              In addition to hijacking the protein scaffolds and machinery inside the cell, viruses also rewire cellular signaling networks
              to promote an environment favorable for viral replication. This is driven in part by cellular <b>kinases</b> that relay
              important signals through <b>protein phosphorylation</b>. By characterizing the differences between normal and SARS-CoV-2
              infected cell signaling and phosphorylation dynamics, we can better understand the cellular pathways the virus needs to
              survive. Coupled with our SARS-CoV-2 protein-protein-interaction network, this data can be a powerful tool for understanding
              the virus on a molecular level.
            </div>
            <div>
              Following our PPI network study, we have pursued new research into the phosphorylation dynamics of host proteins at 0, 2, 4,
              8, 12, and 24 hours post SARS-CoV-2 infection in Vero E6 cells. A description of this study and the corresponding methods can
              be found in the Cell publication: The Global Phosphorylation Landscape of SARS-CoV-2 Infection; co-corresponding authors:
              Nevan J. Krogan, Pedro Beltrao, Danielle L. Swaney, Kevan M. Shokat, Jeffery R. Johnson, Marco Vignuzzi, Adolfo Garcia-Sastre,
              and Robert Grosse.
            </div>
            <div>
              Briefly, Vero E6 cells were infected with SARS-CoV-2 (MOI = 1.0) for one hour (t = -1 hour). After virus absorption, the 0
              hour samples were lysed while media for other samples was replaced and incubated for 2, 4, 8, 12, or 24 hours. Enriched
              phosphorylated peptides were analyzed by mass spectrometry. Log<sub>2</sub> fold changes and adjusted p-values were calculated
              by comparing each time point to the 0 hour mock infected control. In our network, proteins that are phosphorylated are
              represented with “p” in the center of the node. All corresponding phosphorylated residues with time-course data are visible
              upon clicking the interactive node.
            </div>
            <div>
              <i>
                *Note that we are constantly innovating new ways to integrate and visualize the proteomics and functional data acquired on
                SARS-CoV-2 baits and infected cells. New updates, features, and integrated datasets will be announced on this page.
              </i>
            </div>
            <div className="info-subheader">Network last updated: 2020-05-22</div>
          </div>
        </PivotItem>

        <PivotItem itemKey={'tutorial'} headerText="Tutorial">
          <div className="info-background-body">
            <div className="info-header">Features</div>

            <div className="info-subheader">Navbar buttons</div>
            <div className="info-list">
              <div className="info-icon-group">
                <Icon iconName="info"></Icon> View general information and instructions for this web application
              </div>

              <div className="info-icon-group">
                <Icon iconName="Light"></Icon> Toggle between Light and Dark themes
              </div>

              <div className="info-icon-group">
                <Icon iconName="FilterSolid"></Icon> Open or close the Filter Panel, which allows you to choose what to show on the graph.
                You can filter by Bait, Other Pathogen, Drugs, or PDBs. You can also type letters into the search box and it will filter the
                list to elements containing the text you typed. Under the bait tab, you can type in a human protein name, and it will find
                the bait protein that interacts with that protein.
              </div>

              <div className="info-icon-group">
                <Icon iconName="Download"></Icon> Download the datasheets used to generate this graph
              </div>

              <div className="info-icon-group">
                <Icon iconName="FileImage"></Icon> Save current graph view as an image
              </div>

              <div className="info-icon-group">
                <Icon iconName="Refresh"></Icon> Reset graph components to their original position
              </div>

              <div className="info-icon-group">
                <Icon iconName="ZoomToFit"></Icon> Zoom the graph to fit your browser window automatically
              </div>

              <div className="info-icon-group">
                <Icon iconName="Share"></Icon> Generate and share a public link of your current graph view and settings
              </div>

              <div className="info-icon-group">
                <Icon iconName="LineSpacing"></Icon> Change layout settings, which affect how graph is arranged when reset or when filters
                are applied
              </div>
            </div>

            <div className="info-subheader">Graph gestures</div>
            <div className="info-list">
              <div className="info-image-group">
                <div>
                  1. Click on a Node or Edge to show various information. Click the arrow button to hide the panel, or resize the info popup
                  by dragging the top blue border of the info popup.
                </div>
                <img src="/nodeInfo.png" height="300px" style={{ objectFit: 'contain' }} />
              </div>

              <div className="info-image-group">
                <div>2. Drag a Node or Node Group to move it within the graph.</div>
                <img src="/nodeDrag.png" height="300px" style={{ objectFit: 'contain' }} />
              </div>

              <div className="info-image-group">
                <div>
                  3. Hold down Ctrl, Shift or Cmd (Mac) key to enable Multi-select. Click or drag the cursor on the screen to
                  select/deselect multiple nodes. Multi-selected nodes should have a different shade over it.{' '}
                </div>
                <img src="/nodeMulti.png" height="300px" style={{ objectFit: 'contain' }} />
              </div>

              <div className="info-image-group">
                <div>
                  4. Drag the cursor across empty space on the graph to Pan the graph view. A dark circle should appear under your cursor
                  when you are panning the graph.
                </div>
                <img src="/pan.png" height="300px" style={{ objectFit: 'contain' }} />
              </div>

              <div className="info-image-group">
                <div>5. Zoom in/out with mouse scrollbar or the Zoom Bar tool in the upper left corner.</div>
                <img src="/zoom.png" height="300px" style={{ objectFit: 'contain' }} />
              </div>
            </div>

            <div className="info-subheader">Legend guide</div>
            <Legend
              className={'legend'}
              legends={legendRowOne(theme)}
              labelColor={theme === 'light' ? LightStyles.labelColor : DarkStyles.labelColor}
            />
            <Legend
              className={'legend'}
              legends={legendRowTwo(theme)}
              labelColor={theme === 'light' ? LightStyles.labelColor : DarkStyles.labelColor}
            />
            <Legend
              className={'legend'}
              legends={legendRowThree(theme)}
              labelColor={theme === 'light' ? LightStyles.labelColor : DarkStyles.labelColor}
              style={{ height: 22 }}
            />
            <div className="info-list">
              <ul className="ref-list">
                <li>
                  <b>SARS-CoV-2 Viral Protein:</b> SARS-CoV-2 viral proteins (26/29) used as “baits” for affinity purification mass
                  spectrometry (AP-MS) experiments.
                </li>
                <li>
                  <b>Human Protein:</b> Human proteins captured as “preys” for each of the viral “bait” proteins.{' '}
                </li>
                <li>
                  <b># of Pathogens:</b> Human prey proteins captured by other pathogen proteins with MIST score<sup>1</sup> &ge; 0.7. Other
                  pathogens assessed are Zika<sup>2</sup>, West Nile Virus<sup>3</sup>, tuberculosis<sup>4</sup>, KSHV<sup>5</sup>,
                  chlamydia
                  <sup>6</sup>, dengue<sup>2</sup>, ebola<sup>7</sup>, HBV, HCV<sup>8</sup>, HIV<sup>1</sup>, and HPV<sup>9</sup>.
                </li>
                <li>
                  <b>Drug Target:</b> These human “preys” have been identified by our paper Gordon <i>et al.</i> (2020) to be targetable
                  with small molecules. These molecules are noted for each targetable node.
                </li>
                <li>
                  <b>MIST Score:</b> Scoring metric<sup>1</sup> for AP-MS data representing specificity of each interaction across all
                  “bait” proteins. 
                </li>
                <li>
                  <b>Spectral Counts:</b> Average spectral count (across three AP-MS biological replicates) identified for each prey human
                  protein, a measure of protein abundance in the immunoprecipitation.
                </li>
                <li>
                  <b>Complex:</b> Protein complexes as annotated by CORUM<sup>10</sup> and literature sources.
                </li>
                <li>
                  <b>Process:</b> Biological process as annotated by Gene Ontology<sup>11</sup>, Reactome<sup>12</sup>, and literature
                  sources.
                </li>
                <li>
                  <b>Human-human protein-protein interaction:</b> Human-human protein-protein interactions curated from CORUM<sup>10</sup>,
                  IntAct
                  <sup>13</sup>, and Reactome<sup>12</sup>.
                </li>
                <li>
                  <b>Phosphorylation Sites:</b> Indicates host interacting proteins with one or more phosphorylation sites detected by
                  phosphoproteomics analysis of SARS-CoV-2 infected Vero E6 cells. Clicking on these nodes displays heatmaps of log2 fold
                  changes and adjusted p-values for each detected phosphorylation site at 0, 2, 4, 8, 12, and 24 hours post-infection.
                  Please refer to Bouhaddou et al. (2020) for full results and methods.
                </li>
              </ul>
            </div>

            <div className="info-subheader">References</div>
            <div className="info-list">
              <ol className="ref-list">
                <li>
                  Jäger, S. <i>et al.</i> Global landscape of HIV-human protein complexes. <i>Nature</i> <b>481</b>, 365–370 (2011).
                </li>
                <li>
                  Shah, P. S. <i>et al.</i> Comparative Flavivirus-Host Protein Interaction Mapping Reveals Mechanisms of Dengue and Zika
                  Virus Pathogenesis. <i>Cell</i> <b>175</b>, 1931–1945.e18 (2018).
                </li>
                <li>
                  Li, M. <i>et al</i>. Identification of antiviral roles for the exon-junction complex and nonsense-mediated decay in
                  flaviviral infection. <i>Nat Microbiol</i> <b>4</b>, 985–995 (2019).
                </li>
                <li>
                  Penn, B. H. <i>et al.</i> An Mtb-Human Protein-Protein Interaction Map Identifies a Switch between Host Antiviral and
                  Antibacterial Responses. <i>Mol. Cell</i> <b>71</b>, 637–648.e5 (2018).
                </li>
                <li>
                  Davis, Z. H. <i>et al.</i> Global mapping of herpesvirus-host protein complexes reveals a transcription strategy for late
                  genes. <i>Mol. Cell</i> <b>57</b>, 349–360 (2015).
                </li>
                <li>
                  Mirrashidi, K. M. <i>et al.</i> Global Mapping of the Inc-Human Interactome Reveals that Retromer Restricts Chlamydia
                  Infection. <i>Cell Host Microbe</i> <b>18</b>, 109–121 (2015).
                </li>
                <li>
                  Batra, J. <i>et al.</i> Protein Interaction Mapping Identifies RBBP6 as a Negative Regulator of Ebola Virus Replication.
                  <i>Cell</i> <b>175</b>, 1917–1930.e13 (2018).
                </li>
                <li>
                  Ramage, H. R. <i>et al.</i> A combined proteomics/genomics approach links hepatitis C virus infection with
                  nonsense-mediated mRNA decay. <i>Mol. Cell</i> <b>57</b>, 329–340 (2015).
                </li>
                <li>
                  Eckhardt, M. <i>et al.</i> Multiple Routes to Oncogenesis Are Promoted by the Human Papillomavirus-Host Protein Network.
                  <i>Cancer Discov.</i> <b>8</b>, 1474–1489 (2018).
                </li>
                <li>
                  Giurgiu, M. <i>et al.</i> CORUM: the comprehensive resource of mammalian protein complexes-2019. <i>Nucleic Acids Res.</i>{' '}
                  <b>47</b>, D559–D563 (2019).
                </li>
                <li>
                  Ashburner, M. <i>et al.</i> Gene ontology: tool for the unification of biology. The Gene Ontology Consortium.{' '}
                  <i>Nat. Genet.</i>
                  <b>25</b>, 25–29 (2000).
                </li>
                <li>
                  Jassal, B. <i>et al.</i> The reactome pathway knowledgebase. <i>Nucleic Acids Res.</i> <b>48</b>, D498–D503 (2020).
                </li>
                <li>
                  Orchard, S. <i>et al.</i> The MIntAct project--IntAct as a common curation platform for 11 molecular interaction
                  databases. <i>Nucleic Acids Res.</i> <b>42</b>, D358–63 (2014).
                </li>
              </ol>
            </div>
          </div>
        </PivotItem>

        <PivotItem itemKey={'contact_us'} headerText="Contact Us">
          <div className="info-background-body">
            {/* <div className="info-header">Features</div> */}
            <div className="info-subheader">Krogan Labs</div>
            <div>
              Correspondence and requests for materials should be addressed to{' '}
              <Link href="mailto:nevan.krogan@ucsf.edu" target="_blank">
                nevan.krogan@ucsf.edu
              </Link>
              . Reprints and permissions information is available at{' '}
              <Link href="www.nature.com/reprints" target="_blank">
                www.nature.com/reprints
              </Link>
              .
            </div>

            <div className="info-subheader">Zoic Labs</div>
            <div>
              This tool was created with the Cognitive Core Toolkit&trade; by Zoic Labs in April of 2020. If you or your organization are
              interested in custom visualization tools such as this one or other software development, please email{' '}
              <Link href="mailto:info@zoiclabs.com" target="_blank">
                info@zoiclabs.com
              </Link>{' '}
              or visit{' '}
              <Link href={'https://www.zoiclabs.com'} target="_blank">
                https://www.zoiclabs.com
              </Link>
              . This PPI map incorporated software from{' '}
              <Link href="https://github.com/cytoscape/cytoscape.js" target="_blank">
                Cytoscape
              </Link>
              <sup>1,2</sup>
            </div>

            <div>
              <ol className="ref-list">
                <li>
                  <i>Cytoscape.js: a graph theory library for visualisation and analysis</i>, Franz M, Lopes CT, Huck G, Dong Y, Sumer O,
                  Bader GD Bioinformatics (2016) 32 (2): 309-311 first published online September 28, 2015
                  doi:10.1093/bioinformatics/btv557.
                </li>
                <li>
                  Shannon, Paul, Andrew Markiel, Owen Ozier, Nitin S. Baliga, Jonathan T. Wang, Daniel Ramage, Nada Amin, Benno Schwikowski,
                  and Trey Ideker. 2003. “Cytoscape: A Software Environment for Integrated Models of Biomolecular Interaction Networks.” 
                  <i>Genome Research</i> 13 (11): 2498–2504.
                </li>
              </ol>
            </div>
          </div>
        </PivotItem>
      </Pivot>
    </div>
  )
}

export default InfoContent
