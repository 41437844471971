import React, { useState, useCallback, useContext } from 'react'
import { IconButton, Slider } from 'office-ui-fabric-react'
import { ThemeContext } from '../../contexts/theme'
import './index.css'

interface IZoomBarProps {
  className?: string
  minZoom: number
  maxZoom: number
  initialZoom?: number
  zoom?: number
  length?: number
  onZoomUpdated?: (zoom: number) => void
  segments?: number

  // vertical?: boolean
  // inversed?: boolean
  // minimalized?: boolean
}

function getZoom(currentZoom: number, shouldZoomIn: boolean, minZoom: number, maxZoom: number, segments: number): number {
  const offset = (maxZoom - minZoom) / segments
  let retZoom = currentZoom + offset * (shouldZoomIn ? 1 : -1)
  if (retZoom < minZoom) retZoom = minZoom
  if (retZoom > maxZoom) retZoom = maxZoom
  return retZoom
}

function ZoomBar(props: IZoomBarProps) {
  const { className, onZoomUpdated, initialZoom, minZoom, maxZoom, zoom, length = 200, segments = 20 } = props
  const { palette } = useContext(ThemeContext)

  if (maxZoom < minZoom) {
    throw Error('maxZoom must be greater than minZoom!')
  }

  const [_zoom, _setZoom] = useState(initialZoom || minZoom + (maxZoom - minZoom) / 2)
  const zoomInUse = zoom || _zoom

  const factor = (zoomInUse - minZoom) / (maxZoom - minZoom)
  const offset = factor * length

  const onZoomChange = (newZoom: number) => {
    _setZoom(newZoom)
    if (onZoomUpdated) onZoomUpdated(newZoom)
  }

  const onZoomInClick = () => {
    const newZoom = getZoom(zoomInUse, true, minZoom, maxZoom, segments)
    onZoomChange(newZoom)
  }

  const onZoomOutClick = () => {
    const newZoom = getZoom(zoomInUse, false, minZoom, maxZoom, segments)
    onZoomChange(newZoom)
  }

  return (
    <div className={[className, 'zoombar', 'vertical'].join(' ')}>
      <IconButton
        iconProps={{
          iconName: 'Add'
        }}
        onClick={onZoomInClick}
        className={'zoom-button-top'}
      />

      <Slider
        styles={{
          root: {
            flex: 1,
            margin: 0
          },
          slideBox: {
            padding: 0,
            margin: 0,
            selectors: {
              '&:hover .ms-Slider-active': {
                backgroundColor: 'var(--neutralTertiaryAlt)'
              },
              '&:hover .ms-Slider-inactive': {
                backgroundColor: 'var(--themeSecondary)'
              }
            }
          },
          inactiveSection: {
            backgroundColor: 'var(--themePrimary)'
          },
          activeSection: {
            backgroundColor: 'var(--neutralTertiary)'
          },
          line: {
            width: '8px'
          },
          lineContainer: {
            width: '8px'
          },
          thumb: {
            left: '-4px'
          }
        }}
        showValue={false}
        min={minZoom}
        max={maxZoom}
        value={zoomInUse}
        onChange={onZoomChange}
        step={(maxZoom - minZoom) / segments}
        vertical={true}
      />

      <IconButton
        iconProps={{
          iconName: 'Remove'
        }}
        onClick={onZoomOutClick}
        className={'zoom-button-bottom'}
      />
    </div>
  )
}

export default ZoomBar
