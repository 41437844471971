import React, { useState, useCallback, useContext } from 'react'
import {
  TooltipHost,
  Panel,
  IconButton,
  IconType,
  Modal,
  Pivot,
  PivotItem,
  DirectionalHint,
  Link,
  PanelType,
  Image,
  ImageFit,
  Dropdown,
  IDropdownOption
} from 'office-ui-fabric-react'
import NamesFilterPanel, { IFilterGroup } from '../../components/NamesFilterPanel'
import DownloadSpreadsheetsButton from './DownloadSpreadsheetsButton'
import ShareLinkButton from './ShareLinkButton'
import Legend, { ILegendArgs } from '../../components/Graph/Legend'
import * as LightStyles from './GraphStyles/light'
import * as DarkStyles from './GraphStyles/dark'
import ZoomBar from '../../components/ZoomBar'
import GraphSettings from './GraphSettings'
import { organizeCovid19Graph, arrangeNodesInGrid } from './GraphActions'
import { GraphViewIsLoaded } from '../../contexts/graphContext'

import { GraphSettingsContext } from '../../contexts/graphSettings'
import { GraphFiltersContext } from '../../contexts/graphFilters'
import { zoomToFit } from './GraphActions'
import { downloadUrlImage } from '../../utils'
import Cytoscape from 'cytoscape'
import { ThemeContext } from '../../contexts/theme'

import { Header } from '../../components/Header'
import InfoContent from './InfoContent'

const headerIconStyles = (isActive?: boolean, activeColor?: string, inactiveColor?: string) => {
  return {
    root: {
      width: 'var(--navHeaderHeight)',
      height: 'var(--navHeaderHeight)',
      margin: 0,
      padding: 0
    },
    icon: {
      color: isActive ? activeColor || 'var(--themePrimary)' : inactiveColor || 'var(--neutralQuaternary)'
    },
    iconHovered: {
      color: 'var(--themePrimary)'
    },
    iconPressed: {
      color: 'var(--neutralQuaternary)'
    }
  }
}

const filterPanelWidth = '380px'
const settingsPanelWidth = '300px'

export const legendRowOne = (theme: string): ILegendArgs[] => {
  const styles = theme === 'light' ? LightStyles : DarkStyles

  return [
    {
      classes: 'virus',
      type: 'node',
      label: 'SARS-CoV-2 Viral Protein',
      paddingRight: 24,
      style: {
        selector: 'node.virus',
        style: styles.virusStyle.style
      }
    },
    {
      classes: 'human',
      type: 'node',
      label: 'Human Protein',
      paddingRight: 12,
      style: {
        selector: 'node.human',
        style: {
          ...styles.humanStyle.style,
          width: 16,
          height: 16
        }
      }
    },
    {
      classes: 'other-viruses-small',
      type: 'node',
      paddingRight: -16,
      style: {
        selector: 'node.other-viruses-small',
        style: {
          ...styles.humanStyle.style,
          ...styles.otherVirusesStyle.style,
          width: 19,
          height: 19,
          'text-valign': 'top',
          'text-halign': 'center',
          label: '1'
        }
      }
    },
    {
      classes: 'other-viruses-medium',
      type: 'node',
      paddingRight: -12,
      style: {
        selector: 'node.other-viruses-medium',
        style: {
          ...styles.humanStyle.style,
          ...styles.otherVirusesStyle.style,
          width: 24,
          height: 24,
          'text-valign': 'top',
          'text-halign': 'center',
          label: '3'
        }
      }
    },
    {
      classes: 'other-viruses',
      type: 'node',
      paddingRight: -28,
      // label: 'Has pathogens',
      style: {
        selector: 'node.other-viruses',
        style: {
          ...styles.humanStyle.style,
          ...styles.otherVirusesStyle.style,
          'text-valign': 'top',
          'text-halign': 'center',
          label: '5'
        }
      }
    },
    {
      classes: 'other-viruses-label',
      type: 'node',
      paddingRight: -32,
      label: '# of Pathogens',
      style: {
        selector: 'node.other-viruses-label',
        style: {
          width: 1,
          height: 1
        }
      }
    }
  ]
}

export const legendRowTwo = (theme: string): ILegendArgs[] => {
  const styles = theme === 'light' ? LightStyles : DarkStyles
  const ret: ILegendArgs[] = [
    {
      classes: 'Complex',
      type: 'node',
      label: 'Complex',
      paddingRight: 14,
      style: {
        selector: 'node.Complex',
        style: {
          shape: 'round-rectangle',
          backgroundColor: styles.complexColor,
          'background-opacity': styles.compoundOpacity
        }
      }
    },
    {
      classes: 'Process',
      type: 'node',
      label: 'Process',
      paddingRight: -2,
      style: {
        selector: 'node.Process',
        style: {
          shape: 'round-rectangle',
          backgroundColor: styles.processColor,
          'background-opacity': styles.compoundOpacity
        }
      }
    },
    {
      classes: 'mist',
      type: 'edge',
      label: 'MIST Score',
      paddingRight: 20,
      style: {
        selector: 'edge.mist',
        style: styles.mistLineStyle.style
      },
      valRange: [0.7, 1],
      valName: 'mist',
      numLines: 3
    },
    {
      classes: 'spec',
      type: 'edge',
      label: 'Spectral Counts',
      style: {
        selector: 'edge.spec',
        style: {
          ...styles.virusToHumanStyle.style,
          width: 'mapData(avgSpec, 0, 180, 2, 8)'
        }
      },
      valRange: [2, 181],
      valName: 'avgSpec',
      numLines: 3
    }
  ]
  return ret
}

export const legendRowThree = (theme: string): ILegendArgs[] => {
  const styles = theme === 'light' ? LightStyles : DarkStyles

  return [
    {
      classes: 'drug-target',
      type: 'node',
      label: 'Drug Target',
      paddingRight: 40,
      style: {
        selector: 'node.drug-target',
        style: {
          ...styles.humanStyle.style,
          ...styles.drugTargetStyle.style,

          width: 16,
          height: 16
        }
      }
    },
    {
      classes: 'phos-data',
      type: 'node',
      label: 'Phosphorylation Sites',
      paddingRight: 40,
      style: {
        selector: 'node.phos-data',
        style: {
          // ...styles.humanStyle.style,
          ...styles.phosStyle.style,

          backgroundColor: styles.backgroundColor,
          'background-image': theme === 'light' ? '/p_letter.png' : '/WhiteP.png'
        }
      }
    },
    {
      classes: 'human-to-human',
      type: 'edge',
      label: 'Human-Human PPI',
      style: {
        selector: 'edge.human-to-human',
        style: styles.humanToHumanStyle.style
      }
    }
  ]
}

export default function SarsCov2Overlay(props: {}) {
  const graphSettings = useContext(GraphSettingsContext)
  const graphFilters = useContext(GraphFiltersContext)
  const { theme, toggleTheme } = useContext(ThemeContext)

  const onFilterButtonClick = useCallback(() => {
    const showFilterPanel = !graphSettings.showFilterPanel
    graphSettings.update({
      showFilterPanel,
      // offset for panel
      width: showFilterPanel ? `calc(100% - ${filterPanelWidth})` : '100%',
      showSettingsPanel: false
    })
  }, [graphSettings])

  const onFilterDismiss = useCallback(() => {
    graphSettings.update({ showFilterPanel: false, width: '100%' })
  }, [graphSettings])

  const onSettingsButtonClick = useCallback(() => {
    const showSettingsPanel = !graphSettings.showSettingsPanel
    graphSettings.update({
      showSettingsPanel,
      // offset for panel
      width: showSettingsPanel ? `calc(100% - ${settingsPanelWidth})` : '100%',
      showFilterPanel: false
    })
  }, [graphSettings])

  const onSettingsDismiss = useCallback(() => {
    graphSettings.update({ showSettingsPanel: false, width: '100%' })
  }, [graphSettings])

  const zoomGraphToFit = useCallback(() => {
    if (!graphSettings.cy) return
    zoomToFit(graphSettings.cy)
  }, [graphSettings])

  const saveAsImage = useCallback(() => {
    if (!graphSettings.cy) return
    const dataUri = graphSettings.cy.png({
      output: 'base64uri',
      full: false,
      scale: 1,
      bg: theme === 'light' ? LightStyles.backgroundColor : DarkStyles.backgroundColor
    })
    downloadUrlImage('sarscov2_img', dataUri)
  }, [theme, graphSettings.cy])

  const onZoomUpdated = useCallback(
    (zoom: number) => {
      if (graphSettings.cy) {
        graphSettings.cy.zoom({
          level: zoom,
          renderedPosition: {
            x: graphSettings.cy.width() / 2,
            y: graphSettings.cy.height() / 2
          }
        })
      }
    },
    [graphSettings]
  )

  const serializeGraphView = useCallback(() => {
    if (graphSettings.cy) {
      const json: any = graphSettings.cy.json()
      const searchTerms: { [key: string]: string } = {}
      const activeTerms: { [key: string]: string[] } = {}

      for (const key in graphFilters.data) {
        searchTerms[key] = graphFilters.data[key].searchText
        activeTerms[key] = graphFilters.data[key].activeTerms
      }

      const nodesData: Cytoscape.NodeDefinition[] = []
      const allNodes: Cytoscape.NodeDefinition[] = json.elements.nodes
      for (const node of allNodes) {
        nodesData.push({
          data: {
            id: node.data.id,
            type: node.data.type
          },
          position: node.position
        })
      }

      const ret = {
        filters: {
          mode: graphFilters.filterMode,
          searchTerms,
          activeTerms
        },
        layoutOptions: graphSettings.layoutOptions,
        cyOptions: {
          zoom: json.zoom,
          pan: json.pan,
          minZoom: json.minZoom,
          maxZoom: json.maxZoom,
          nodes: nodesData
        }
      }

      return ret
    }
  }, [graphFilters, graphSettings])

  const onResetButtonPressed = useCallback(() => {
    if (graphSettings.cy) {
      organizeCovid19Graph(graphSettings.cy, true)
      arrangeNodesInGrid(graphSettings.cy, graphSettings.cy.nodes('.virus-compound').not('.hidden'), graphSettings.layoutOptions)
    }
  }, [graphSettings])

  let _allTermsTransformed: { [key: string]: IFilterGroup[] } = {}
  for (const key in graphFilters.data) {
    const filter = graphFilters.data[key]
    if (filter.groupBy) {
      const allGroups: { [key: string]: IFilterGroup } = {}
      for (const term of graphFilters.data[key].allTerms) {
        const group = filter.groupBy(term)
        if (!allGroups[group]) {
          allGroups[group] = {
            key: group,
            children: []
          }
        }
        allGroups[group].children?.push({ key: term })
      }
      _allTermsTransformed[key] = Object.values(allGroups)
      continue
    }

    _allTermsTransformed[key] = filter.allTerms.map(term => {
      return { key: term }
    })
  }

  // const onPivotLinkClick = useCallback(
  //   (item?: PivotItem, ev?: React.MouseEvent<HTMLElement, MouseEvent>) => {
  //     if (item) {
  //       const key = item.props.itemKey || ''
  //       graphFilters.update({ filterMode: key })
  //       if (graphFilters.useFilter && graphSettings.cy) {
  //         graphFilters.useFilter(key)
  //         arrangeNodesInGrid(graphSettings.cy, graphSettings.cy.nodes('.virus-compound').not('.hidden'), graphSettings.layoutOptions)
  //       }
  //     }
  //   },
  //   [graphFilters, graphSettings]
  // )

  const onDropdownSelect = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option && option.key) {
        const key = String(option.key)
        graphFilters.update({ filterMode: key })

        // use a timeout reduce the visible input latency
        setTimeout(() => {
          if (graphFilters.useFilter && graphSettings.cy) {
            graphFilters.useFilter(key)
            arrangeNodesInGrid(graphSettings.cy, graphSettings.cy.nodes('.virus-compound').not('.hidden'), graphSettings.layoutOptions)
          }
        }, 0)
      }
    },
    [graphFilters, graphSettings]
  )

  const onToggleTheme = useCallback(() => {
    toggleTheme && toggleTheme()
  }, [toggleTheme])

  const minZoomLog = Math.log2(graphSettings.minZoom || 1e-50)
  const maxZoomLog = Math.log2(graphSettings.maxZoom || 1e50)
  const zoomLog = Math.log2(graphSettings.zoom || 1)
  const _onZoomUpdated = (value: number) => {
    if (onZoomUpdated) onZoomUpdated(Math.pow(2, value))
  }

  const onActiveTermsChange = (key: string) => (activeTerms: string[]) => {
    if (graphFilters.updateFilter) {
      graphFilters.updateFilter(key, { activeTerms })
    }
    if (graphFilters.useFilter) {
      graphFilters.useFilter(key, activeTerms)
      if (graphSettings.cy) {
        arrangeNodesInGrid(graphSettings.cy, graphSettings.cy.nodes('.virus-compound').not('.hidden'), graphSettings.layoutOptions)
      }
    }
  }

  const onToggleInfo = useCallback(() => {
    graphSettings.update({
      showInfo: !graphSettings.showInfo
    })
  }, [graphSettings])

  const selectedFilter = graphFilters.data[graphFilters.filterMode]

  return (
    <div className="sarscov2-graph-overlay">
      <div className="overlay-header">
        <Header>
          <TooltipHost content="Info" directionalHint={DirectionalHint.bottomAutoEdge}>
            <IconButton
              iconProps={{
                iconName: 'Info'
              }}
              styles={headerIconStyles(graphSettings.showInfo)}
              onClick={onToggleInfo}
            />
          </TooltipHost>

          <TooltipHost content="Toggle Theme" directionalHint={DirectionalHint.bottomAutoEdge}>
            <IconButton
              iconProps={{
                iconName: 'Light'
              }}
              styles={headerIconStyles(theme === 'light', 'var(--neutralQuaternary)', 'var(--neutralTertiaryAlt)')}
              onClick={onToggleTheme}
            />
          </TooltipHost>

          <TooltipHost content="Filters" directionalHint={DirectionalHint.bottomAutoEdge}>
            <IconButton
              iconProps={{
                iconName: 'FilterSolid',
                iconType: IconType.default
              }}
              styles={headerIconStyles(graphSettings.showFilterPanel)}
              onClick={onFilterButtonClick}
            />
          </TooltipHost>

          <TooltipHost content="Download" directionalHint={DirectionalHint.bottomAutoEdge}>
            <DownloadSpreadsheetsButton styles={headerIconStyles()} />
          </TooltipHost>

          <TooltipHost content="Save Image" directionalHint={DirectionalHint.bottomAutoEdge}>
            <IconButton
              iconProps={{
                iconName: 'FileImage'
              }}
              styles={headerIconStyles()}
              onClick={saveAsImage}
            />
          </TooltipHost>

          <TooltipHost content="Reset" directionalHint={DirectionalHint.bottomAutoEdge}>
            <IconButton
              iconProps={{
                iconName: 'Refresh'
              }}
              styles={headerIconStyles()}
              onClick={onResetButtonPressed}
            />
          </TooltipHost>

          <TooltipHost content="Zoom To Fit" directionalHint={DirectionalHint.bottomAutoEdge}>
            <IconButton
              iconProps={{
                iconName: 'ZoomToFit'
              }}
              styles={headerIconStyles()}
              onClick={zoomGraphToFit}
            />
          </TooltipHost>

          <TooltipHost content="Share" directionalHint={DirectionalHint.bottomAutoEdge}>
            <ShareLinkButton getStyles={headerIconStyles} serializeGraphView={serializeGraphView} />
          </TooltipHost>

          <TooltipHost content="Layout" directionalHint={DirectionalHint.bottomAutoEdge}>
            <IconButton
              iconProps={{
                iconName: 'LineSpacing'
              }}
              styles={headerIconStyles(graphSettings.showSettingsPanel)}
              onClick={onSettingsButtonClick}
            />
          </TooltipHost>
        </Header>
      </div>

      <div className="overlay-top-left">
        <ZoomBar
          segments={50}
          className={'overlay-zoombar'}
          minZoom={minZoomLog}
          maxZoom={maxZoomLog}
          zoom={zoomLog}
          onZoomUpdated={_onZoomUpdated}
        />
      </div>

      <div className="overlay-top-left">
        <GraphViewIsLoaded>
          <Link href="/" className="back-link">
            Back to home
          </Link>
        </GraphViewIsLoaded>
      </div>

      <div
        className={'overlay-bottom-right'}
        style={{ backgroundColor: theme === 'light' ? LightStyles.backgroundColor : DarkStyles.backgroundColor }}>
        <Legend
          className={'legend'}
          legends={legendRowOne(theme)}
          labelColor={theme === 'light' ? LightStyles.labelColor : DarkStyles.labelColor}
        />
        <Legend
          className={'legend'}
          legends={legendRowTwo(theme)}
          labelColor={theme === 'light' ? LightStyles.labelColor : DarkStyles.labelColor}
        />
        <Legend
          className={'legend'}
          labelColor={theme === 'light' ? LightStyles.labelColor : DarkStyles.labelColor}
          legends={legendRowThree(theme)}
          style={{ height: 22 }}
        />
      </div>

      <div className="overlay-bottom-left">
        <div className="logo-images-container">
          <Link href="https://kroganlab.ucsf.edu/krogan-lab" target="_blank">
            <Image
              width="125px"
              height="50px"
              imageFit={ImageFit.contain}
              src={theme === 'light' ? '/krogan_logo.png' : '/krogan_logo_white.png'}
              alt={'krogan logo'}
            />
          </Link>
          <div style={{ width: '100px' }}></div>
          <Link href="https://www.zoiclabs.com" target="_blank">
            <Image
              imageFit={ImageFit.contain}
              src={theme === 'light' ? '/cognitive_logo_black.png' : '/cognitive_logo_white.png'}
              alt="cognitive logo"
              height="50px"
              width="250px"
            />
          </Link>
        </div>
      </div>

      <Panel
        isOpen={graphSettings.showFilterPanel}
        hasCloseButton={false}
        isBlocking={false}
        onDismiss={onFilterDismiss}
        styles={{
          content: {
            paddingLeft: '20px',
            paddingRight: '20px'
          }
        }}
        type={PanelType.custom}
        customWidth={filterPanelWidth}
        layerProps={{
          eventBubblingEnabled: true
        }}>
        {/* <Pivot selectedKey={graphFilters.filterMode} onLinkClick={onPivotLinkClick}>
          {Object.keys(graphFilters.data).map(key => {
            const filter = graphFilters.data[key]
            const allTermsTransformed = _allTermsTransformed[key]
            return (
              <PivotItem headerText={filter.name} itemKey={key} key={key}>
                <NamesFilterPanel
                  allTerms={allTermsTransformed}
                  initialSearchText={filter.searchText}
                  initialActiveTerms={filter.activeTerms}
                  extraTerms={filter.extraTerms}
                  onSearchTextChange={searchText => {
                    if (graphFilters.updateFilter) {
                      graphFilters.updateFilter(key, { searchText })
                    }
                  }}
                  onActiveTermsChange={onActiveTermsChange(key)}
                />
              </PivotItem>
            )
          })}
        </Pivot> */}
        <h2 style={{ marginTop: '24px', marginBottom: '12px' }}>Filters</h2>

        <Dropdown
          options={Object.keys(graphFilters.data).map(key => {
            return {
              key: key,
              text: graphFilters.data[key].name
            }
          })}
          selectedKey={graphFilters.filterMode}
          onChange={onDropdownSelect}
        />

        <div style={{ height: '12px' }} />

        <PivotItem headerText={selectedFilter.name} itemKey={graphFilters.filterMode} key={graphFilters.filterMode}>
          <NamesFilterPanel
            allTerms={_allTermsTransformed[graphFilters.filterMode]}
            initialSearchText={selectedFilter.searchText}
            initialActiveTerms={selectedFilter.activeTerms}
            extraTerms={selectedFilter.extraTerms}
            onSearchTextChange={searchText => {
              if (graphFilters.updateFilter) {
                graphFilters.updateFilter(graphFilters.filterMode, { searchText })
              }
            }}
            onActiveTermsChange={onActiveTermsChange(graphFilters.filterMode)}
          />
        </PivotItem>
      </Panel>

      <Panel
        isOpen={graphSettings.showSettingsPanel}
        isBlocking={false}
        hasCloseButton={false}
        onDismiss={onSettingsDismiss}
        type={PanelType.custom}
        customWidth={settingsPanelWidth}
        styles={{
          content: {
            paddingLeft: '20px',
            paddingRight: '20px'
          }
        }}
        layerProps={{
          eventBubblingEnabled: true
        }}>
        <GraphSettings />
      </Panel>

      <Modal isOpen={graphSettings.showInfo} onDismiss={onToggleInfo} isBlocking={false}>
        <InfoContent />
      </Modal>
    </div>
  )
}
