import React, { useEffect, useState, useContext } from 'react'
import { PrimaryButton, Spinner, Modal } from 'office-ui-fabric-react'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import JSONTree from 'react-json-tree'

import { FirebaseContext } from '../../contexts/firebaseContext'
import FileInput from '../../components/FileInput'
import { GraphContext } from '../../contexts/graphContext'

const styles = {
  ingestOuter: {
    'text-align': 'center',
    width: '25em',
    maxWidth: '100%%',
    marginBottom: '3em'
  },
  filesFormOuter: {
    paddingBottom: '3em'
  },
  filesOuter: {
    marginBottom: '1em'
  },
  tableFileOuter: {
    display: 'flex',
    'flex-direction': 'column',
    alignItems: 'center',
    padding: '1em 0 .5em 0',
    borderBottom: '1px solid black',
    height: '5em'
  },
  fileInputLabel: {
    flex: 1,
    fontStyle: 'italic'
  },
  fileInputOuter: {
    display: 'flex',
    flex: 9,
    alignItems: 'center'
  },
  historyOuter: {},
  heading: {
    fontSize: '1.5em',
    fontWeight: 600,
    padding: '.5em'
  },
  errorJSONTreeOuter: {
    height: '75vh',
    width: '75vw',
    border: '1em solid white',
    overflow: 'auto'
  },
  errorJSONTree: {},
  uploadErrorMessageOuter: {
    height: '4em',
    marginBottom: '1em',
    'overflow-y': 'auto'
  },
  uploadErrorText: {
    fontSize: '.85em',
    color: 'red'
  }
}

interface ITableFileInputProps {
  tableName: string
  label: string
  onUpload: Function
  onDeleteFile: Function
}
function TableFileInput(props: ITableFileInputProps) {
  const { label } = props

  return (
    <div style={styles.tableFileOuter}>
      <div style={styles.fileInputLabel}>{label}</div>
      <div style={styles.fileInputOuter}>
        <FileInput {...props} />
      </div>
    </div>
  )
}

interface IErrorJSONTree {
  data: object
  header: string
}
function ErrorJSONTree(props: IErrorJSONTree) {
  const { header, data } = props
  return (
    <div style={styles.errorJSONTreeOuter}>
      <h3 style={{ textAlign: 'center' }}>{header}</h3>
      <span>
        Below are the validation errors. If you click on the table names, it will show you the errors associated with each row. If you see
        fields labeled with _EMPTY, or if there are "addtionalProperties" errors, it's mostly likely because the files uploaded were not
        matched with their respective tables.
      </span>
      <JSONTree data={data} theme={styles.errorJSONTree} />
    </div>
  )
}

interface ITableFiles {
  [key: string]: string | File | null
}
export default function Ingest() {
  const { firebase } = React.useContext(FirebaseContext)
  const { tableNames, loadGraphData } = useContext(GraphContext)

  // upload state
  const [tableFiles, setTableFiles] = useState({})
  const [isUploading, setIsUploading] = useState(false)
  const [uploadButtonText, setUploadButtonText] = useState('')
  const [uploadErrorMessage, setUploadErrorMessage] = useState('')

  // rollback state
  const [isRollingBack, setIsRollingBack] = useState(false)
  const [currentUpdatedAt, setCurrentUpdatedAt] = useState('N/A')
  const [previousUpdatedAt, setPreviousUpdatedAt] = useState('N/A')
  const [rollbackResultMessage, setRollbackResultMessage] = useState('')

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(<div></div>)

  const updateTimestamps = async () => {
    // fetch xlsxUploads current and previous
    const collection = firebase.firestore().collection('graphs')

    // get docs and set updated at timestamps
    await Promise.all([collection.doc('latest').get(), collection.doc('previous').get()]).then(docSnapshots => {
      const timestamps = docSnapshots.map(snapshot => {
        if (!snapshot.exists) return 'N/A'
        return moment(snapshot.get('updatedAt')).calendar()
      })
      setCurrentUpdatedAt(timestamps[0])
      setPreviousUpdatedAt(timestamps[1])
    })
  }

  useEffect(() => {
    updateTimestamps()
  }, [])

  const onAddFile = (tableName: string) => (data: FormData) => {
    console.log('upload tableName', tableName)
    const newTableData: ITableFiles = {
      ...tableFiles,
      [tableName]: data.get('files')
    }
    setTableFiles(newTableData)
    setUploadButtonText('Upload')
  }

  const onDeleteFile = (tableName: string) => () => {
    console.log('delete tableName', tableName)
    setTableFiles({
      ...tableFiles,
      [tableName]: null
    })
    setUploadButtonText('Upload')
  }

  const onClickUpload = async () => {
    setIsUploading(true)
    setUploadErrorMessage('')

    const uploadId = uuid()
    const storageRef = firebase.storage().ref(`xlsxUploads/${uploadId}`)
    const uploadPromises = []
    const tablesUploaded: { [tableName: string]: boolean } = {}
    try {
      console.log({ uploadId })
      // upload files
      for (const [tableName, file] of Object.entries(tableFiles)) {
        if (!file) continue
        uploadPromises.push(storageRef.child(tableName).put(file as Blob))
        tablesUploaded[tableName] = true
      }
      await Promise.all(uploadPromises)
      await firebase
        .functions()
        .httpsCallable('ingest')({ uploadId, tables: tablesUploaded })
        .then(r => console.log(r.data))
      setUploadButtonText('Success!')
    } catch (e) {
      console.error(e)
      console.log(e.details)
      if (e.details) {
        setIsModalOpen(true)
        setModalContent(<ErrorJSONTree header={e.message} data={e.details} />)
      } else {
        setUploadErrorMessage(e.message)
      }
      setUploadButtonText(`Error! Retry?`)
    }

    await updateTimestamps()
    setIsUploading(false)
    loadGraphData()
  }

  const onClickRollback = async () => {
    setIsRollingBack(true)
    try {
      await firebase.functions().httpsCallable('rollback')()
      setRollbackResultMessage('')
    } catch (e) {
      setRollbackResultMessage('Error. Retry?')
      console.error(e)
    }
    await updateTimestamps()
    loadGraphData()
    setIsRollingBack(false)
    setUploadButtonText('Upload')
  }

  const tableFileInputs = tableNames.map(([tableName, label]) => (
    <TableFileInput
      key={tableName}
      label={label}
      tableName={tableName}
      onUpload={onAddFile(tableName)}
      onDeleteFile={onDeleteFile(tableName)}
    />
  ))

  return (
    <div style={styles.ingestOuter}>
      <div style={styles.filesFormOuter}>
        <div style={styles.heading}>Upload Data</div>
        <div style={styles.filesOuter}>{tableFileInputs}</div>
        <div style={styles.uploadErrorMessageOuter}>
          <div style={styles.uploadErrorText}>{uploadErrorMessage}</div>
        </div>
        <PrimaryButton
          onClick={onClickUpload}
          disabled={isUploading ? false : !Object.values(tableFiles).some(file => file !== null)}
          styles={{
            root: {
              width: '100%'
            }
          }}>
          {isUploading ? <Spinner /> : uploadButtonText || 'Upload'}
        </PrimaryButton>
      </div>
      <div style={styles.historyOuter}>
        <div style={styles.heading}>History</div>
        <div style={{ marginBottom: '1em' }}>
          <div>
            <div>Current: {currentUpdatedAt}</div>
          </div>
          <div>
            <div>Previous: {previousUpdatedAt}</div>
          </div>
        </div>
        <PrimaryButton
          onClick={onClickRollback}
          styles={{
            root: {
              width: '100%',
              backgroundColor: '#ff0000',
              borderColor: 'transparent'
            },
            rootHovered: {
              backgroundColor: '#de0000',
              borderColor: 'transparent'
            },
            rootPressed: {
              backgroundColor: '#c20000',
              borderColor: 'transparent'
            }
          }}
          disabled={previousUpdatedAt === 'N/A'}>
          {isRollingBack ? <Spinner /> : rollbackResultMessage || 'Rollback to Previous'}
        </PrimaryButton>
      </div>
      <Modal
        isOpen={isModalOpen}
        styles={{ main: { padding: '1em' }, scrollableContent: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }}>
        {modalContent}
        <PrimaryButton onClick={() => setIsModalOpen(false)}>Close</PrimaryButton>
      </Modal>
    </div>
  )
}
