import Cytoscape, { ElementsDefinition, ElementDefinition, EdgeDefinition, NodeDefinition } from 'cytoscape'
import { IGraphData, IGraphDrug, IGraphPDB } from 'krogan-types'

interface NodesRef {
  [id: string]: NodeDefinition
}

export function getVirusNodeId(name: string): string {
  return 'virus_' + name.toLowerCase()
}

export function getVirusCompoundNodeId(name: string): string {
  return 'virus_compound_' + name.toLowerCase()
}

export function getHumanNodeId(name: string): string {
  return 'human_' + name.toLowerCase()
}

export function getComplexId(complexName: string, virusId: string) {
  return 'complex_' + complexName + '_' + virusId
}

function transformDataToElements(tableData: IGraphData): Cytoscape.ElementDefinition[] | ElementsDefinition {
  // console.log({ tableData })
  const nodes: NodeDefinition[] = []
  const edges: EdgeDefinition[] = []

  const humansReference: NodesRef = {}
  const virusReference: NodesRef = {}
  const virusCompoundReference: NodesRef = {}

  const {
    virusData,
    virusToHumanEdges,
    humanData,
    humanToHuman,
    humanToDrug,
    drugData,
    pdbData,
    virusToPDB,
    humanToPhos,
    virusToPhos
  } = tableData

  // virus nodes
  for (const virusName in virusData) {
    const virus = virusData[virusName]
    const phos = virusToPhos ? virusToPhos[virusName] : undefined

    if (phos) console.log({ virusPhos: phos, virusName })

    const pdbs = Array.isArray(virusToPDB[virusName])
      ? virusToPDB[virusName].reduce((pdbObj: { [drugName: string]: IGraphPDB }, pdbName) => {
          pdbObj[pdbName] = pdbData[pdbName]
          return pdbObj
        }, {})
      : undefined

    const virusId = getVirusNodeId(virusName)
    const virusDef: NodeDefinition = {
      data: {
        id: virusId,
        label: virusName,
        type: 'virus',
        kDa: virus['MW (kDa)'],
        similarity: virus['% Similarity (to SARS-CoV)'],
        aaLength: virus['Length (aa)'],
        func: virus['Function'],
        description: virus['Description'],
        pdbs,
        phosphorylation: phos
      },
      classes: 'virus'
    }
    nodes.push(virusDef)
    virusReference[virusId] = virusDef

    const virusCompoundId = getVirusCompoundNodeId(virusName)
    const virusCompoundDef = {
      data: {
        id: virusCompoundId,
        label: virusName,
        type: 'virus-compound'
      },
      classes: 'virus-compound'
    }
    nodes.push(virusCompoundDef)
    virusCompoundReference[virusCompoundId] = virusCompoundDef

    virusReference[virusId].data.parent = virusCompoundId
  }

  // human nodes
  for (const humanName in humanData) {
    const human = humanData[humanName]

    const drugs = Array.isArray(humanToDrug[humanName])
      ? humanToDrug[humanName].reduce((humanDrugs: { [drugName: string]: IGraphDrug }, drugName) => {
          humanDrugs[drugName] = drugData[drugName]
          return humanDrugs
        }, {})
      : undefined

    const phos = humanToPhos ? humanToPhos[humanName] : undefined

    const humanId = getHumanNodeId(humanName)
    const humanDef: NodeDefinition = {
      data: {
        id: humanId,
        label: humanName,
        type: 'human',
        preyGene: human.PreyGeneName,
        preys: human.PreyUniprotAcc,
        uniprotProteinDescription: human['Uniprot Protein Description'],
        structuresPdb: human['Structures (PDB)'],
        uniprotFunction: human['Uniprot Function'],
        uniprotFunctionInDisease: human['Uniprot Function in Disease'],
        // drugs: humanToDrug[humanName]
        drugs,
        phosphorylationSites: phos
      },
      classes: 'human'
    }
    nodes.push(humanDef)
    humansReference[humanId] = humanDef
  }

  // drug nodes
  // for (const humanName in humanToDrug) {
  //   const humanDrugs = humanToDrug[humanName]
  //   for (const drugName in humanDrugs) {
  //     const drug = humanDrugs[drugName]
  //     nodes.push({data: {
  //       id: 'drug_' + drugName + '_' + humanName,
  //       label: drugName,
  //       type: 'drug',
  //     }})
  //   }
  // }

  for (const virusName in virusToHumanEdges) {
    const virusCompoundId = getVirusCompoundNodeId(virusName)
    const virusId = getVirusNodeId(virusName)

    for (const humanName in virusToHumanEdges[virusName]) {
      const edge = virusToHumanEdges[virusName][humanName]
      const otherViruses = edge.OtherViruses && edge.OtherViruses.length > 0 ? edge.OtherViruses : undefined
      const uniqueViruses = new Set()
      otherViruses?.forEach(virus => {
        const family = virus.split(' ')[0]
        uniqueViruses.add(family)
      })

      const humanId = getHumanNodeId(humanName)
      edges.push({
        data: {
          source: virusId,
          sourceLabel: virusName,
          target: humanId,
          targetLabel: humanName,
          type: 'virus-to-human',
          mist: edge.MIST,
          bfdr: edge.SAINT_BFDR,
          avgSpec: edge.AvgSpec
        },
        classes: 'virus-to-human'
      })

      humansReference[humanId].data.parent = virusCompoundId
      humansReference[humanId].data.otherViruses = otherViruses
      humansReference[humanId].data.otherVirusesCount = uniqueViruses.size
    }
  }

  const complexes: { [id: string]: { node: NodeDefinition; virusId: string; humans: Set<NodeDefinition> } } = {}
  for (const humanSourceName in humanToHuman) {
    for (const humanTargetName in humanToHuman[humanSourceName]) {
      const edge = humanToHuman[humanSourceName][humanTargetName]

      const humanSourceId = getHumanNodeId(humanSourceName)
      const humanTargetId = getHumanNodeId(humanTargetName)
      const edgeDef: EdgeDefinition = {
        data: {
          source: humanSourceId,
          sourceLabel: humanSourceName,
          target: humanTargetId,
          targetLabel: humanTargetName,
          type: 'human-to-human',
          complexOrProcess: edge.ComplexProcessName,
          isHumanPPI: edge.is_HumanPPI,
          isComplexProcess: String(edge.is_ComplexProcess)
        },
        classes: ['human-to-human', edge.is_ComplexProcess].join(' ')
      }
      edges.push(edgeDef)

      const parent = humansReference[humanSourceId].data.parent || humansReference[humanTargetId].data.parent
      humansReference[humanSourceId].data.parent = parent
      humansReference[humanTargetId].data.parent = parent

      if (edge.is_ComplexProcess !== 'NA') {
        humansReference[humanSourceId].classes += ' ' + edge.is_ComplexProcess
        humansReference[humanTargetId].classes += ' ' + edge.is_ComplexProcess
        humansReference[humanSourceId].data.complexOrProcess = edge.ComplexProcessName
        humansReference[humanSourceId].data.isComplexProcess = String(edge.is_ComplexProcess)
        humansReference[humanTargetId].data.complexOrProcess = edge.ComplexProcessName
        humansReference[humanTargetId].data.isComplexProcess = String(edge.is_ComplexProcess)
      }

      if (edge.ComplexProcessName) {
        const virusId = humansReference[humanSourceId].data.parent || humansReference[humanTargetId].data.parent || ''
        const complexId = getComplexId(edge.ComplexProcessName, virusId)
        if (!complexes[complexId]) {
          complexes[complexId] = {
            virusId,
            node: {
              data: {
                id: complexId,
                parent: virusId,
                type: 'complex-or-process',
                label: edge.ComplexProcessName,
                isComplexProcess: String(edge.is_ComplexProcess)
              },
              classes: ['complex-or-process', edge.is_ComplexProcess].join(' ')
            },
            humans: new Set()
          }
        }
        complexes[complexId].humans.add(humansReference[humanSourceId])
        complexes[complexId].humans.add(humansReference[humanTargetId])
      }
    }
  }

  for (const complexId in complexes) {
    const complex = complexes[complexId]
    complex.humans.forEach(node => {
      node.data.parent = complexId
    })
    nodes.push(complex.node)
  }

  return {
    nodes,
    edges
  }
}

export default transformDataToElements
