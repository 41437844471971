import React from 'react'
import './index.css'

interface IVirusToHumanInfoProps {
  source: string
  target: string
  mist?: number
  bfdr?: number
  avgSpec?: number
}

function VirusToHumanInfo(props: IVirusToHumanInfoProps) {
  const { source, target, mist, bfdr, avgSpec } = props

  return (
    <div className="tip-modal-content">
      <div className="tip-row">
        <div className="tip-header">Bait-Prey Edge Information</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Bait:</div>
        <div className="tip-col-value">{source}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Prey:</div>
        <div className="tip-col-value">{target}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">MIST: </div>
        <div className="tip-col-value">{mist}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">BFDR: </div>
        <div className="tip-col-value">{bfdr}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Avg Spec: </div>
        <div className="tip-col-value">{avgSpec}</div>
      </div>

      {/* <div className="tip-row">
        <div className="tip-col-name">Other Viruses: </div>
        <div className="tip-col-value">{otherViruses && otherViruses.join(', ')}</div>
      </div> */}
    </div>
  )
}

export default VirusToHumanInfo
