import React from 'react'
import './index.css'

interface IHumanToHumanInfoProps {
  source: string
  target: string
  complexOrProcess?: string
  isHumanPPI?: boolean
  isComplexProcess?: string
}

function HumanToHumanInfo(props: IHumanToHumanInfoProps) {
  const { source, target, complexOrProcess, isHumanPPI, isComplexProcess } = props

  return (
    <div className="tip-modal-content long-col-name">
      <div className="tip-row">
        <div className="tip-header">Prey-Prey Edge Information</div>
      </div>
      <div className="tip-row">
        <div className="tip-col-name">Preys:</div>
        <div className="tip-col-value">
          {source}, {target}
        </div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Is Human PPI: </div>
        <div className="tip-col-value">{isHumanPPI ? 'True' : 'False'}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Is Complex or Process: </div>
        <div className="tip-col-value">{isComplexProcess}</div>
      </div>

      {isComplexProcess !== 'NA' && (
        <div className="tip-row">
          <div className="tip-col-name">{isComplexProcess + ': '}</div>
          <div className="tip-col-value">{complexOrProcess}</div>
        </div>
      )}
    </div>
  )
}

export default HumanToHumanInfo
