import React, { useState, useEffect, useContext } from 'react'
import * as firebase from 'firebase'

export enum OnlineStatus {
  Loading,
  SignedIn,
  SignedOut
}

export interface IAuth {
  userInfo?: any
  online?: OnlineStatus
}

const defaultState: IAuth = {}

export const AuthContext = React.createContext(defaultState)

interface IProps {}

export const signOut = () => firebase.auth().signOut()

export const googleSignIn = () => {
  let provider = new firebase.auth.GoogleAuthProvider()

  firebase
    .auth()
    .signInWithPopup(provider)
    .then(function (result) {
      // console.log(result)
      // This gives you a Google Access Token. You can use it to access the Google API.
      // var token = result.credential.accessToken
      // // The signed-in user info.
      // var user = result.user
      // ...
    })
    .catch(function (error) {
      // Handle Errors here.
      // var errorCode = error.code
      // var errorMessage = error.message
      // // The email of the user's account used.
      // var email = error.email
      // // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential
      // ...
    })
}

export const AuthIsSignedIn: React.FunctionComponent = props => {
  const { online }: IAuth = useContext(AuthContext)

  return <div>{online === OnlineStatus.SignedIn ? props.children : null}</div>
}

export const AuthIsNotSignedIn: React.FunctionComponent = props => {
  const { online }: IAuth = useContext(AuthContext)

  return <div>{online === OnlineStatus.SignedOut ? props.children : null}</div>
}

export const AuthIsLoading: React.FunctionComponent = props => {
  const { online }: IAuth = useContext(AuthContext)

  return <div>{online === OnlineStatus.Loading ? props.children : null}</div>
}

const AuthProvider: React.FunctionComponent<IProps> = props => {
  const [online, setOnline] = useState(OnlineStatus.Loading)
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    setOnline(OnlineStatus.Loading)
    const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      // console.log('firebase onAuthStateChanged', user)
      if (user) {
        setOnline(OnlineStatus.SignedIn)
        setUserInfo(user)
      } else {
        setOnline(OnlineStatus.SignedOut)
        setUserInfo({})
      }
    })
    return unsubscribe
  }, [])

  const state: IAuth = {
    userInfo,
    online
  }

  return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>
}

export default AuthProvider
