import React, { useState } from 'react'
import { Checkbox, IconButton } from 'office-ui-fabric-react'

export interface ICheckListItem {
  key: string
  label: string
  subLabels?: string[]
  checked?: boolean
  children?: ICheckListItem[]
}

export interface ICheckListProps {
  items: ICheckListItem[]
  level?: number
  onItemsChecked?: (keys: string[], checked: boolean) => void
  defaultCollapsed?: boolean
}

export const checkboxStyles: any = {
  label: {
    alignItems: 'flex-start',
    width: 'calc(100% - 1em)',
    // selectors: {
    //   ':hover': {
    //     color: 'var(--black)'
    //   }
    // },
    padding: '.5em'
  },
  checkmark: {
    color: 'var(--neutralQuaternary)'
  }
}

const chevronButtonStyles = (collapsed: boolean): any => {
  return {
    root: {
      transform: collapsed ? undefined : 'rotate(90deg)'
    },
    icon: {
      fontSize: '.75em',
      color: 'var(--themePrimary)'
    }
  }
}

function getCheckListItemKeys(items: ICheckListItem[]): string[] {
  const ret: string[] = []
  for (const item of items) {
    if (!item.children) ret.push(item.key)
    else ret.push(...getCheckListItemKeys(item.children))
  }
  return ret
}

export function CheckList(props: ICheckListProps) {
  const { items, level = 0, onItemsChecked, defaultCollapsed = true } = props

  const onChangeCallback = (key: string) => (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    if (onItemsChecked) onItemsChecked([key], typeof checked === 'boolean' ? checked : false)
  }

  const [parentsCollapsed, setParentsCollapsed] = useState<{ [key: string]: boolean }>({})

  return (
    <div>
      {items.map(item => {
        if (item.children) {
          const numChildren = item.children.length
          const numChildrenChecked = item.children.reduce((count, childItem) => {
            return count + (childItem.checked ? 1 : 0)
          }, 0)

          const checked = numChildren === numChildrenChecked
          const indeterminate = numChildrenChecked < numChildren && numChildrenChecked > 0
          const onChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
            if (onItemsChecked && item.children)
              onItemsChecked(getCheckListItemKeys(item.children), typeof checked === 'boolean' ? checked : false)
          }

          const collapsed = item.key in parentsCollapsed ? parentsCollapsed[item.key] : defaultCollapsed
          const setCollapsed = () => {
            const copy = Object.assign({}, parentsCollapsed)
            copy[item.key] = !collapsed
            setParentsCollapsed(copy)
          }

          return (
            <div key={item.key} className="filter-collapse-group-outer">
              <div className="filter-collapse-group-header">
                <IconButton
                  styles={chevronButtonStyles(collapsed)}
                  iconProps={{
                    iconName: 'CaretSolidRight'
                  }}
                  onClick={setCollapsed}
                />
                <Checkbox
                  key={item.key}
                  styles={checkboxStyles}
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={onChange}
                  onRenderLabel={() => {
                    return (
                      <div className="filter-collapse-group">
                        <div className="filter-label">
                          <div className="filter-label-main multi">
                            {item.label} {item.children ? `(${item.children.length})` : ''}
                          </div>
                          {item.subLabels &&
                            item.subLabels.map(subLabel => {
                              return (
                                <div className="filter-label-sub" key={subLabel}>
                                  {subLabel}
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    )
                  }}
                />
              </div>
              {!collapsed && (
                <div style={{ marginLeft: '2rem' }}>
                  <CheckList items={item.children} onItemsChecked={onItemsChecked} level={level + 1} />
                </div>
              )}
            </div>
          )
        } else {
          return (
            <Checkbox
              key={item.key}
              // label={term}
              onChange={onChangeCallback(item.key)}
              checked={item.checked}
              styles={checkboxStyles}
              onRenderLabel={() => {
                return (
                  <div className="filter-label">
                    <div className="filter-label-main"> {item.label} </div>
                    {item.subLabels &&
                      item.subLabels.map(subLabel => {
                        return (
                          <div className="filter-label-sub" key={subLabel}>
                            {subLabel}
                          </div>
                        )
                      })}
                  </div>
                )
              }}
            />
          )
        }
      })}
    </div>
  )
}
