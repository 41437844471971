import React, { useCallback, useState, useContext, useEffect } from 'react'
import Graph from '../../components/Graph'
import Cytoscape from 'cytoscape'
import { INodeCollectionGroups, organizeCovid19Graph, arrangeNodesInGrid, applyFilter, IArrangeNodeOptions } from './GraphActions'
import transformDataToElements from './processData'
import InfoPane from './InfoPane'
import { LightStyles, DarkStyles } from './GraphStyles'
import * as LightStyleColors from './GraphStyles/light'
import * as DarkStyleColors from './GraphStyles/dark'
import { GraphContext } from '../../contexts/graphContext'
import { IExtraFilterTerm } from '../../components/NamesFilterPanel'
import { Spinner } from 'office-ui-fabric-react'

import { GraphSettingsContext } from '../../contexts/graphSettings'
import { GraphFiltersContext, IUpdateAllDataParam } from '../../contexts/graphFilters'

// cytoscape extensions
import CISE from 'cytoscape-cise'
import BCOSE from 'cytoscape-cose-bilkent'
import FCOSE from 'cytoscape-fcose'
import KLAY from 'cytoscape-klay'
import COLA from 'cytoscape-cola'
import AVSDF from 'cytoscape-avsdf'
import SarsCov2Overlay from './SarsCov2Overlay'
import { ThemeContext } from '../../contexts/theme'

Cytoscape.use(CISE)
Cytoscape.use(BCOSE)
Cytoscape.use(FCOSE)
Cytoscape.use(KLAY)
Cytoscape.use(COLA)
Cytoscape.use(AVSDF)

const minZoom = 0.05
const maxZoom = 25

export interface ISarsCov2GraphSaveData {
  filters: {
    mode: string
    activeTerms: {
      [key: string]: string[]
    }
    searchTerms: {
      [key: string]: string
    }
  }
  layoutOptions: IArrangeNodeOptions
  cyOptions: {
    pan: {
      x: number
      y: number
    }
    zoom: number
    minZoom: number
    maxZoom: number
    nodes: Cytoscape.NodeDefinition[]
  }
}

export interface ISarsCov2GraphProps {}

const SarsCov2Graph = (props: ISarsCov2GraphProps) => {
  // use context for data
  const { graphDataIsLoading, graphData, graphView } = useContext(GraphContext)
  const graphSettings = useContext(GraphSettingsContext)
  const graphFilters = useContext(GraphFiltersContext)
  const { theme } = useContext(ThemeContext)

  const [elementsData, setElementsData] = useState<Cytoscape.ElementDefinition[] | Cytoscape.ElementsDefinition>()

  // transform new data when fetched
  useEffect(() => {
    setElementsData(transformDataToElements(graphData.data))
  }, [graphData])

  // load graph view
  useEffect(() => {
    if (!graphSettings.cy || Object.keys(graphView).length === 0) return

    const cy = graphSettings.cy
    const { filters: filtersData, layoutOptions, cyOptions } = graphView

    const allFilterUpdateProps: IUpdateAllDataParam = {
      baits: {},
      otherPathogens: {},
      drugs: {},
      pdbs: {},
      phos: {}
    }

    for (const key in filtersData.searchTerms) {
      if (allFilterUpdateProps[key]) {
        allFilterUpdateProps[key].searchText = filtersData.searchTerms[key]
      }
    }

    for (const key in filtersData.activeTerms) {
      if (allFilterUpdateProps[key]) {
        allFilterUpdateProps[key].activeTerms = filtersData.activeTerms[key]
      }
    }

    const elementGroups = graphFilters.data[filtersData.mode].elementGroups
    const activeTerms = filtersData.activeTerms[filtersData.mode]
    if (elementGroups && activeTerms) {
      applyFilter(graphSettings.cy, elementGroups, activeTerms, true)
      arrangeNodesInGrid(cy, cy.nodes('.virus-compound').not('.hidden'), layoutOptions)
    }

    if (graphFilters.updateAllFilters) {
      graphFilters.updateAllFilters(allFilterUpdateProps)
    }

    if (graphFilters.update) {
      graphFilters.update({ filterMode: filtersData.mode })
    }

    graphSettings.update({
      layoutOptions,
      zoom: cyOptions.zoom,
      minZoom: cyOptions.minZoom,
      maxZoom: cyOptions.maxZoom
    })

    cy.zoom(cyOptions.zoom)
    cy.pan(cyOptions.pan)
    cy.minZoom(cyOptions.minZoom)
    cy.maxZoom(cyOptions.maxZoom)

    const nodesByTypes: { [key: string]: Cytoscape.NodeDefinition[] } = {}
    cyOptions.nodes.forEach((node: any) => {
      if (!nodesByTypes[node.data.type]) nodesByTypes[node.data.type] = []
      nodesByTypes[node.data.type].push(node)
    })
    ;['human', 'virus'].forEach(type => {
      if (!nodesByTypes[type]) return
      for (const node of nodesByTypes[type]) {
        const { data, position } = node
        const { id } = data
        if (id && position) {
          const cyNode = cy.getElementById(id).nodes().first()
          cyNode.position(position)
        }
      }
    })
  }, [graphView, graphSettings.cy])

  const initializeGraph = useCallback(
    (cy: Cytoscape.Core) => {
      organizeCovid19Graph(cy)

      const allFilterUpdateProps: IUpdateAllDataParam = {
        baits: {},
        otherPathogens: { groupBy: term => term.split(' ')[0] },
        drugs: {},
        pdbs: {},
        phos: {}
      }

      const baitCompounds = cy.nodes('.virus-compound')
      const baitGroups: INodeCollectionGroups = {}
      baitCompounds.forEach(compound => {
        const nodes = compound.descendants().union(compound)
        const edges = nodes.connectedEdges()
        const virusNetwork = nodes.union(edges)
        baitGroups[compound.data('label')] = virusNetwork
      })
      allFilterUpdateProps.baits.elementGroups = baitGroups

      // set filter panel options
      const allBaits = baitCompounds.map(node => node.data('label')).sort()
      allFilterUpdateProps.baits.activeTerms = allBaits
      allFilterUpdateProps.baits.allTerms = allBaits

      const baitExtraTerms: IExtraFilterTerm[] = []
      const allOtherViruses: { [key: string]: Cytoscape.NodeSingular[] } = {}
      const allDrugs: { [key: string]: Cytoscape.NodeSingular[] } = {}
      const allPhosSite: { [key: string]: Cytoscape.NodeSingular[] } = {}
      for (const baitName in baitGroups) {
        const humanNodes = baitGroups[baitName].nodes('.human')
        humanNodes.forEach(node => {
          baitExtraTerms.push({
            key: baitName,
            term: node.data('label'),
            relation: 'Prey'
          })

          const otherViruses: string[] = node.data('otherViruses') || []
          otherViruses.forEach(v => {
            if (!allOtherViruses[v]) allOtherViruses[v] = []
            allOtherViruses[v].push(node)
          })

          const drugs: string[] = Object.keys(node.data('drugs') || {})
          drugs.forEach(drugName => {
            if (!allDrugs[drugName]) allDrugs[drugName] = []
            allDrugs[drugName].push(node)
          })

          const phosSites: string[] = Object.keys(node.data('phosphorylationSites') || {})
          phosSites.forEach(site => {
            const prependedSite = node.data('label') + '_' + site

            if (!allPhosSite[prependedSite]) allPhosSite[prependedSite] = []
            allPhosSite[prependedSite].push(node)
          })
        })
      }
      allFilterUpdateProps.baits.extraTerms = baitExtraTerms

      const virusNodes = cy.nodes('.virus')
      const allPdbs: { [key: string]: Cytoscape.NodeSingular[] } = {}
      const allVirusPhos: { [key: string]: Cytoscape.NodeSingular[] } = {}
      virusNodes.forEach(virusNode => {
        const pdbs: string[] = Object.keys(virusNode.data('pdbs') || {})
        pdbs.forEach(pdb => {
          if (!allPdbs[pdb]) allPdbs[pdb] = []
          allPdbs[pdb].push(virusNode)
        })

        const virusPhos = virusNode.data('phosphorylation')
        const virusPhosSites = virusPhos && virusPhos.sites ? Object.keys(virusPhos.sites) : []
        virusPhosSites.forEach(site => {
          const prependedSite = virusNode.data('label') + '_' + site
          if (!allVirusPhos[prependedSite]) allVirusPhos[prependedSite] = []
          allVirusPhos[prependedSite].push(virusNode)
        })
      })

      // other viruses
      const otherVirusesGroups: INodeCollectionGroups = {}
      for (const virusName in allOtherViruses) {
        const humanNodes = cy.collection(allOtherViruses[virusName])
        const compounds = humanNodes.ancestors()
        const baits = humanNodes.neighborhood('node.virus')
        const virusToHumanEdges = baits.edgesTo(humanNodes)
        const humanToHumanEdges = humanNodes.neighborhood('edge.human-to-human')
        otherVirusesGroups[virusName] = humanNodes.union(compounds).union(baits).union(virusToHumanEdges).union(humanToHumanEdges)
      }
      allFilterUpdateProps.otherPathogens.elementGroups = otherVirusesGroups
      const allOtherVirusesArr = Object.keys(allOtherViruses).sort()
      allFilterUpdateProps.otherPathogens.allTerms = allOtherVirusesArr
      allFilterUpdateProps.otherPathogens.activeTerms = allOtherVirusesArr

      // drugs
      const drugGroups: INodeCollectionGroups = {}
      for (const drugName in allDrugs) {
        const humanNodes = cy.collection(allDrugs[drugName])
        const parents = humanNodes.ancestors()
        const baits = humanNodes.neighborhood('node.virus')
        const virusToHumanEdges = baits.edgesTo(humanNodes)
        const humanToHumanEdges = humanNodes.neighborhood('edge.human-to-human')
        drugGroups[drugName] = humanNodes.union(parents).union(baits).union(virusToHumanEdges).union(humanToHumanEdges)
      }
      allFilterUpdateProps.drugs.elementGroups = drugGroups
      const drugsArr = Object.keys(drugGroups).sort()
      allFilterUpdateProps.drugs.allTerms = drugsArr
      allFilterUpdateProps.drugs.activeTerms = drugsArr

      // human phosphorylation
      const phosGroups: INodeCollectionGroups = {}
      for (const phosSite in allPhosSite) {
        const humanNodes = cy.collection(allPhosSite[phosSite])
        const parents = humanNodes.ancestors()
        const baits = humanNodes.neighborhood('node.virus')
        const virusToHumanEdges = baits.edgesTo(humanNodes)
        const humanToHumanEdges = humanNodes.neighborhood('edge.human-to-human')
        phosGroups[phosSite] = humanNodes.union(parents).union(baits).union(virusToHumanEdges).union(humanToHumanEdges)
      }

      // virus phosphorylation
      for (const virusPhosSite in allVirusPhos) {
        const virusNetworks = allVirusPhos[virusPhosSite].map(virus => baitGroups[virus.data('label')])
        let totalNetwork = cy.collection()
        virusNetworks.forEach(n => (totalNetwork = totalNetwork.union(n)))
        phosGroups[virusPhosSite] = totalNetwork
      }

      allFilterUpdateProps.phos.elementGroups = phosGroups
      const phosArr = Object.keys(phosGroups).sort()
      allFilterUpdateProps.phos.allTerms = phosArr
      allFilterUpdateProps.phos.activeTerms = phosArr

      // PDBs
      const pdbGroups: INodeCollectionGroups = {}
      for (const pdbName in allPdbs) {
        const virusNetworks = allPdbs[pdbName].map(virusName => baitGroups[virusName.data('label')])
        let totalNetwork = cy.collection()
        virusNetworks.forEach(n => (totalNetwork = totalNetwork.union(n)))
        pdbGroups[pdbName] = totalNetwork
      }
      allFilterUpdateProps.pdbs.elementGroups = pdbGroups
      const allPdbsArr = Object.keys(allPdbs).sort()
      allFilterUpdateProps.pdbs.allTerms = allPdbsArr
      allFilterUpdateProps.pdbs.activeTerms = allPdbsArr

      cy.on('zoom', () => {
        graphSettings.update({ zoom: cy.zoom() })
      })

      if (graphFilters.updateAllFilters) {
        graphFilters.updateAllFilters(allFilterUpdateProps)
      }

      const elementGroups = allFilterUpdateProps[graphFilters.filterMode].elementGroups
      const allTerms = allFilterUpdateProps[graphFilters.filterMode].allTerms
      if (elementGroups && allTerms) {
        applyFilter(cy, elementGroups, allTerms, true)
      }

      arrangeNodesInGrid(cy, cy.nodes('.virus-compound').not('.hidden'), graphSettings.layoutOptions)
    },
    [graphSettings, graphFilters]
  )

  const setCytoscapeRef = useCallback(
    (cy: Cytoscape.Core | null) => {
      graphSettings.update({ cy: cy ? cy : undefined })
      graphFilters.update({ cy: cy ? cy : undefined })
      if (!cy) return
      initializeGraph(cy)
    },
    [graphSettings, initializeGraph]
  )

  const [elementFocused, setElementFocused] = useState<Cytoscape.NodeSingular | Cytoscape.EdgeSingular>()
  const onElementFocused = useCallback(
    (element: Cytoscape.NodeSingular | Cytoscape.EdgeSingular) => {
      const validTypes = ['virus', 'human', 'virus-to-human', 'human-to-human', 'complex-or-process']
      if (validTypes.indexOf(element.data('type')) !== -1) {
        setElementFocused(element)
      }
    },
    [setElementFocused]
  )

  const onInfoPaneDismiss = useCallback(() => {
    setElementFocused(undefined)
  }, [setElementFocused])

  return (
    <>
      {graphDataIsLoading && (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner />
        </div>
      )}
      {!graphDataIsLoading && elementsData && (
        <div
          className={'sarscov2-graph-outer'}
          style={{ backgroundColor: theme === 'light' ? LightStyleColors.backgroundColor : DarkStyleColors.backgroundColor }}>
          <Graph
            minZoom={minZoom}
            maxZoom={maxZoom}
            elements={elementsData}
            wheelSensitivity={0.5}
            onElementFocused={onElementFocused}
            styles={theme === 'light' ? LightStyles : DarkStyles}
            cyRef={setCytoscapeRef}
            style={{ width: graphSettings.width, height: 'calc(100% - var(--navHeaderHeight))', top: 'var(--navHeaderHeight)' }}>
            <InfoPane element={elementFocused} onDismissEnd={onInfoPaneDismiss} />
          </Graph>
          <SarsCov2Overlay />
        </div>
      )}
    </>
  )
}

export default SarsCov2Graph
