import React, { useState, useContext } from 'react'
import { TextField, PrimaryButton } from 'office-ui-fabric-react'

import { FirebaseContext } from '../contexts/firebaseContext'

const styles = {
  loginFormOuter: {
    width: '15em',
    maxWidth: '100%'
    // backgroundColor: 'gray'
  },
  errorMessageOuter: {
    height: '3em',
    padding: '.5em'
  },
  errorMessageText: {
    fontSize: '.8em'
  },
  heading: {
    fontSize: '1.5em',
    fontWeight: 600,
    padding: '.5em'
  }
}

interface IProps {}
export default function LoginForm(props: IProps) {
  const { firebase } = useContext(FirebaseContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErroMessage] = useState('')

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(e => {
        setErroMessage(e.message)
      })
  }

  return (
    <form style={styles.loginFormOuter} onSubmit={onSubmit}>
      <div style={styles.heading}>Login</div>
      <TextField label="Email" value={email} onChange={(e, value) => setEmail(value as string)} />
      <TextField label="Password" type="password" value={password} onChange={(e, value) => setPassword(value as string)} />
      <div style={styles.errorMessageOuter}>
        <div style={styles.errorMessageText}>{errorMessage}</div>
      </div>
      <PrimaryButton type="submit">Login</PrimaryButton>
    </form>
  )
}
