import React, { useState, useEffect } from 'react'
import { Link, DetailsList, IColumn, SelectionMode, CheckboxVisibility } from 'office-ui-fabric-react'
import { IGraphPDB } from 'krogan-types'

interface IPdbTableProps {
  pdb: {
    [name: string]: IGraphPDB
  }
  onPdbSelected?: (item: IGraphPDB) => void
  onOpenPdbViewer?: (item: IGraphPDB) => void
}

function getPdbTableColumns(onItemSelected?: (item: any) => void, onOpenPdbViewer?: (item: any) => void): IColumn[] {
  return [
    {
      key: 'PDB ID',
      fieldName: 'PDB ID',
      name: 'PDB ID',
      minWidth: 60,
      maxWidth: 80,
      data: 'string',
      isResizable: true,
      isPadded: true,
      isRowHeader: true,
      onRender: item => {
        return <Link onClick={() => onItemSelected && onItemSelected(item)}>{item['PDB ID']}</Link>
      }
    },
    {
      key: '3D Viewer',
      name: 'View in 3D',
      minWidth: 60,
      maxWidth: 120,
      data: 'string',
      isResizable: true,
      isPadded: true,
      isRowHeader: true,
      onRender: item => {
        return <Link onClick={() => onOpenPdbViewer && onOpenPdbViewer(item)}>{'3D PDB Viewer'}</Link>
      }
    },
    {
      key: 'Structure Title (PDB)',
      fieldName: 'Structure Title (PDB)',
      name: 'Structure Title (PDB)',
      minWidth: 150,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Node Name',
      fieldName: 'Node Name',
      name: 'Node Name',
      minWidth: 50,
      data: 'string',
      isPadded: true,
      isResizable: true
    },

    {
      key: 'Protein Name(s)',
      fieldName: 'Protein Name(s)',
      name: 'Protein Name(s)',
      minWidth: 50,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Exp. Method',
      fieldName: 'Exp. Method',
      name: 'Exp. Method',
      minWidth: 100,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Resolution (Å)',
      fieldName: 'Resolution (Å)',
      name: 'Resolution (Å)',
      minWidth: 70,
      data: 'number',
      isResizable: true,
      isPadded: true
    },

    {
      key: 'PDB DOI',
      fieldName: 'PDB DOI',
      name: 'PDB DOI',
      minWidth: 110,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Taxonomy ID',
      fieldName: 'Taxonomy ID',
      name: 'Taxonomy ID',
      minWidth: 80,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Virus',
      fieldName: 'Virus',
      name: 'Virus',
      minWidth: 70,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Link to DOI',
      fieldName: 'Link to DOI',
      name: 'Link to DOI',
      minWidth: 100,
      isResizable: true,
      isPadded: true,
      onRender: item => {
        return (
          <Link href={item['Link to DOI']} target="_blank">
            {item['Link to DOI']}
          </Link>
        )
      }
    }
  ]
}

function PdbTable(props: IPdbTableProps) {
  const { pdb, onPdbSelected, onOpenPdbViewer } = props

  const pdbItems = Object.keys(pdb).map(pdbId => {
    return {
      key: pdbId,
      name: pdbId,
      ...pdb[pdbId]
    }
  })

  const [columns, setColumns] = useState(getPdbTableColumns(onPdbSelected, onOpenPdbViewer))
  useEffect(() => {
    setColumns(getPdbTableColumns(onPdbSelected, onOpenPdbViewer))
  }, [onPdbSelected, onOpenPdbViewer])

  return (
    <div className="info-table-outer">
      <div className="info-table">
        <DetailsList
          key={'details_list'}
          items={pdbItems}
          columns={columns}
          selectionMode={SelectionMode.single}
          checkboxVisibility={CheckboxVisibility.hidden}
          onItemInvoked={onPdbSelected}
          onShouldVirtualize={() => false}
        />
      </div>
    </div>
  )
}

export default PdbTable
