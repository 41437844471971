import React, { useContext } from 'react'
import { ThemeContext } from '../contexts/theme'
import { dimensions } from './'

function generateCSS(colors: { [key: string]: string }) {
  const styles = []
  for (const semanticColor in colors) {
    styles.push(`--${semanticColor}: ${colors[semanticColor]};`)
  }

  return styles.join('\n')
}

export default function ThemeToCSS() {
  const theme = useContext(ThemeContext)
  const palette: any = theme.palette
  const semanticColors: any = theme.semanticColors

  return (
    <div>
      {palette && <style>{`:root {\n${generateCSS(palette)}\n}`}</style>}
      {semanticColors && <style>{`:root {\n${generateCSS(semanticColors)}\n}`}</style>}
      {dimensions && <style>{`:root {\n${generateCSS(dimensions)}\n}`}</style>}
    </div>
  )
}
