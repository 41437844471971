import React, { useCallback, useState, useRef } from 'react'
import './index.css'
import { IGraphPDB } from 'krogan-types'
import PdbTable from './PdbTable'
import { ActionButton, Link } from 'office-ui-fabric-react'
import PdbInfo from './PdbInfo'
import { IGraphVirusPhosSite } from 'krogan-types/dist/Graph'
import VirusPhosphorylationTable from './VirusPhosphorylationTable'

interface IVirusInfoProps {
  name: string
  kDa?: number
  similarity?: number | string
  aaLength?: number
  func?: string
  description?: string
  pdbs?: { [key: string]: IGraphPDB }
  phosphorylation?: {
    sites: {
      [siteId: string]: IGraphVirusPhosSite
    }
    description: string
  }
}

function VirusInfo(props: IVirusInfoProps) {
  const { name, kDa, similarity, aaLength, func, description, pdbs, phosphorylation } = props

  const renderPdb = pdbs && Object.keys(pdbs).length > 0
  const renderPhosTable = phosphorylation && Object.keys(phosphorylation.sites).length > 0

  const [pdbSelected, setPdbSelected] = useState<IGraphPDB>()

  const onPdbSelected = useCallback(
    (pdb: IGraphPDB) => {
      const id = pdb['PDB ID']
      if (id) {
        window.open(`https://www.rcsb.org/structure/${id}`, '_blank')
      }
    },
    [setPdbSelected]
  )

  const onOpenViewer = useCallback(
    (pdb: IGraphPDB) => {
      setPdbSelected(pdb)
    },
    [setPdbSelected]
  )

  const onDismissPdb = useCallback(() => {
    setPdbSelected(undefined)
  }, [setPdbSelected])

  const pdbDivRef = useRef<HTMLDivElement | null>(null)
  const setPdbDivRef = useCallback(
    (node: HTMLDivElement | null) => {
      pdbDivRef.current = node
      if (node && node.parentElement) {
        node.parentElement.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    [pdbDivRef]
  )

  if (pdbSelected) {
    return (
      <div ref={setPdbDivRef}>
        <ActionButton
          iconProps={{
            iconName: 'Back'
          }}
          text={`Return to ${name}`}
          onClick={onDismissPdb}
        />
        <PdbInfo pdb={pdbSelected} />
      </div>
    )
  }

  return (
    <div className="tip-modal-content long-col-name">
      <div className="tip-row">
        <div className="tip-header">Bait Information</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Name:</div>
        <div className="tip-col-value">{name}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">MW (kDa):</div>
        <div className="tip-col-value">{kDa}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">% Similarity (to SARS-CoV):</div>
        <div className="tip-col-value">{similarity}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Length (aa):</div>
        <div className="tip-col-value">{aaLength}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Function:</div>
        <div className="tip-col-value">{func}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Description:</div>
        <div className="tip-col-value">{description}</div>
      </div>

      {phosphorylation?.description && (
        <div className="tip-row">
          <div className="tip-col-name">Literature Review:</div>

          <div className="tip-col-value">{phosphorylation.description}</div>
        </div>
      )}

      {renderPdb && (
        <>
          <div className="tip-row" style={{ paddingTop: '24px' }}>
            <div className="tip-col-full" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Protein Data Banks
            </div>
          </div>
          <div className="tip-row">
            <div className="tip-col-full">
              {pdbs && <PdbTable pdb={pdbs} onPdbSelected={onPdbSelected} onOpenPdbViewer={onOpenViewer} />}
            </div>
          </div>
        </>
      )}

      {renderPhosTable && (
        <>
          <div className="tip-row" style={{ paddingTop: '24px' }}>
            <div className="tip-col-full" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Phosphorylation Heat Map (<Link>Bouhaddou et. al., 2020</Link>)
            </div>
          </div>
          <div className="tip-row">
            <div style={{ width: '100%', maxWidth: '1100px', margin: 'auto' }}>
              {phosphorylation?.sites && <VirusPhosphorylationTable virusName={name} phosphorylationSites={phosphorylation.sites} />}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default VirusInfo
