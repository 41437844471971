export function compareArrays<T>(arr1: Array<T>, arr2: Array<T>, compareFunc?: (item1: T, item2: T) => number): boolean {
  if (arr1.length !== arr2.length) return false
  arr1.sort(compareFunc)
  arr2.sort(compareFunc)
  for (let i = 0; i < arr2.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }
  return true
}

export function shallowCompare(obj1?: any, obj2?: any): boolean {
  if (obj1 === obj2) return true
  if (!obj1 || !obj2) return true
  if (typeof obj1 !== typeof obj2) return false
  if (typeof obj1 !== 'object') return false

  // make sure all keys are the same
  if (!compareArrays(Object.keys(obj1), Object.keys(obj2))) return false

  // make sure all values are the same
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) return false
  }

  return true
}

export function deepCompareArrays<T>(arr1: Array<T>, arr2: Array<T>, compareFunc?: (item1: T, item2: T) => number): boolean {
  if (arr1.length !== arr2.length) return false
  arr1.sort(compareFunc)
  arr2.sort(compareFunc)
  for (let i = 0; i < arr2.length; i++) {
    const result = deepCompare(arr1[i], arr2[i])
    if (!result) return false
  }
  return true
}

export function deepCompare(obj1?: any, obj2?: any): boolean {
  if (obj1 === obj2) return true
  if (!obj1 || !obj2) return true
  if (typeof obj1 !== typeof obj2) return false
  if (typeof obj1 !== 'object') return false

  if (Array.isArray(obj1) || Array.isArray(obj2)) {
    if (!Array.isArray(obj1) || !Array.isArray(obj2)) return false
    return deepCompareArrays(obj1, obj2)
  }

  // make sure all keys are the same
  if (!compareArrays(Object.keys(obj1), Object.keys(obj2))) return false

  for (const key in obj1) {
    const val1 = obj1[key]
    const val2 = obj2[key]

    if (val1 === val2) continue

    if (typeof val1 !== typeof val2) return false

    if (typeof val1 === 'object') {
      const result = deepCompare(val1, val2)
      if (!result) return false
    }

    if (Array.isArray(val1) || Array.isArray(val2)) {
      if (!Array.isArray(val1) || !Array.isArray(val2)) return false
      const result = compareArrays(val1, val2)
      if (!result) return false
    }

    if (val1 !== val2) return false
  }

  return true
}

export interface IColor {
  r: number
  g: number
  b: number
  a?: number
}

export function stringToColor(color: string): IColor {
  const isHex = color.startsWith('#')
  const isRGB = color.startsWith('rgb(') && color.endsWith(')')
  const isRGBA = color.startsWith('rgba(') && color.endsWith(')')

  if (isHex) {
    const str = color.slice(1)
    let r, g, b, a

    // #fff
    if (str.length === 4 || str.length === 3) {
      r = parseInt(str.slice(0, 1), 16) / 15
      g = parseInt(str.slice(1, 2), 16) / 15
      b = parseInt(str.slice(2, 3), 16) / 15
      if (str.length === 4) a = parseInt(str.slice(3, 4), 16) / 15
    }

    // #ffff
    if (str.length === 8 || str.length === 6) {
      r = parseInt(str.slice(0, 2), 16) / 255
      g = parseInt(str.slice(2, 4), 16) / 255
      b = parseInt(str.slice(4, 6), 16) / 255
      if (str.length === 8) a = parseInt(str.slice(6, 8), 16) / 255
    }

    if (typeof r !== 'number' || typeof g !== 'number' || typeof b !== 'number') {
      throw Error('Invalid Hex Number')
    }

    return {
      r,
      g,
      b,
      a
    }
  }

  // rgb(255, 255, 255)
  if (isRGB) {
    const str = color.slice(4, -1)
    const numbers = str.split(',')
    if (numbers.length !== 3) throw Error('Invalid RBG Number')
    let r, g, b
    r = parseInt(numbers[0]) / 255
    g = parseInt(numbers[1]) / 255
    b = parseInt(numbers[2]) / 255
    return { r, g, b }
  }

  // rgba(255, 255, 255, 1.0)
  if (isRGBA) {
    const str = color.slice(5, -1)
    const numbers = str.split(',')
    if (numbers.length !== 4) throw Error('Invalid RBGA Number')
    let r, g, b, a
    r = parseInt(numbers[0]) / 255
    g = parseInt(numbers[1]) / 255
    b = parseInt(numbers[2]) / 255
    a = parseInt(numbers[3])
    return { r, g, b, a }
  }

  throw Error(color + ' is not a supported color for conversion!')
}

export function toHex2(n: number) {
  return n.toString(16).padStart(2, '0')
}

export function clamp(n: number, min: number, max: number) {
  return Math.max(Math.min(n, max), min)
}

export function colorToHex(rgb: IColor) {
  const red = clamp(Math.round(rgb.r * 255), 0, 255)
  const green = clamp(Math.round(rgb.g * 255), 0, 255)
  const blue = clamp(Math.round(rgb.b * 255), 0, 255)
  const alpha = rgb.a && clamp(Math.round(rgb.a * 255), 0, 255)
  return '#' + toHex2(red) + toHex2(green) + toHex2(blue) + (alpha ? toHex2(alpha) : '')
}

export function downloadUrlImage(name: string, url: string) {
  const link = document.createElement('a')
  link.setAttribute('CrossOrigin', 'Anonymous')
  link.href = url
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
