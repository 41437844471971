import React from 'react'
import { setTheme } from '../theme'
import { IPalette, ISemanticColors } from 'office-ui-fabric-react'

export interface ITheme {
  theme: string
  palette?: IPalette
  semanticColors?: ISemanticColors
  toggleTheme?: () => void
}

const defaultSettings: ITheme = {
  theme: localStorage.getItem('theme') || 'light'
}

const ThemeContext = React.createContext(defaultSettings)

class ThemeProvider extends React.Component<{}, ITheme> {
  constructor(props: {}) {
    super(props)

    const ret = setTheme(defaultSettings.theme)
    this.state = {
      ...defaultSettings,
      toggleTheme: this.toggleTheme,
      palette: ret.palette,
      semanticColors: ret.semanticColors
    }
  }

  toggleTheme = () => {
    const newTheme = this.state.theme === 'light' ? 'dark' : 'light'
    const ret = setTheme(newTheme)
    this.setState({
      theme: newTheme,
      palette: ret.palette,
      semanticColors: ret.semanticColors
    })
    localStorage.setItem('theme', newTheme)
  }

  render() {
    return <ThemeContext.Provider value={this.state}>{this.props.children}</ThemeContext.Provider>
  }
}

export { ThemeProvider, ThemeContext }
