import React, { useRef, useCallback, useContext } from 'react'
import './index.css'
import { IGraphPDB } from 'krogan-types'
import { Link } from 'office-ui-fabric-react'
import { ThemeContext } from '../../contexts/theme'

interface IPdbInfoProps {
  pdb: IGraphPDB
}

function PdbInfo(props: IPdbInfoProps) {
  const viewportRef = useRef<HTMLDivElement | null>(null)
  const stageRef = useRef<any | null>(null)
  const theme = useContext(ThemeContext)

  const setViewportRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        stageRef.current = new NGL.Stage(node, { backgroundColor: theme.palette?.white })
        stageRef.current._windowListener = () => {
          if (stageRef.current) stageRef.current.handleResize()
        }

        window.addEventListener('resize', stageRef.current._windowListener)
        stageRef.current.loadFile(`rcsb://${props.pdb['PDB ID']}`, { defaultRepresentation: true })

        node.addEventListener('wheel', evt => {
          evt.preventDefault()
        })
      } else {
        if (stageRef.current) {
          window.removeEventListener('resize', stageRef.current._windowListener)
          stageRef.current = null
        }
      }
      viewportRef.current = node
    },
    [viewportRef, stageRef, props.pdb]
  )

  return (
    <div className="tip-modal-content long-col-name">
      <div className="tip-row">
        <div className="tip-header">PDB Information</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">PDB ID:</div>
        <div className="tip-col-value">
          <Link href="onPdbSelected" target="_blank">
            {props.pdb['PDB ID']}
          </Link>
        </div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Structure Title (PDB)</div>
        <div className="tip-col-value">{props.pdb['Structure Title (PDB)']}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Node Name</div>
        <div className="tip-col-value">{props.pdb['Node Name']}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Protein Name(s)</div>
        <div className="tip-col-value">{props.pdb['Protein Name(s)']}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Exp. Method</div>
        <div className="tip-col-value">{props.pdb['Exp. Method']}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Resolution (Å)</div>
        <div className="tip-col-value">{props.pdb['Resolution (Å)']}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Taxonomy ID</div>
        <div className="tip-col-value">{props.pdb['Taxonomy ID']}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Virus</div>
        <div className="tip-col-value">{props.pdb['Virus']}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Link to DOI</div>
        <div className="tip-col-value">
          <Link href={props.pdb['Link to DOI']} target="_blank">
            {props.pdb['Link to DOI']}
          </Link>
        </div>
      </div>

      <div className={'pdb-viewport'}>
        <div ref={setViewportRef} style={{ width: '100%', height: '100%' }}></div>
      </div>
    </div>
  )
}

export default PdbInfo
