import React from 'react'
import SarsCov2Graph from '../containers/SarsCov2Graph'
import { GraphFiltersProvider } from '../contexts/graphFilters'
import { GraphSettingsProvider } from '../contexts/graphSettings'

interface IProps {}

const Home = (props: IProps) => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <GraphFiltersProvider>
        <GraphSettingsProvider>
          <SarsCov2Graph />
        </GraphSettingsProvider>
      </GraphFiltersProvider>
    </div>
  )
}

export default Home
