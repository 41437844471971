import React, { useState, useEffect, useCallback, useContext } from 'react'
import { Slider, PrimaryButton, Dropdown, IDropdownOption } from 'office-ui-fabric-react'
import { IArrangeNodeOptions, arrangeNodesInGrid } from './GraphActions'
import { GraphSettingsContext } from '../../contexts/graphSettings'

interface IGraphSettingsProps {
  // layoutOptions: IArrangeNodeOptions
  // onApplyLayout: (opt?: IArrangeNodeOptions) => void
}

function useInitialState<T>(v: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [val, setVal] = useState(v)
  useEffect(() => {
    setVal(v)
  }, [v])
  return [val, setVal]
}

function GraphSettings(props: IGraphSettingsProps) {
  const graphSettings = useContext(GraphSettingsContext)
  const layoutOptions = graphSettings.layoutOptions
  const onApplyLayout = useCallback(
    (settings: Partial<IArrangeNodeOptions>) => {
      const newOptions = {
        ...graphSettings.layoutOptions,
        ...settings
      }
      graphSettings.update({ layoutOptions: newOptions })
      if (graphSettings.cy) arrangeNodesInGrid(graphSettings.cy, graphSettings.cy.nodes('.virus-compound').not('.hidden'), newOptions)
    },
    [graphSettings]
  )

  const [aspectRatioPreset, setAspectRatioPreset] = useState('')
  const [hSpacing, setHSpacing] = useInitialState(layoutOptions.horizontalSpacing)
  const [vSpacing, setVSpacing] = useInitialState(layoutOptions.verticalSpacing)
  const [lWidth, setLWidth] = useInitialState(layoutOptions.layoutWidth)

  const onApply = useCallback(() => {
    onApplyLayout({
      horizontalSpacing: hSpacing,
      verticalSpacing: vSpacing,
      layoutWidth: lWidth
    })
  }, [hSpacing, vSpacing, lWidth, onApplyLayout])

  const onReset = useCallback(() => {
    setHSpacing(200)
    setVSpacing(200)
    setLWidth(6000)
    onApplyLayout({
      horizontalSpacing: 200,
      verticalSpacing: 200,
      layoutWidth: 6000
    })
  }, [onApplyLayout])

  const onSetAspectRatioPreset = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
      if (option) {
        const { key } = option

        setAspectRatioPreset(String(key))
        switch (key) {
          case '16_9':
            setHSpacing(240)
            setVSpacing(200)
            setLWidth(9550)
            onApplyLayout({
              horizontalSpacing: 240,
              verticalSpacing: 200,
              layoutWidth: 9550
            })
            break

          case '8.5_11':
            setHSpacing(250)
            setVSpacing(200)
            setLWidth(5870)
            onApplyLayout({
              horizontalSpacing: 250,
              verticalSpacing: 200,
              layoutWidth: 5870
            })
            break

          case '11_8.5':
            setHSpacing(300)
            setVSpacing(200)
            setLWidth(7640)
            onApplyLayout({
              horizontalSpacing: 300,
              verticalSpacing: 200,
              layoutWidth: 7640
            })
            break
        }
      }
    },
    [onApplyLayout]
  )

  const onChangeHSpacing = useCallback((val: number) => {
    setHSpacing(val)
    setAspectRatioPreset('')
  }, [])

  const onChangeVSpacing = useCallback((val: number) => {
    setVSpacing(val)
    setAspectRatioPreset('')
  }, [])

  const onChangeLWidth = useCallback((val: number) => {
    setLWidth(val)
    setAspectRatioPreset('')
  }, [])

  return (
    <div className="settings-content">
      {/* <div className="settings-header">Layout</div> */}

      <div className="settings-group">
        <div className="settings-name">Presets</div>
        <Dropdown
          styles={{
            dropdownOptionText: {
              color: 'var(--black)'
            },
            dropdown: {
              selectors: {
                '&:hover .ms-Dropdown-title': {
                  color: 'var(--black)'
                },
                '&:focus .ms-Dropdown-title': {
                  color: 'var(--black)'
                }
              }
            }
          }}
          options={[
            { key: 'manual', text: 'Manual' },
            { key: '16_9', text: '16 x 9' },
            { key: '8.5_11', text: '8.5 x 11' },
            { key: '11_8.5', text: '11 x 8.5' }
          ]}
          selectedKey={aspectRatioPreset}
          onChange={onSetAspectRatioPreset}
          placeHolder={'Select a preset layout'}
        />
      </div>
      <div className="settings-group">
        <div className="settings-name">Horizontal Spacing</div>
        <Slider showValue={true} min={0} max={800} value={hSpacing} onChange={onChangeHSpacing} step={10} />
      </div>
      <div className="settings-group">
        <div className="settings-name">Vertical Spacing</div>
        <Slider showValue={true} min={0} max={800} value={vSpacing} onChange={onChangeVSpacing} step={10} />
      </div>
      <div className="settings-group">
        <div className="settings-name">Layout Width (at 1x zoom)</div>
        <Slider showValue={true} min={1000} max={16000} value={lWidth} onChange={onChangeLWidth} step={10} />
      </div>

      <div className="settings-group">
        <div className="settings-buttons-group">
          <PrimaryButton onClick={onApply}>Apply</PrimaryButton>
          <PrimaryButton onClick={onReset}>Reset</PrimaryButton>
        </div>
      </div>
    </div>
  )
}

export default GraphSettings
