import Cytoscape from 'cytoscape'
import { generateStateContext } from '../utils/reactUtils'
import { IArrangeNodeOptions } from '../containers/SarsCov2Graph/GraphActions'

export interface IGraphSettings {
  minZoom: number
  maxZoom: number
  zoom: number
  layoutOptions: IArrangeNodeOptions
  showFilterPanel: boolean
  showSettingsPanel: boolean
  showInfo: boolean
  cy?: Cytoscape.Core
  width: string
}

const defaultSettings: IGraphSettings = {
  zoom: 1,
  minZoom: 0.05,
  maxZoom: 25,
  showFilterPanel: false,
  showSettingsPanel: false,
  showInfo: false,
  layoutOptions: {
    horizontalSpacing: 200,
    verticalSpacing: 200,
    layoutWidth: 6000
  },
  cy: undefined,
  width: '100%'
}

const { Context: GraphSettingsContext, Provider: GraphSettingsProvider } = generateStateContext(defaultSettings)
export { GraphSettingsContext, GraphSettingsProvider }
