import React from 'react'
import * as firebase from 'firebase'

// in local dev, use staging config by default for now,
const firebaseConfig = process.env.REACT_APP_FIREBASE_CONFIG
  ? JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string)
  : {
      apiKey: 'AIzaSyARgKE5QajkjLZ6xIXvBMhFvH4tD_8layU',
      authDomain: 'krogan-staging.firebaseapp.com',
      databaseURL: 'https://krogan-staging.firebaseio.com',
      projectId: 'krogan-staging',
      storageBucket: 'krogan-staging.appspot.com',
      messagingSenderId: '185411923659',
      appId: '1:185411923659:web:3a86cdd46e53307cfc754e',
      measurementId: 'G-7KWBNWJYBK'
    }

firebase.initializeApp(firebaseConfig)
if (firebaseConfig.measurementId) firebase.analytics()

// if running in local dev, use firebase emulators
if (window.location.hostname === 'localhost') {
  firebase.functions().useFunctionsEmulator('http://localhost:5001')
  // firestore emulator not working...
  // firebase.firestore().settings({
  //   host: 'http://localhost:8080',
  //   ssl: false
  // })
}

export const FirebaseContext = React.createContext({
  firebase
})
