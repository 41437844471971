import React, { useState, useRef, useCallback, useEffect } from 'react'
import './index.css'
import { Icon, IconButton } from 'office-ui-fabric-react'

interface IShelfProps {
  children: any
  isOpen: boolean
  onDismiss?: () => void
  onDismissEnd?: () => void
  className?: string
  onHeightChange?: (h: number) => void
  initialHeight?: number
}

const minHeight = 24
const animDuration = 250

function Shelf(props: IShelfProps) {
  const { className, isOpen, onDismiss, onDismissEnd, children, onHeightChange, initialHeight } = props

  const heightRef = useRef(initialHeight || window.outerHeight / 2)
  const preferredHeightRef = useRef(heightRef.current)
  const rootRef = useRef<HTMLDivElement | null>(null)
  const dragButtonRef = useRef<HTMLDivElement | null>(null)
  const dragFunctionRefs = useRef<{ [key: string]: (evt: PointerEvent) => void }>({})

  const setRootRef = useCallback(
    (node: HTMLDivElement | null) => {
      rootRef.current = node
    },
    [rootRef]
  )

  const onHeightChangeImpl = useCallback(
    (height: number) => {
      if (height < minHeight) height = minHeight
      heightRef.current = height
      if (rootRef.current) {
        rootRef.current.style.height = `${height}px`
      }

      if (onHeightChange) onHeightChange(height)
    },
    [onHeightChange, heightRef, rootRef]
  )

  // minimalize and maximize!
  const onBorderDoubleClick = useCallback(() => {
    let newHeight
    if (heightRef.current <= minHeight) {
      newHeight = preferredHeightRef.current
    } else {
      preferredHeightRef.current = heightRef.current
      newHeight = minHeight
    }

    if (rootRef.current) {
      rootRef.current.style.transition = `all ${animDuration}ms ease-in-out`
    }
    onHeightChangeImpl(newHeight)
    setTimeout(() => {
      if (rootRef.current) {
        rootRef.current.style.transition = ``
      }
    }, animDuration)
  }, [preferredHeightRef, heightRef, rootRef, initialHeight])

  const setDragButtonRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        dragFunctionRefs.current.pointerdown = () => {
          dragFunctionRefs.current.pointermove = evt => {
            const y = evt.clientY
            if (!rootRef.current) return

            const clientRect = rootRef.current.getBoundingClientRect()
            let height = clientRect.bottom - y
            onHeightChangeImpl(height)
          }

          document.body.style.cursor = 'row-resize'
          document.addEventListener('pointermove', dragFunctionRefs.current.pointermove)
        }

        dragFunctionRefs.current.pointerup = () => {
          document.body.style.cursor = ''
          document.removeEventListener('pointermove', dragFunctionRefs.current.pointermove)
        }

        node.addEventListener('pointerdown', dragFunctionRefs.current.pointerdown)
        document.addEventListener('pointerup', dragFunctionRefs.current.pointerup)
      }

      if (!node && dragButtonRef.current && dragFunctionRefs.current) {
        dragFunctionRefs.current.pointerup(new PointerEvent('pointerup'))
        dragButtonRef.current.removeEventListener('pointerdown', dragFunctionRefs.current.pointerdown)
        document.removeEventListener('pointerup', dragFunctionRefs.current.pointerup)
      }

      dragButtonRef.current = node
    },
    [onHeightChangeImpl, dragFunctionRefs, dragButtonRef, rootRef]
  )

  function openShelfAnim() {
    if (rootRef.current) {
      rootRef.current.style.height = '0px'
      rootRef.current.style.display = ''
    }

    setTimeout(() => {
      if (rootRef.current) {
        rootRef.current.style.transition = `all ${animDuration}ms ease-in-out`
        rootRef.current.style.height = `${heightRef.current}px`
      }
    }, 1)

    setTimeout(() => {
      if (rootRef.current) {
        rootRef.current.style.transition = ''
      }
    }, animDuration + 1)
  }

  function closeShelfAnim() {
    if (rootRef.current) {
      rootRef.current.style.transition = `all ${animDuration}ms ease-in-out`
      rootRef.current.style.height = `0px`
      setTimeout(() => {
        if (onDismissEnd) onDismissEnd()
        if (rootRef.current) {
          rootRef.current.style.display = 'none'
        }
      }, animDuration)
    }
  }

  useEffect(() => {
    if (!isOpen) {
      closeShelfAnim()
    } else {
      openShelfAnim()
    }
  }, [isOpen])

  return (
    <div className={['shelf-outer', className].join(' ')} style={{ height: 0 }} ref={setRootRef}>
      <div className="shelf-border-top" ref={setDragButtonRef} onDoubleClick={onBorderDoubleClick}></div>
      <div className="shelf-top" onClick={onDismiss || closeShelfAnim}>
        <div className="shelf-anchor-button">
          <Icon className="shelf-anchor-icon" iconName="CaretDownSolid8"></Icon>
        </div>
      </div>
      <div className="shelf-content">{children}</div>
    </div>
  )
}

export default Shelf
