import React, { useEffect, useContext } from 'react'

import ProtectedRoute from '../containers/ProtectedRoute'
import { Ingest } from '../containers/Ingest'
import { FirebaseContext } from '../contexts/firebaseContext'

const styles = {
  top: {
    display: 'flex'
  },
  topLink: {}
}

interface IProps {}

export default function IngestRoute(props: IProps) {
  const { firebase } = useContext(FirebaseContext)

  const onClickLogout = () => firebase.auth().signOut()

  return (
    <ProtectedRoute>
      <div>
        <div style={styles.top}>
          <div style={{ flex: 1, textAlign: 'left' }}>
            <a className="App-link" href="/#">
              Back to home
            </a>
          </div>
          <div style={{ flex: 1, textAlign: 'right' }}>
            <a className="App-link" onClick={onClickLogout}>
              Logout
            </a>
          </div>
        </div>
        <Ingest />
      </div>
    </ProtectedRoute>
  )
}
