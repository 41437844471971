import React, { useState, useRef, useEffect } from 'react'
import './index.css'
import Cytoscape from 'cytoscape'
import VirusInfo from './VirusInfo'
import VirusToHumanInfo from './VirusToHumanInfo'
import HumanInfo from './HumanInfo'
import HumanToHumanInfo from './HumanToHumanInfo'
import Shelf from '../../components/Shelf'
import ComplexOrProcessInfo from './ComplexOrProcessInfo'
// import CyElementTip from '../../components/Graph/CyElementTip'

export interface IInfoPaneProps {
  element?: Cytoscape.NodeSingular | Cytoscape.EdgeSingular
  onDismissEnd?: () => void
}

function InfoPane(props: IInfoPaneProps) {
  const { element, onDismissEnd } = props

  const [isOpen, setIsOpen] = useState(false)
  const onDismiss = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (element) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [element])

  const type = element && element.data('type')

  return (
    <Shelf isOpen={isOpen} onDismiss={onDismiss} onDismissEnd={onDismissEnd} className={'info-shelf'}>
      {element && type === 'virus' && (
        <VirusInfo
          key={element.id()}
          name={element.data('label')}
          kDa={element.data('kDa')}
          similarity={element.data('similarity')}
          aaLength={element.data('aaLength')}
          func={element.data('func')}
          description={element.data('description')}
          pdbs={element.data('pdbs')}
          phosphorylation={element.data('phosphorylation')}
        />
      )}
      {element && type === 'human' && (
        <HumanInfo
          key={element.id()}
          preyGene={element.data('preyGene')}
          preys={element.data('preys')}
          uniprotProteinDescription={element.data('uniprotProteinDescription')}
          structuresPdb={element.data('structuresPdb')}
          uniprotFunction={element.data('uniprotFunction')}
          uniprotFunctionInDisease={element.data('uniprotFunctionInDisease')}
          drugs={element.data('drugs')}
          phosphorylationSites={element.data('phosphorylationSites')}
          otherViruses={element.data('otherViruses')}
          complexOrProcess={element.data('complexOrProcess')}
          isComplexProcess={element.data('isComplexProcess')}
        />
      )}
      {element && type === 'virus-to-human' && (
        <VirusToHumanInfo
          source={element.data('sourceLabel')}
          target={element.data('targetLabel')}
          mist={element.data('mist')}
          bfdr={element.data('bfdr')}
          avgSpec={element.data('avgSpec')}
          //otherViruses={element.data('otherViruses')}
        />
      )}
      {element && type === 'human-to-human' && (
        <HumanToHumanInfo
          source={element.data('sourceLabel')}
          target={element.data('targetLabel')}
          complexOrProcess={element.data('complexOrProcess')}
          isComplexProcess={element.data('isComplexProcess')}
          isHumanPPI={element.data('isHumanPPI')}
        />
      )}
      {element && type === 'complex-or-process' && (
        <ComplexOrProcessInfo complexOrProcess={element.data('label')} isComplexProcess={element.data('isComplexProcess')} />
      )}
    </Shelf>
  )
}

export default InfoPane
