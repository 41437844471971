import { initializeIcons } from '@uifabric/icons'
import { loadTheme } from '@uifabric/styling'
import {
  IPartialTheme,
  IPalette,
  IFontStyles,
  IEffects,
  ISemanticColors,
  ITooltipStyleProps,
  ITooltipStyles,
  IButtonStyles,
  IButtonProps,
  IIconStyleProps,
  IIconStyles,
  IPanelStyleProps,
  IPanelStyles,
  IDetailsRowStyleProps,
  IDetailsRowStyles,
  IPivotStyleProps,
  ISearchBoxStyles,
  ICheckboxStyles,
  ISearchBoxProps,
  ICheckboxProps,
  IPivotStyles,
  IPivotProps,
  IModalStyles,
  IModalProps,
  IDropdownStyleProps,
  IDropdownStyles
} from 'office-ui-fabric-react'
import { ICustomizations } from 'office-ui-fabric-react'

initializeIcons()

export const dimensions = {
  navHeaderHeight: '2.25em'
}

export const paletteLight: Partial<IPalette> = {
  /* contrast against background */
  black: '#171a1f',
  neutralDark: '#171a1f', // background color of navbar
  neutralPrimary: '#171a1f', // text color

  neutralTertiary: '#55585e', // slider background
  neutralTertiaryAlt: '#93969d', // icon button when unselected
  neutralQuaternary: '#ffffff', // icon when selected
  neutralQuaternaryAlt: '#9f9f9f', // icon when hovered

  /* background colors */
  white: '#e8ebf2', // graph background
  neutralLight: '#d3d6dd', // info pane background
  neutralLighter: '#c5c8cf', // side tab background

  /* theme colors (buttons/etc) */
  themeDark: '#06626A',
  themePrimary: '#07929A',
  themeSecondary: '#2AC4C2'
}

export const paletteDark: Partial<IPalette> = {
  black: '#ffffff',
  neutralDark: '#171a1f', // background color of navbar
  neutralPrimary: '#ffffff', // text color

  neutralTertiary: '#55585e', // slider background
  neutralTertiaryAlt: '#93969d', // icon button when unselected
  neutralQuaternary: '#ffffff', // icon when selected
  neutralQuaternaryAlt: '#9f9f9f', // icon when hovered

  white: '#0c0f16', // graph background
  neutralLight: '#171a1f', // info pane background
  neutralLighter: '#2c2e34', // side tab background

  themeDark: '#06626A',
  themePrimary: '#07929A',
  themeSecondary: '#2AC4C2'
}

function getSemantics(palette: Partial<IPalette>): Partial<ISemanticColors> {
  return {
    bodyBackground: palette.white,
    bodyStandoutBackground: palette.neutralLight,

    bodyText: palette.black,
    bodySubtext: palette.black,

    buttonText: palette.neutralPrimary,
    buttonTextDisabled: palette.neutralTertiaryAlt,
    buttonTextChecked: palette.neutralQuaternary,
    buttonTextHovered: palette.neutralQuaternaryAlt,
    buttonTextPressed: palette.neutralQuaternary
  }
}

export const TooltipStyles = (props: ITooltipStyleProps): Partial<ITooltipStyles> => ({
  content: {
    color: props.theme.palette.black
  }
})

export const IconButtonStyles = (props: IButtonProps): Partial<IButtonStyles> => ({
  root: {
    backgroundColor: 'transparent'
  },
  rootHovered: {
    // assuming its hex
    backgroundColor: 'transparent'
  },
  rootPressed: {
    backgroundColor: 'transparent'
  },
  icon: {
    color: props.theme?.semanticColors.buttonText
  },
  iconHovered: {
    color: props.theme?.semanticColors.buttonTextHovered
  },
  iconPressed: {
    color: props.theme?.semanticColors.buttonTextPressed
  },
  iconChecked: {
    color: props.theme?.semanticColors.buttonTextChecked
  },
  iconDisabled: {
    color: props.theme?.semanticColors.buttonTextDisabled
  }
})

export const IconStyles = (props: IIconStyleProps): Partial<IIconStyles> => ({
  root: {
    color: props.theme?.palette.themePrimary,
    textAlign: 'center'
  }
})

export const PanelStyles = (props: IPanelStyleProps): Partial<IPanelStyles> => ({
  main: {
    top: dimensions.navHeaderHeight,
    backgroundColor: props.theme?.palette.neutralLighter
  },
  commands: {
    display: 'none'
  }
})

export const DetailsRowStyles = (props: IDetailsRowStyleProps): Partial<IDetailsRowStyles> => ({
  cell: {
    fontWeight: 400
  },
  isRowHeader: {
    color: props.theme.palette.black
  },
  root: {
    selectors: {
      '&:hover .is-row-header': {
        color: props.theme.palette.black
      }
    }
  }
})

export const ActionButtonStyles = (props: IButtonProps): Partial<IButtonStyles> => ({
  icon: {
    color: props.theme?.palette.themePrimary,
    fontSize: '1.2rem'
  },
  iconHovered: {
    color: props.theme?.palette.themeSecondary
  },
  iconPressed: {
    color: props.theme?.palette.themeDark
  },
  label: {
    color: props.theme?.palette.themePrimary,
    fontSize: '1.2rem'
  },
  labelHovered: {
    color: props.theme?.palette.themeSecondary
  }
})

export const SearchBoxStyles = (props: ISearchBoxProps): Partial<ISearchBoxStyles> => ({
  icon: {
    transform: 'scaleX(-1)',
    color: props.theme?.palette.black
  },
  field: {
    color: props.theme?.palette.black,
    selectors: {
      '::placeholder': {
        color: props.theme?.palette.black
      }
    }
  }
})

export const CheckboxStyles = (props: ICheckboxProps): Partial<ICheckboxStyles> => ({
  root: {
    selectors: {
      '.ms-Checkbox-checkmark': {
        color: props.theme?.palette.neutralQuaternary
      },
      ':hover .ms-Checkbox-checkbox': {
        borderColor: props.theme?.palette.themePrimary
      },
      ':hover .ms-Checkbox-text': {
        color: props.theme?.palette.black
      }
    }
  },
  checkbox: {
    borderRadius: '3px',
    borderColor: props.theme?.palette.themePrimary
  },
  label: {
    selectors: {
      ':hover': {
        backgroundColor: 'transparent'
      }
    }
  }
})

export const PivotStyles = (props: IPivotProps): Partial<IPivotStyles> => ({
  link: {
    selectors: {
      ':hover': {
        color: props.theme?.palette.themePrimary
      }
    }
  },
  linkIsSelected: {
    selectors: {
      '::before': {
        display: 'none'
      },
      ':hover': {
        color: props.theme?.palette.themePrimary
      }
    },
    color: props.theme?.palette.themePrimary
  },
  linkContent: {
    border: 0
  }
})

export const ModalStyles = (props: IModalProps): Partial<IModalStyles> => ({
  main: {
    boxShadow: '0px 0px 50px 0px var(--neutralTertiary)'
  }
})

export const DropdownStyles = (props: IDropdownStyleProps): Partial<IDropdownStyles> => ({
  root: {
    selectors: {
      ':focus .ms-Dropdown-title': {
        color: 'var(--black) !important'
      },
      ':hover .ms-Dropdown-title': {
        color: 'var(--black) !important'
      }
    }
  },
  title: {
    color: 'var(--black) !important'
  },
  dropdownItem: {
    color: 'var(--black) !important'
  },
  dropdownItemSelected: {
    color: 'var(--black) !important'
  }
})

export const Customizations: Partial<ICustomizations> = {
  scopedSettings: {
    Tooltip: {
      styles: TooltipStyles
    },
    IconButton: {
      styles: IconButtonStyles
    },
    Icon: {
      styles: IconStyles
    },
    Panel: {
      styles: PanelStyles
    },
    DetailsRow: {
      styles: DetailsRowStyles
    },
    ActionButton: {
      styles: ActionButtonStyles
    },
    SearchBox: {
      styles: SearchBoxStyles
    },
    Checkbox: {
      styles: CheckboxStyles
    },
    Pivot: {
      styles: PivotStyles
    },
    Modal: {
      styles: ModalStyles
    },
    Dropdown: {
      styles: DropdownStyles
    }
  }
}

export const fonts: Partial<IFontStyles> = {
  xSmall: {
    fontFamily: '"Lato", sans-serif',
    fontSize: '.75em',
    fontWeight: 300
  },
  small: {
    fontFamily: '"Lato", sans-serif',
    fontSize: '.8rem',
    fontWeight: 300
  },
  medium: {
    fontFamily: '"Lato", sans-serif',
    fontSize: '1rem',
    fontWeight: 300
  },
  large: {
    fontFamily: '"Lato", sans-serif',
    fontSize: '1.2rem',
    fontWeight: 700
  },
  xLarge: {
    fontFamily: '"Lato", sans-serif',
    fontSize: '1.4rem',
    fontWeight: 300
  },
  xxLarge: {
    fontFamily: '"Lato", sans-serif',
    fontSize: '1.4rem',
    fontWeight: 700
  }
}

export const effects: Partial<IEffects> = {
  roundedCorner2: '0'
}

export const themeLight: IPartialTheme = {
  palette: paletteLight,
  semanticColors: getSemantics(paletteLight),
  fonts,
  effects
}

export const themeDark: IPartialTheme = {
  palette: paletteDark,
  semanticColors: getSemantics(paletteDark),
  fonts,
  effects
}

export function setTheme(theme: string) {
  if (theme === 'light') return loadTheme(themeLight)
  else return loadTheme(themeDark)
}
