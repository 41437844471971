import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Icon, IconButton, mergeStyleSets } from 'office-ui-fabric-react'

const styles = mergeStyleSets({
  uploadContainer: {
    width: '100%',
    height: 58,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'var(--bodyText)',
    background: ' var(--inputBackground)',
    borderWidth: 'thin',
    borderStyle: 'dashed',
    borderColor: 'transparent',
    outline: 'none',
    borderRadius: 10,
    cursor: 'pointer'
  },
  fileContainer: {
    width: '100%',
    height: 36,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
    background: 'var(--inputBackground)',
    borderRadius: 10
  },
  fileName: {
    fontSize: 16,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    color: 'var(--bodyText)'
  },
  ellipsis: {
    width: 'min-content',
    marginRight: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  boldText: {
    fontWeight: 700,
    color: 'var(--themePrimary)'
  }
})

const fileSize = size => {
  if (size <= 1024) return `${size} bytes`
  if (size < 1048576) return `${(size / 1024).toFixed(1)} KB`
  return `${(size / 1048576).toFixed(1)} MB`
}

export default function FileUpload(props) {
  const [documents, setDocument] = React.useState([])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ getFilesFromEvent })

  const deleteFile = (e, index) => {
    e.stopPropagation()
    const removed = documents[index]
    setDocument(documents.filter((val, i) => i !== index))
    props.onDeleteFile(removed)
  }

  function getFilesFromEvent(e) {
    if (e.type !== 'drop' && e.type !== 'change') return []

    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files

    const formData = new FormData()
    const uploading = []

    for (const file of files) {
      formData.append('files', file)
      uploading.push({
        name: file.name,
        bytes: file.size,
        size: fileSize(file.size),
        type: file.type
      })
    }

    setDocument(uploading)
    props.onUpload(formData)
    return formData
  }

  const renderUploadedFile = (document, key) => (
    <div className={styles.fileContainer} key={key}>
      <div className={styles.fileName}>
        <Icon iconName="Page" styles={{ root: { margin: '0 10px', color: 'var(--themeLighterAlt)' } }} />
        <span className={styles.ellipsis}>{document.name}</span>
        <span>({document.size})</span>
      </div>

      <IconButton
        iconProps={{
          iconName: 'Cancel',
          styles: { root: { fontWeight: 700, color: 'var(--themePrimary)' } }
        }}
        onClick={e => deleteFile(e, key)}
      />
    </div>
  )

  return (
    <>
      {documents.length ? (
        documents.map((val, i) => renderUploadedFile(val, i))
      ) : (
        <div {...getRootProps()} style={{ outline: 'none' }}>
          <div className={styles.uploadContainer} style={{ borderColor: isDragActive ? 'var(--themePrimary)' : 'transparent' }}>
            <IconButton
              iconProps={{
                iconName: 'CloudUpload',
                styles: { root: { fontSize: 35, paddingRight: 25, color: 'var(--themePrimary)' } }
              }}
            />
            <span>
              Drag and drop here or <span className={styles.boldText}> Select File</span>
            </span>
            <input id="document" {...getInputProps()} />
          </div>
        </div>
      )}
    </>
  )
}
