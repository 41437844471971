import React, { useState, useEffect } from 'react'
import { Link, DetailsList, IColumn, SelectionMode, CheckboxVisibility, DetailsHeader, DetailsListLayoutMode } from 'office-ui-fabric-react'
import { IGraphHumanPhos, IGraphHumanPhosTimeline } from 'krogan-types/dist/Graph'

interface IPhosphorylationSitesTableProps {
  phosphorylationSites: {
    [name: string]: IGraphHumanPhos
  }
}

function clamp(num: number, min: number = 0, max: number = 1) {
  if (num < min) return min
  if (num > max) return max
  return num
}

function calcColorFC(value: string | number | undefined | null, mid: number = 0, min: number = -3, max: number = 3) {
  if (value === undefined || value === null) return undefined
  const str = String(value).trim().toLowerCase()
  if (str === 'inf') return 'red'
  if (str === '-inf') return 'blue'

  const num = Number(value)
  if (isNaN(num)) return undefined

  if (num >= mid) {
    const factor = clamp((num - mid) / (max - mid))
    return `rgba(255, 0, 0, ${factor})`
  } else {
    const factor = clamp((num - mid) / (min - mid))
    return `rgba(0, 0, 255, ${factor})`
  }
}

function calcColorAdjP(value: string | number | undefined | null, min: number = 0, max: number = 5) {
  if (value === undefined || value === null) return undefined
  const str = String(value).trim().toLowerCase()

  if (str === 'inf') return 'rgba(102, 51, 153, 0)'
  if (str === '-inf') return undefined

  const num = Number(value)
  if (isNaN(num)) return undefined

  const logNum = -Math.log10(num)
  const factor = clamp((logNum - min) / (max - min))
  return `rgba(102, 51, 153, ${factor})`
}

function getphosphorylationSitesColumns(): IColumn[] {
  const ret: IColumn[] = [
    {
      key: 'site',
      fieldName: 'site',
      name: 'Site',
      minWidth: 100,
      maxWidth: 150,
      data: 'string',
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IGraphHumanPhos) => {
        return (
          <div>
            {item.Gene_Name}_{item.site}
          </div>
        )
      }
    }
  ]

  type fieldsType = keyof IGraphHumanPhosTimeline
  const fields: fieldsType[] = ['00Hr', '02Hr', '04Hr', '08Hr', '12Hr', '24Hr']

  fields.forEach(time => {
    ret.push({
      key: time,
      name: time,
      minWidth: 60,
      maxWidth: 60,
      data: 'number',
      isResizable: true,
      isPadded: false,
      className: 'site-block-column',
      onRender: (item: IGraphHumanPhos) => {
        const adjVal = item['adj.pvalue.Inf']?.[time]

        return (
          <div className="site-block">
            <div className="site-cell" style={{ backgroundColor: calcColorFC(item['log2FC.Inf']?.[time]) }}>
              {item['log2FC.Inf']?.[time]}
            </div>
            {/* <div className="site-cell" style={{ backgroundColor: calcColor(item['log2FC.Ctrl']) }}>
              {item['log2FC.Ctrl']}
            </div> */}
            <div className="site-cell" style={{ backgroundColor: calcColorAdjP(adjVal) }}>
              {adjVal}
            </div>
            {/* <div className="site-cell" style={{ backgroundColor: calcColor(item['adj.pvalue.Ctrl']) }}>
              {item['adj.pvalue.Ctrl']}
            </div> */}
          </div>
        )
      }
    })
  })

  ret.push(
    {
      key: 'legend',
      name: '',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isPadded: false,
      className: 'site-label-column',
      onRender: (item: IGraphHumanPhos) => {
        return (
          <div>
            <div className="site-cell-label">Log2FC</div>
            {/* <div className="site-cell-label">FC Control</div> */}
            <div className="site-cell-label">Adjusted p-value</div>
            {/* <div className="site-cell-label">Adj Value Control</div> */}
          </div>
        )
      }
    },
    {
      key: 'Biological Category',
      fieldName: 'Biological Category',
      name: 'Biological Category',
      minWidth: 100,
      maxWidth: 150,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Subcategory',
      fieldName: 'Subcategory',
      name: 'Subcategory',
      minWidth: 100,
      maxWidth: 100,
      data: 'string',
      isResizable: true,
      isPadded: true
    },
    {
      key: 'Functional Score (Ochoa et al., 2019)',
      fieldName: 'Functional Score (Ochoa et al., 2019)',
      name: 'Functional Score (Ochoa et al., 2019)',
      minWidth: 100,
      maxWidth: 250,
      data: 'string',
      isResizable: true,
      isPadded: true
    }
  )

  return ret
}

function PhosphorylationSitesTable(props: IPhosphorylationSitesTableProps) {
  const { phosphorylationSites } = props

  const phosphorylationSiteItems = Object.keys(phosphorylationSites).map(siteId => {
    return {
      key: siteId,
      name: siteId,
      ...phosphorylationSites[siteId]
    }
  })

  const [columns, setColumns] = useState(getphosphorylationSitesColumns())

  return (
    <div className="info-table-outer">
      <div className="info-table">
        <DetailsList
          key={'details_list'}
          items={phosphorylationSiteItems}
          columns={columns}
          onRenderDetailsHeader={props => {
            return (
              <DetailsHeader
                styles={{
                  root: { padding: 0 }
                }}
                {...props}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderColumnHeaderTooltip={headerProps => {
                  if (headerProps?.column?.name === 'Functional Score (Ochoa et al., 2019)') {
                    return (
                      <h3>
                        Functional Score (
                        <Link target="_blank" href="https://www.nature.com/articles/s41587-019-0344-3">
                          Ochoa et al., 2019
                        </Link>
                        )
                      </h3>
                    )
                  }

                  let isCentered = false
                  if (headerProps?.column?.name.endsWith('Hr')) isCentered = true

                  return <h3 style={{ textAlign: isCentered ? 'center' : undefined }}>{headerProps?.column?.name}</h3>
                }}
              />
            )
          }}
          selectionMode={SelectionMode.none}
          checkboxVisibility={CheckboxVisibility.hidden}
          onShouldVirtualize={() => false}
        />
      </div>
    </div>
  )
}

export default PhosphorylationSitesTable
