import React from 'react'
import { Spinner } from 'office-ui-fabric-react'

import AuthProvider, { AuthIsLoading, AuthIsNotSignedIn, AuthIsSignedIn } from '../contexts/authContext'
import LoginForm from '../containers/LoginForm'

interface IProps {
  children: React.ReactChild
}
export default function ProtectedRoute(props: IProps) {
  return (
    <>
      <AuthIsLoading>
        <Spinner />
      </AuthIsLoading>
      <AuthIsNotSignedIn>
        <LoginForm />
      </AuthIsNotSignedIn>
      <AuthIsSignedIn>{props.children}</AuthIsSignedIn>
    </>
  )
}
