import React, { ReactChild } from 'react'
import AuthProvider from './authContext'
import { GraphProvider } from './graphContext'
import { ThemeProvider } from './theme'

export function RootProviders(props: { children: ReactChild[] }) {
  return (
    <AuthProvider>
      <GraphProvider>
        <ThemeProvider>{props.children}</ThemeProvider>
      </GraphProvider>
    </AuthProvider>
  )
}
