import React from 'react'
import './index.css'

interface IComplexOrProcessInfoProps {
  complexOrProcess?: string
  isComplexProcess?: string
}

function ComplexOrProcessInfo(props: IComplexOrProcessInfoProps) {
  const { complexOrProcess, isComplexProcess } = props

  return (
    <div className="tip-modal-content long-col-name">
      <div className="tip-row">
        <div className="tip-header">{isComplexProcess} Information</div>
      </div>

      {isComplexProcess !== 'NA' && (
        <div className="tip-row">
          <div className="tip-col-name">{isComplexProcess + ': '}</div>
          <div className="tip-col-value">{complexOrProcess}</div>
        </div>
      )}
    </div>
  )
}

export default ComplexOrProcessInfo
