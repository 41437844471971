"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var TABLE_NAMES;
(function (TABLE_NAMES) {
    TABLE_NAMES["V"] = "V";
    TABLE_NAMES["H"] = "H";
    TABLE_NAMES["N"] = "N";
    TABLE_NAMES["D"] = "D";
    TABLE_NAMES["PDB"] = "PDB";
    TABLE_NAMES["PHOS"] = "PHOS";
    TABLE_NAMES["SI"] = "SI";
    TABLE_NAMES["VPHOS"] = "VPHOS";
})(TABLE_NAMES = exports.TABLE_NAMES || (exports.TABLE_NAMES = {}));
exports.TABLES = (_a = {},
    _a[TABLE_NAMES.V] = {
        typeName: 'IGraphVirusTable',
        publicName: 'Bait_Lookup_Table.xlsx'
    },
    _a[TABLE_NAMES.H] = {
        typeName: 'IGraphHumanTable',
        publicName: 'Prey_Lookup_Table.xlsx',
        headerRow: 2
    },
    _a[TABLE_NAMES.N] = {
        typeName: 'IGraphNetworkTable',
        publicName: 'Network_Table.xlsx'
    },
    _a[TABLE_NAMES.D] = {
        typeName: 'IGraphDrugTable',
        publicName: 'Drugs_Table.xlsx'
    },
    _a[TABLE_NAMES.PDB] = {
        typeName: 'IGraphPDBTable',
        publicName: 'Bait_PDB_Lookup_Table.xlsx'
    },
    _a[TABLE_NAMES.PHOS] = {
        typeName: 'IGraphHumanPhosTable',
        publicName: 'Phosphorylation_Sites_Table.xlsx'
    },
    _a[TABLE_NAMES.SI] = {
        typeName: 'IGraphSITable',
        publicName: 'SI_Table_RMK.xlsx',
        headerRow: 2
    },
    _a[TABLE_NAMES.VPHOS] = {
        typeName: 'IGraphVirusPhosTable',
        publicName: 'Virus_Phosphorylation_Heatmap_table.xlsx'
    },
    _a);
var EnumIsComplexProcess;
(function (EnumIsComplexProcess) {
    EnumIsComplexProcess["NA"] = "NA";
    EnumIsComplexProcess["Process"] = "Process";
    EnumIsComplexProcess["Complex"] = "Complex";
})(EnumIsComplexProcess || (EnumIsComplexProcess = {}));
