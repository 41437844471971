import React from 'react'

export type Updatable<T> = T & { update: (props: Partial<T>) => void }

export function generateStateContext<T>(
  defaultState: T,
  onComponentDidMount?: (props: Updatable<T>) => void,
  onComponentDidUpdate?: (props: Updatable<T>) => void
) {
  const Context = React.createContext<Updatable<T>>({ ...defaultState, update: () => {} })

  class Provider extends React.Component<{}, Updatable<T>> {
    constructor(props: {}) {
      super(props)
      this.state = {
        ...defaultState,
        update: this.update
      }
    }

    update = (props: Partial<T>) => {
      const newState: any = {
        ...props
      }
      this.setState(newState)
    }

    componentDidMount() {
      if (onComponentDidMount) onComponentDidMount(this.state)
    }

    componentDidUpdate() {
      if (onComponentDidUpdate) onComponentDidUpdate(this.state)
    }

    render() {
      return <Context.Provider value={this.state}>{this.props.children}</Context.Provider>
    }
  }

  return { Context, Provider }
}
