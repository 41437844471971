import React, { useState, useCallback } from 'react'
import './index.css'
import { ICheckListItem, ICheckListProps, CheckList, checkboxStyles } from './CheckList'
import { SearchBox, Checkbox, IconButton } from 'office-ui-fabric-react'

export interface IExtraFilterTerm {
  key: string // key pointing to the key in allTerms
  term: string // extra term used by search
  relation?: string
}

export interface IFilterGroup {
  key: string
  children?: IFilterGroup[]
}

export interface INamesFilterPanelProps {
  allTerms: IFilterGroup[]
  extraTerms?: IExtraFilterTerm[]

  initialActiveTerms?: string[]
  activeTerms?: string[]
  onActiveTermsChange?: (activeTerms: string[]) => void

  searchText?: string
  initialSearchText?: string
  onSearchTextChange?: (text: string) => void

  deselectOnSearchChange?: boolean
}

interface IAltGroup {
  [key: string]: {
    key: string
    subLabels?: {
      [relation: string]: string[]
    }
    children?: IAltGroup
  }
}

interface IArrangedExtraFilterTerms {
  [key: string]: {
    [extraTerm: string]: {
      relation: string
    }
  }
}

function filterAltGroup(altGroup: IAltGroup, searchTermLower: string, extraTerms?: IArrangedExtraFilterTerms) {
  const ret: IAltGroup = {}
  for (const term in altGroup) {
    const group = altGroup[term]
    const key = group.key

    if (!group.children) {
      const containsSearch = key.toLowerCase().indexOf(searchTermLower) !== -1
      const subLabels: { [relation: string]: string[] } = {}
      if (extraTerms) {
        if (extraTerms[key]) {
          const keyExtraTerms = Object.keys(extraTerms[key]).filter(
            keyExtraTerm => keyExtraTerm.toLowerCase().indexOf(searchTermLower) !== -1
          )
          for (const keyExtraTerm of keyExtraTerms) {
            const relation = extraTerms[key][keyExtraTerm].relation
            if (!subLabels[relation]) subLabels[relation] = []
            subLabels[relation].push(keyExtraTerm)
          }
        }
      }

      if (containsSearch || Object.keys(subLabels).length > 0) {
        ret[key] = {
          key,
          subLabels: Object.keys(subLabels).length > 0 ? subLabels : undefined
        }
      }
    } else {
      const childrenRet = filterAltGroup(group.children, searchTermLower, extraTerms)
      if (Object.keys(childrenRet).length > 0) {
        ret[key] = {
          key,
          children: childrenRet
        }
      }
    }
  }

  return ret
}

function constructAltGroup(allGroups: IFilterGroup[]): IAltGroup {
  const ret: IAltGroup = {}
  for (const group of allGroups) {
    ret[group.key] = {
      key: group.key,
      children: group.children && constructAltGroup(group.children)
    }
  }
  return ret
}

function getFilteredTerms(allGroups: IFilterGroup[], extraTerms?: IExtraFilterTerm[], searchText?: string): IAltGroup {
  let filteredTerms = constructAltGroup(allGroups)
  if (searchText) {
    const searchTermLower = searchText.toLowerCase()

    const arrangeExtraTerms: IArrangedExtraFilterTerms = {}
    if (extraTerms) {
      for (const extra of extraTerms) {
        if (!arrangeExtraTerms[extra.key]) arrangeExtraTerms[extra.key] = {}
        arrangeExtraTerms[extra.key][extra.term] = { relation: extra.relation || '_' }
      }
    }

    filteredTerms = filterAltGroup(filteredTerms, searchTermLower, arrangeExtraTerms)
  }

  return filteredTerms
}

function getRootTerms(filteredGroups: IAltGroup): string[] {
  const allStrings: string[] = []
  for (const key in filteredGroups) {
    const children = filteredGroups[key].children
    if (children) {
      allStrings.push(...getRootTerms(children))
    } else {
      allStrings.push(filteredGroups[key].key)
    }
  }
  return allStrings
}

function getCheckListItems(filteredTerms: IAltGroup, checkedKeys: Set<string>): ICheckListItem[] {
  const ret: ICheckListItem[] = []
  for (const key in filteredTerms) {
    const termDef = filteredTerms[key]

    const retItem: ICheckListItem = {
      key: termDef.key,
      label: termDef.key,
      checked: checkedKeys.has(termDef.key),
      children: termDef.children && getCheckListItems(termDef.children, checkedKeys),
      subLabels:
        termDef.subLabels &&
        Object.keys(termDef.subLabels).map(relation => `${relation}: ${termDef.subLabels && termDef.subLabels[relation].join(', ')}`)
    }

    ret.push(retItem)
  }

  return ret
}

function NamesFilterPanel(props: INamesFilterPanelProps) {
  const {
    allTerms,
    extraTerms,

    activeTerms,
    initialActiveTerms,
    onActiveTermsChange,

    initialSearchText,
    searchText,
    onSearchTextChange,

    deselectOnSearchChange = true
  } = props

  const [_activeTerms, _setActiveTerms] = useState(initialActiveTerms || [])
  const activeTermsInUse = activeTerms || _activeTerms

  const [_searchText, _setSearchText] = useState(initialSearchText || '')
  const searchTextInUse = searchText || _searchText

  const activeTermsSet = new Set(activeTermsInUse)
  const _onSearchChange = (event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => {
    _setSearchText(newValue || '')
    if (onSearchTextChange) onSearchTextChange(newValue || '')

    if (deselectOnSearchChange) {
      const filteredTerms = getFilteredTerms(allTerms, extraTerms, newValue)
      let filteredTermsArr = getRootTerms(filteredTerms)
      filteredTermsArr = filteredTermsArr.filter(term => activeTermsSet.has(term))

      _setActiveTerms(filteredTermsArr)
      if (onActiveTermsChange) onActiveTermsChange(filteredTermsArr)
    }
  }

  const filteredTerms = getFilteredTerms(allTerms, extraTerms, searchTextInUse)
  const filteredTermsArr = getRootTerms(filteredTerms)

  let isAllChecked = true
  filteredTermsArr.forEach(term => {
    if (!activeTermsSet.has(term)) isAllChecked = false
  })

  const onSelectAllChecked = useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
      if (checked) {
        _setActiveTerms(filteredTermsArr)
        if (onActiveTermsChange) onActiveTermsChange(filteredTermsArr)
      } else {
        _setActiveTerms([])
        if (onActiveTermsChange) onActiveTermsChange([])
      }
    },
    [onActiveTermsChange, JSON.stringify(filteredTerms)]
  )

  const checkListItems: ICheckListItem[] = getCheckListItems(filteredTerms, activeTermsSet)
  const onItemsChecked = (keys: string[], checked: boolean) => {
    if (checked) {
      for (const key of keys) {
        activeTermsSet.add(key)
      }
    } else {
      for (const key of keys) {
        activeTermsSet.delete(key)
      }
    }
    const newActiveTerms = Array.from(activeTermsSet)
    _setActiveTerms(newActiveTerms)
    if (onActiveTermsChange) onActiveTermsChange(newActiveTerms)
  }

  return (
    <div className="filter-panel">
      <div className="filter-header">
        <SearchBox
          value={searchTextInUse}
          placeholder={'Enter search'}
          onChange={_onSearchChange}
          underlined
          styles={{
            root: {
              backgroundColor: 'transparent'
            }
          }}
        />
        <Checkbox
          label={'Select All'}
          className={'select-all'}
          checked={isAllChecked}
          indeterminate={!isAllChecked && activeTermsSet.size}
          onChange={onSelectAllChecked}
          styles={checkboxStyles}
        />
      </div>
      <div className="filter-terms">
        <CheckList items={checkListItems} onItemsChecked={onItemsChecked} />
      </div>
    </div>
  )
}

export default NamesFilterPanel
