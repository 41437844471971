import React from 'react'
import Cytoscape from 'cytoscape'
import { IExtraFilterTerm } from '../components/NamesFilterPanel'
import { applyFilter, arrangeNodesInGrid } from '../containers/SarsCov2Graph/GraphActions'

export interface INodeCollectionGroups {
  [key: string]: Cytoscape.Collection
}

export interface IGraphFilterProps {
  name: string
  key: string
  extraTerms?: IExtraFilterTerm[]
  allTerms: string[]
  activeTerms: string[]
  groupBy?: (term: string) => string
  searchText: string
  elementGroups?: INodeCollectionGroups
}

export interface IUpdateAllDataParam {
  [key: string]: Partial<IGraphFilterProps>
}

export interface IGraphFilters {
  cy?: Cytoscape.Core
  filterMode: string
  useFilter?: (key: string, activeTerms?: string[]) => void
  update: (props: Partial<IGraphFilters>) => void
  updateFilter?: (key: string, filter: Partial<IGraphFilterProps>) => void
  updateAllFilters?: (data: IUpdateAllDataParam) => void
  data: {
    [key: string]: IGraphFilterProps
  }
}

const defaultFilters: IGraphFilters = {
  filterMode: 'baits',
  update: () => {},
  data: {
    baits: {
      name: 'Baits',
      key: 'baits',
      allTerms: [],
      activeTerms: [],
      searchText: ''
    },
    otherPathogens: {
      name: 'Other Pathogens',
      key: 'otherPathogens',
      allTerms: [],
      activeTerms: [],
      searchText: ''
    },
    drugs: {
      name: 'Drugs',
      key: 'drugs',
      allTerms: [],
      activeTerms: [],
      searchText: ''
    },
    pdbs: {
      name: 'PDBs',
      key: 'pdbs',
      allTerms: [],
      activeTerms: [],
      searchText: ''
    },
    phos: {
      name: 'Phosphorylation Sites',
      key: 'phos',
      allTerms: [],
      activeTerms: [],
      searchText: ''
    }
  }
}

const GraphFiltersContext = React.createContext(defaultFilters)

class GraphFiltersProvider extends React.Component<{}, IGraphFilters> {
  tempData: {
    [key: string]: IGraphFilterProps
  }

  constructor(props: {}) {
    super(props)
    this.state = {
      ...defaultFilters,
      update: this.update,
      useFilter: this.useFilter,
      updateFilter: this.updateFilter,
      updateAllFilters: this.updateAllFilters
    }

    this.tempData = this.state.data
  }

  update = (props: Partial<IGraphFilters>) => {
    const newState: any = props
    if (newState.data) this.tempData = newState.data
    this.setState(newState)
  }

  // NOTE: will not update the activeTerms, update need to be called separately
  useFilter = (key: string, activeTerms?: string[]) => {
    if (!this.state.data[key]) return
    const cy = this.state.cy
    if (!cy) return

    const filter = this.state.data[key]
    if (!filter.elementGroups) {
      console.warn('Must initialize elementGroups before using filter!')
      return
    }

    if (activeTerms) {
      applyFilter(cy, filter.elementGroups, activeTerms, true)
    } else {
      applyFilter(cy, filter.elementGroups, filter.activeTerms, true)
    }
  }

  updateFilter = (key: string, filter: Partial<IGraphFilterProps>) => {
    const copy = Object.assign({}, this.tempData)
    if (copy[key]) {
      copy[key] = {
        ...copy[key],
        ...filter
      }
      this.setState({ data: copy })
      this.tempData = copy
    }
  }

  updateAllFilters = (data: IUpdateAllDataParam) => {
    const copy = Object.assign({}, this.tempData)
    for (const key in data) {
      if (copy[key]) {
        copy[key] = {
          ...copy[key],
          ...data[key]
        }
      }
    }
    this.setState({ data: copy })
    this.tempData = copy
  }

  render() {
    return <GraphFiltersContext.Provider value={this.state}>{this.props.children}</GraphFiltersContext.Provider>
  }
}

export { GraphFiltersContext, GraphFiltersProvider }
