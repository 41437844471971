import React, { useContext, useState } from 'react'
import { IconButton, IconType, IButtonStyles, Spinner, Modal, TextField, PrimaryButton, IButton } from 'office-ui-fabric-react'
import { v4 as uuid } from 'uuid'

import { FirebaseContext } from '../../contexts/firebaseContext'

const generateShareLink = (graphViewId: string) => `${window.location.protocol}//${window.location.host}/#/?gv=${graphViewId}`

interface IProps {
  // styles?: IButtonStyles
  getStyles: { (isOpen: boolean): IButtonStyles }
  serializeGraphView: { (): any }
}

export default function ShareLinkButton(props: IProps) {
  const { serializeGraphView, getStyles } = props

  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [graphViewId, setGraphViewId] = useState('')

  const { firebase } = useContext(FirebaseContext)

  const createShareLink = () => {
    setGraphViewId('')
    setIsLoading(true)

    const newGraphViewId = uuid()
    const graphSettings: any = JSON.parse(JSON.stringify(serializeGraphView()))
    firebase
      .firestore()
      .collection('graphViews')
      .doc(newGraphViewId)
      .set({
        ...graphSettings,
        id: newGraphViewId,
        url: generateShareLink(graphViewId),
        createdAt: new Date().toISOString(),
        lastAccessedAt: new Date().toISOString()
      })
      .then(() => {
        setIsLoading(false)
        setGraphViewId(newGraphViewId)
      })
      .catch(e => {
        console.error(e)
        setIsLoading(false)
      })
  }

  return (
    <div>
      <IconButton
        iconProps={{
          iconName: 'Share',
          iconType: IconType.default
        }}
        styles={getStyles(isOpen)}
        onClick={() => {
          createShareLink()
          setIsOpen(true)
        }}
      />
      <Modal
        isOpen={isOpen}
        styles={{ main: { padding: '1em', backgroundColor: 'var(--neutralLight)' } }}
        onDismiss={() => {
          setIsOpen(false)
        }}>
        <h3>Share</h3>
        <TextField
          value={graphViewId && generateShareLink(graphViewId)}
          disabled={true}
          styles={{
            root: {
              selectors: {
                '.ms-TextField-field': {
                  color: 'var(--black)'
                }
              }
            }
          }}
        />
        <PrimaryButton
          onClick={
            graphViewId
              ? () => {
                  setIsOpen(false)
                  navigator.clipboard.writeText(generateShareLink(graphViewId))
                }
              : createShareLink
          }
          styles={{
            root: {
              marginTop: '1em',
              width: '100%'
            }
          }}>
          {isLoading ? <Spinner /> : graphViewId ? 'Copy and Close' : 'Generate Link'}
        </PrimaryButton>
      </Modal>
    </div>
  )
}

// const [graphViews, setGraphViews] = useState([])

// const [selectedgraphViewId, setSelectedgraphViewId] = useState('')
// const [isDeleting, setIsDeleting] = useState(false)
// const [isSaving, setIsSaving] = useState(false)

// const selection = useRef(
//   new Selection({
//     onSelectionChanged: () => {
//       const currentSelection = selection.current.getSelection()
//       console.log(currentSelection)
//       if (!currentSelection[0]) {
//         setSelectedgraphViewId('')
//         return
//       }
//       setSelectedgraphViewId(currentSelection[0].key as string)
//     }
//   })
// )
// const graphViewsCollection = firebase.firestore().collection('graphViews')
// const getgraphViews = async () => {
//   const docs = await graphViewsCollection.get()
//   const graphViews: any = []
//   docs.forEach(doc => {
//     const data = doc.data()
//     graphViews.push({ ...data, key: data.id })
//   })
//   setGraphViews(graphViews)
// }

// useEffect(() => {
//   getgraphViews()
// }, [])

// const upsertShareLink = (mode = 'new') => () => {
//   if (mode === 'new') setIsLoading(true)
//   if (mode === 'save') setIsSaving(true)

//   const graphViewId = mode === 'new' ? uuid() : selectedgraphViewId
//   const graphSettings: any = JSON.parse(JSON.stringify(serializeGraphView()))
//   firebase
//     .firestore()
//     .collection('graphViews')
//     .doc(graphViewId)
//     .set({
//       ...graphSettings,
//       id: graphViewId,
//       url: `${window.location.href}?sl=${graphViewId}`,
//       updatedAt: new Date().toISOString()
//     })
//     .then(() => {
//       getgraphViews().then(() => {
//         if (mode === 'new') setIsLoading(false)
//         if (mode === 'save') setIsSaving(false)
//       })
//     })
//     .catch(e => {
//       console.error(e)
//       getgraphViews().then(() => {
//         if (mode === 'new') setIsLoading(false)
//         if (mode === 'save') setIsSaving(false)
//       })
//     })
// }

// const onClickDelete = () => {
//   setIsDeleting(true)
//   firebase
//     .firestore()
//     .collection('graphViews')
//     .doc(selectedgraphViewId)
//     .delete()
//     .then(() => {
//       getgraphViews().then(() => setIsDeleting(false))
//     })
//     .catch(e => {
//       console.error(e)
//       getgraphViews().then(() => setIsDeleting(false))
//     })
// }
{
  /* <Panel isOpen={isOpen} onDismiss={() => setIsOpen(false)} headerText="Share" type={PanelType.medium} isBlocking={false}>
        <CommandBar
          styles={{
            root: {
              paddingLeft: 0
            }
          }}
          items={[
            { key: 'new', iconProps: { iconName: 'Add' }, text: 'New', onClick: upsertShareLink('new') },
            {
              key: 'save-as',
              iconProps: { iconName: 'SaveAs' },
              text: 'Save To',
              disabled: isSaving || !selectedgraphViewId,
              onClick: upsertShareLink('save')
            },
            {
              key: 'delete',
              iconProps: { iconName: 'Trash' },
              text: 'Delete',
              disabled: isDeleting || !selectedgraphViewId,
              onClick: onClickDelete
            }
          ]}
          farItems={[
            {
              key: 'loading',
              commandBarButtonAs: () => (isLoading || isSaving || isDeleting ? <Spinner /> : null)
            }
          ]}
        />
        {!isLoading && graphViews.length === 0 && <div style={{ paddingTop: '1em' }}>No shared links. Click new to create one.</div>}
        {graphViews.length > 0 && (
          <DetailsList
            columns={[
              {
                key: 'url',
                name: 'URL',
                onRender: (item: any) => (
                  <TooltipHost content="Click to copy">
                    <Link onClick={() => navigator.clipboard.writeText(item.url)}>{item.url}</Link>
                  </TooltipHost>
                ),
                minWidth: 400
                // maxWidth: 20
              },
              {
                key: 'updatedAt',
                name: 'Last Updated',
                onRender: (item: any) => <span>{moment(item.updatedAt).calendar()}</span>,
                minWidth: 100
                // maxWidth: 5
              }
            ]}
            items={graphViews}
            selectionMode={SelectionMode.single}
            selection={selection.current}
          />
        )}
      </Panel> */
}
