import React, { useState } from 'react'
import { DetailsList, IColumn, SelectionMode, CheckboxVisibility, DetailsHeader, DetailsListLayoutMode } from 'office-ui-fabric-react'
import { IGraphVirusPhosSite } from 'krogan-types/dist/Graph'

interface IVirusPhosphorylationTableProps {
  virusName: string
  phosphorylationSites: {
    [siteId: string]: IGraphVirusPhosSite
  }
}

interface ICustomGraphVirusPhosSite {
  key: string
  name: string
  isSubHeader?: boolean
  '0 hpi': (string | number | null)[]
  '2 hpi': (string | number | null)[]
  '4 hpi': (string | number | null)[]
  '8 hpi': (string | number | null)[]
  '12 hpi': (string | number | null)[]
  '24 hpi': (string | number | null)[]
}

function clamp(num: number, min: number = 0, max: number = 1) {
  if (num < min) return min
  if (num > max) return max
  return num
}

function calcColorHpi(value: number | null, min: number = 15, max: number = 26) {
  if (value === null) return undefined

  const factor = clamp((value - min) / (max - min))
  return `rgba(255, 0, 0, ${factor})`
}

function getPhosphorylationColumns(): IColumn[] {
  const ret: IColumn[] = [
    {
      key: 'name',
      fieldName: 'name',
      name: 'Site',
      minWidth: 100,
      data: 'string',
      isPadded: true,
      isResizable: true,
      isRowHeader: true
    }
  ]

  const renderFunc = (colKey: keyof IGraphVirusPhosSite) => (item: ICustomGraphVirusPhosSite) => {
    const vals = item[colKey]
    return (
      <div className="site-subrow" style={{ backgroundColor: item.isSubHeader ? 'transparent' : undefined }}>
        {vals.map(value => {
          if (typeof value === 'string') {
            return (
              <div className="site-subrow-item" style={{ border: 0, backgroundColor: 'transparent' }}>
                {value}
              </div>
            )
          }

          return (
            <div className="site-subrow-item" style={{ backgroundColor: calcColorHpi(value) }}>
              {value ? value.toFixed(1) : undefined}
            </div>
          )
        })}
      </div>
    )
  }

  const l2Cols: (keyof IGraphVirusPhosSite)[] = ['0 hpi', '2 hpi']
  l2Cols.forEach(key => {
    ret.push({
      key: key,
      fieldName: key,
      name: key,
      minWidth: 100,
      maxWidth: 100,
      isPadded: false,
      isResizable: true,
      isCentered: true,
      className: 'site-block-column',
      onRender: renderFunc(key)
    } as IColumn)
  })

  const l3Cols: (keyof IGraphVirusPhosSite)[] = ['4 hpi', '8 hpi', '12 hpi', '24 hpi']
  l3Cols.forEach(key => {
    ret.push({
      key: key,
      fieldName: key,
      name: key,
      minWidth: 150,
      maxWidth: 150,
      isPadded: false,
      isCentered: true,
      isResizable: true,
      className: 'site-block-column',
      onRender: renderFunc(key)
    } as IColumn)
  })

  return ret
}

function VirusPhosphorylationTable(props: IVirusPhosphorylationTableProps) {
  const { virusName, phosphorylationSites } = props

  const phosphorylationSiteItems: ICustomGraphVirusPhosSite[] = Object.keys(phosphorylationSites).map(siteId => {
    return {
      key: siteId,
      name: `${virusName}_${siteId}`,
      ...phosphorylationSites[siteId]
    }
  })

  phosphorylationSiteItems.unshift({
    key: 'subheader',
    name: '',
    isSubHeader: true,
    '0 hpi': ['Rep1', 'Rep2'],
    '2 hpi': ['Rep1', 'Rep2'],
    '4 hpi': ['Rep1', 'Rep2', 'Rep3'],
    '8 hpi': ['Rep1', 'Rep2', 'Rep3'],
    '12 hpi': ['Rep1', 'Rep2', 'Rep3'],
    '24 hpi': ['Rep1', 'Rep2', 'Rep3']
  })

  const [columns, setColumns] = useState(getPhosphorylationColumns())

  return (
    <div className="info-table-outer">
      <div className="info-table">
        <DetailsList
          key={'details_list'}
          items={phosphorylationSiteItems}
          columns={columns}
          selectionMode={SelectionMode.none}
          checkboxVisibility={CheckboxVisibility.hidden}
          onShouldVirtualize={() => false}
          onRenderDetailsHeader={props => {
            return (
              <DetailsHeader
                {...props}
                styles={{
                  root: {
                    padding: 0
                  }
                }}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderColumnHeaderTooltip={headerProps => {
                  let isCentered = false
                  const col: any = headerProps?.column
                  if (col && col.isCentered) isCentered = true

                  return <h3 style={{ textAlign: isCentered ? 'center' : undefined }}>{headerProps?.column?.name}</h3>
                }}
              />
            )
          }}
        />
      </div>
    </div>
  )
}

export default VirusPhosphorylationTable
