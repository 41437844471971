import Cytoscape from 'cytoscape'

export interface IArrangeNodeOptions {
  zoomToViewAll?: boolean
  horizontalSpacing?: number
  verticalSpacing?: number
  layoutWidth?: number
  zoomToFitOptions?: IZoomToFitOptions
  presortFunc?: (nodeA: Cytoscape.NodeSingular, nodeB: Cytoscape.NodeSingular) => number
}

export function arrangeNodesInGrid(cy: Cytoscape.Core, nodes: Cytoscape.NodeCollection, options: IArrangeNodeOptions = {}) {
  const {
    zoomToViewAll = true,
    horizontalSpacing = 200,
    verticalSpacing = 200,
    zoomToFitOptions = {},
    layoutWidth = 6000,
    presortFunc
  } = options

  // all variables here are in model coordinates
  let prevX = 0
  let prevY = 0
  let maxX = 0
  const xGridOffset = horizontalSpacing
  const yGridOffset = verticalSpacing

  // allow up to 3x zoom horizontally
  let maxWidthPerRow = layoutWidth
  let maxHeightOnRow = 0

  if (presortFunc) {
    nodes = nodes.sort(presortFunc)
  }

  nodes.forEach(node => {
    const w = node.width()
    const h = node.height()

    // move to next row
    if (prevX + w > maxWidthPerRow) {
      prevX = 0
      prevY += maxHeightOnRow + yGridOffset
      maxHeightOnRow = 0
    }

    if (h > maxHeightOnRow) {
      maxHeightOnRow = h
    }

    if (prevX + w > maxX) maxX = prevX + w

    node.position({
      x: prevX + w / 2,
      y: prevY + h / 2
    })

    prevX += w + xGridOffset
  })

  if (zoomToViewAll) {
    zoomToFit(cy, zoomToFitOptions)
  }
}

interface IZoomToFitOptions {
  extraHorizontalZoomSpace?: number
  extraVerticalZoomSpace?: number
  reserveExtraWidth?: number
  reserveExtraHeight?: number
  xOffset?: number
  yOffset?: number
}

export function zoomToFit(cy: Cytoscape.Core, options: IZoomToFitOptions = {}) {
  const compoundNodes = cy.nodes().parents().not('.hidden')

  let firstTime = true
  let minX = 0
  let minY = 0
  let maxX = 0
  let maxY = 0

  compoundNodes.forEach(node => {
    const w = node.outerWidth()
    const h = node.outerHeight()

    const pos = node.position()
    const top = pos.y - h / 2
    const bottom = pos.y + h / 2
    const left = pos.x - w / 2
    const right = pos.x + w / 2

    if (firstTime) {
      firstTime = false
      minX = left
      minY = top
      maxX = right
      maxY = bottom
    }

    maxX = Math.max(maxX, right)
    maxY = Math.max(maxY, bottom)
    minX = Math.min(minX, left)
    minY = Math.min(minY, top)
  })

  const {
    extraHorizontalZoomSpace = 100, // reserve space on both left and right
    extraVerticalZoomSpace = 220, // reserve space on both top and bottom
    reserveExtraHeight = 70, // reserve space on bottom
    reserveExtraWidth = 0, // reserve space on right
    xOffset = 0, // reserve space on left
    yOffset = 0 // reserve space on top
  } = options

  const canvasWidth = cy.width() - reserveExtraWidth - xOffset
  const canvasHeight = cy.height() - reserveExtraHeight - yOffset

  // set zoom
  const hZoom = canvasWidth / (maxX - minX + extraHorizontalZoomSpace)
  const vZoom = canvasHeight / (maxY - minY + extraVerticalZoomSpace)
  const zoomLevel = Math.min(hZoom, vZoom)
  cy.zoom(zoomLevel)

  // pan in pixel coordinates
  cy.pan({
    x: (canvasWidth - (maxX + minX) * zoomLevel) / 2 + xOffset,
    y: (canvasHeight - (maxY + minY) * zoomLevel) / 2 + yOffset
  })
}

function getLabelPositionOnAngle(angle: number, start: string = 'label-bottom'): string {
  const labels = [
    'label-bottom',
    'label-bottom-right',
    'label-right',
    'label-top-right',
    'label-top',
    'label-top-left',
    'label-left',
    'label-bottom-left'
  ]
  const circleAngle = angle % (Math.PI * 2)
  const idx = Math.round(circleAngle / (Math.PI / 4))
  let startIdx = labels.indexOf(start)
  if (startIdx < 0) startIdx = 0
  const retIdx = (idx + startIdx) % labels.length

  return labels[retIdx]
}

function organizePPINetwork(
  ppiNodes: Cytoscape.NodeCollection,
  center: { x: number; y: number },
  radius: number,
  ppiStartAngle: number,
  allocatedAngle: number
) {
  if (ppiNodes.length <= 2) {
    const subAngleIncrement = allocatedAngle / ppiNodes.length
    const angleOffset = subAngleIncrement / 2

    ppiNodes.forEach((node, idx) => {
      const angle = ppiStartAngle + angleOffset + subAngleIncrement * idx
      const xOffset = Math.sin(angle) * radius
      const yOffset = Math.cos(angle) * radius

      const otherVirusesCount = node.data('otherVirusesCount') || 0
      const factor = 1 + otherVirusesCount / 6

      node.position({
        x: center.x + xOffset,
        y: center.y + yOffset
      })

      node.data({
        labelOffsetX: Math.sin(angle) * node.data('label').length * 10 * factor,
        labelOffsetY: Math.cos(angle) * 30 * factor
      })

      idx++
    })
  } else {
    const angle = ppiStartAngle + allocatedAngle / 2
    const xOffset = Math.sin(angle) * Math.max(radius - ppiNodes.length * 10, 30)
    const yOffset = Math.cos(angle) * Math.max(radius - ppiNodes.length * 10, 30)

    const subCircleRadius = 30 + ppiNodes.length * 16
    const subCircleAngleIncrement = (Math.PI * 2) / ppiNodes.length
    const startAngle = (Math.PI * 2 - angle) * 1.03 // .03 to add random noise to start position
    const subCircleCenter = {
      x: center.x + xOffset * (1 + subCircleRadius / radius),
      y: center.y + yOffset * (1 + subCircleRadius / radius)
    }

    ppiNodes.forEach((node, subIdx) => {
      const subAngle = startAngle + subIdx * subCircleAngleIncrement
      const subXOffset = Math.sin(subAngle) * subCircleRadius
      const subYOffset = Math.cos(subAngle) * subCircleRadius

      const otherVirusesCount = node.data('otherVirusesCount') || 0
      const factor = 1 + otherVirusesCount / 6

      node.position({
        x: subCircleCenter.x + subXOffset,
        y: subCircleCenter.y + subYOffset
      })

      node.data({
        labelOffsetX: Math.sin(subAngle) * node.data('label').length * 10 * factor,
        labelOffsetY: Math.cos(subAngle) * 30 * factor
      })
    })
  }
}

function organizeSubPreyNetwork(
  humanNetwork: Cytoscape.Collection,
  center: { x: number; y: number },
  radius: number,
  angleIncrement: number,
  idx: number
) {
  const ppiNetworks = humanNetwork
    .not('edge[!isHumanPPI]')
    .components()
    .map(c => c.nodes())

  const networkLength = humanNetwork.nodes().length

  // total angle allocated here
  const totalAngle = angleIncrement * networkLength

  // using only 2-thirds of that total angle...
  const startAngle = angleIncrement * (idx - 0.5) + totalAngle / 6 + Math.PI / 2
  const endAngle = startAngle + (totalAngle * 2) / 3

  const subAngleIncrement = (endAngle - startAngle) / networkLength
  let subIdx = 0

  const extendedRadius = radius + networkLength * 10 + (networkLength > 1 ? 80 : 0)

  ppiNetworks.forEach(ppiNetwork => {
    const angleAllocated = subAngleIncrement * ppiNetwork.length
    const subStartAngle = startAngle + subIdx * subAngleIncrement

    organizePPINetwork(ppiNetwork, center, extendedRadius, subStartAngle, angleAllocated)
    subIdx = subIdx + ppiNetwork.length
  })

  const complexes = humanNetwork.nodes().parent().filter('node.complex-or-process')
  complexes.forEach(node => {
    let classes: any = node.classes()
    classes = classes.filter((c: any) => !c.startsWith('label-'))
    const midAngle = (startAngle + endAngle) / 2
    const newClassName = getLabelPositionOnAngle(midAngle)
    classes.push(newClassName)

    node.classes(classes.join(' '))
  })
}

function organizeVirusNetwork(network: Cytoscape.Collection) {
  const humanNodes = network.nodes('.human')

  const humanToHumanEdges = network.edges('.human-to-human')
  const humanOnlyGraph = humanToHumanEdges.union(humanNodes)
  const humanNetworks = humanOnlyGraph.components()
  const virusNode = network.nodes('.virus').first()

  if (humanNodes.length === 0) return
  const numHumans = humanNodes.length
  const radius = numHumans * 8 + 80
  const angleIncrement = (Math.PI * 2) / numHumans

  const center = virusNode.position()
  let idx = 0

  humanNetworks.forEach(humanNetwork => {
    organizeSubPreyNetwork(humanNetwork, center, radius, angleIncrement, idx)
    idx += humanNetwork.nodes().length
  })
}

export function organizeCovid19Graph(cy: Cytoscape.Core, visibleOnly = false) {
  let virusNetworks = cy.elements('node.virus, node.human, edge.human-to-human, edge.virus-to-human').components()
  if (visibleOnly) {
    virusNetworks = virusNetworks.filter(network => {
      const virusNode = network.nodes('.virus').first()
      if (!virusNode || virusNode.hasClass('hidden')) return false
      return true
    })
  }

  virusNetworks.forEach(organizeVirusNetwork)
}

export interface INodeCollectionGroups {
  [key: string]: Cytoscape.Collection
}

export function applyFilter(
  cy: Cytoscape.Core,
  allGroups: INodeCollectionGroups,
  filterTerms: string[],
  checkForDanglingEdges: boolean = false
) {
  const filterTermsSet = new Set(filterTerms)
  let collectionToRestore: Cytoscape.Collection = cy.collection()

  Object.keys(allGroups).forEach(label => {
    if (filterTermsSet.has(label)) {
      collectionToRestore = collectionToRestore.union(allGroups[label])
    }
  })

  if (checkForDanglingEdges) {
    const allNodes = collectionToRestore.nodes()
    const allEdges = collectionToRestore.edges()
    const connectedEdges = allNodes.edgesTo(allNodes)
    let danglingEdges = allEdges.not(connectedEdges)
    collectionToRestore = collectionToRestore.not(danglingEdges)
  }

  cy.elements().not(collectionToRestore).addClass('hidden')
  collectionToRestore.removeClass('hidden')
}
