import React from 'react'
import './Header.css'

interface IProps {
  children: React.ReactNode
}

export default function Header(props: IProps) {
  return (
    <div className="header">
      <div className="header-left">
        <span>sars-cov-2</span>
        <span>virus-human protein-protein interaction map</span>
        <span>by the QCRG</span>
      </div>
      <div className="header-right">{props.children}</div>
    </div>
  )
}
