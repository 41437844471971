import React, { useState, useEffect, useContext } from 'react'
import { FirebaseContext } from './firebaseContext'
// import defaultData from '../assets/data.json'
import { TABLES, IGraphData } from 'krogan-types'

interface IGraph {
  data: IGraphData
  uploadId?: string
  updatedAt?: number
}

const defaultState: {
  graphData: IGraph
  graphDataIsLoading: boolean
  tableNames: Array<Array<string>>
  loadGraphData: { (): void }
  graphView: any
} = {
  graphData: {
    data: {
      humanData: {},
      humanToHuman: {},
      humanToDrug: {},
      virusData: {},
      virusToHumanEdges: {},
      virusToPhos: {},
      drugData: {},
      pdbData: {},
      virusToPDB: {},
      humanToPhos: {}
    },
    uploadId: '',
    updatedAt: 0
  },
  graphDataIsLoading: true,
  tableNames: Object.entries(TABLES).map(([tableName, { publicName }]) => {
    return [tableName, publicName]
  }),
  loadGraphData: () => {},
  graphView: {}
}

const GraphContext = React.createContext(defaultState)

const getQueryParams = (): { [key: string]: string } => {
  const hashFrags = window.location.hash.split('?')
  if (!hashFrags[1]) return {}
  return hashFrags[1].split('&').reduce((m: { [key: string]: string }, v: string) => {
    const [key, value] = v.split('=')
    if (key && value) m[key] = value
    return m
  }, {})
}

interface IGraphProviderProps {}

const GraphProvider: React.FunctionComponent<IGraphProviderProps> = props => {
  const { firebase } = useContext(FirebaseContext)
  const [graphData, setGraph] = useState(defaultState.graphData)
  const [graphDataIsLoading, setGraphIsLoading] = useState(defaultState.graphDataIsLoading)
  const [graphView, setGraphView] = useState(defaultState.graphView)

  const loadGraphData = async () => {
    setGraphIsLoading(true)
    // grab latest graph and use its data
    await firebase
      .firestore()
      .collection('graphs')
      .doc('latest')
      .get()
      .then(doc => {
        if (!doc.exists) {
          setGraphIsLoading(false)
          return
        }
        const _data = doc.data()
        if (_data && _data.data) setGraph(_data as IGraph)
      })

    const queryParams = getQueryParams()
    if (queryParams.gv) {
      await firebase
        .firestore()
        .collection('graphViews')
        .doc(queryParams.gv)
        .get()
        .then(doc => {
          if (!doc.exists) return
          setGraphView(doc.data())
          firebase.firestore().collection('graphViews').doc(queryParams.gv).update({
            lastAccessedAt: new Date().toISOString()
          })
        })
    }

    setGraphIsLoading(false)
  }

  useEffect(() => {
    loadGraphData()
  }, [])

  const state = {
    loadGraphData,
    graphData,
    graphDataIsLoading,
    tableNames: defaultState.tableNames,
    graphView
  }

  return <GraphContext.Provider value={state}>{props.children}</GraphContext.Provider>
}

const GraphViewIsLoaded: React.FunctionComponent = props => {
  const { graphView } = useContext(GraphContext)

  return <>{graphView.id ? props.children : null}</>
}

export { GraphContext, GraphProvider, GraphViewIsLoaded }
