import React from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'

import './App.css'
import Home from './routes/Home'
import Ingest from './routes/IngestRoute'
import { RootProviders } from './contexts'

import { Customizer } from 'office-ui-fabric-react'
import { Customizations } from './theme'
import ThemeToCSS from './theme/ThemeToCSS'

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <div className="App-content">
        <RootProviders>
          <ThemeToCSS />
          <Customizer {...Customizations}>
            <Router>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/ingest">
                  <Ingest />
                </Route>
              </Switch>
            </Router>
          </Customizer>
        </RootProviders>
      </div>
    </div>
  )
}

export default App
