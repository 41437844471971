import * as React from 'react'
import './index.css'
import Cytoscape from 'cytoscape'
import { deepCompare } from '../../utils'

export interface IGraphTipContentProps {
  element: Cytoscape.NodeSingular | Cytoscape.EdgeSingular
  cy: Cytoscape.Core
  onDismiss: () => void
}

export interface IGraphProps {
  elements: Cytoscape.ElementDefinition[] | Cytoscape.ElementsDefinition
  layout?: {
    name: string
    [prop: string]: any
  }
  // renderTipContent?: (props: IGraphTipContentProps) => React.ReactElement | null

  /** NOTE: for some reason wheel sensitivity cannot be changed after set initially */
  wheelSensitivity?: number
  zoomEnabled?: boolean
  minZoom?: number
  maxZoom?: number
  panEnabled?: boolean
  styles?: Cytoscape.Stylesheet[]
  cyRef?: (cy: Cytoscape.Core | null) => void
  style: React.CSSProperties
  onElementFocused?: (element: Cytoscape.EdgeSingular | Cytoscape.NodeSingular) => void
}

export interface IGraphStates {
  // nodeFocused?: Cytoscape.NodeSingular
}

class Graph extends React.Component<IGraphProps, IGraphStates> {
  graphContainerRef: { current: HTMLElement | null }
  cy?: Cytoscape.Core
  elementsHovered: Set<String>

  constructor(props: IGraphProps) {
    super(props)

    this.graphContainerRef = { current: null }
    this.cy = undefined
    this.elementsHovered = new Set()
    // this.state = {
    //   nodeFocused: undefined
    // }
  }

  componentDidMount() {
    const options: Cytoscape.CytoscapeOptions = {
      container: this.graphContainerRef.current,
      elements: this.props.elements,
      style: this.props.styles,
      wheelSensitivity: this.props.wheelSensitivity,
      minZoom: this.props.minZoom,
      maxZoom: this.props.maxZoom,
      zoomingEnabled: this.props.zoomEnabled,
      panningEnabled: this.props.panEnabled
    }

    if (this.props.layout) {
      options.layout = this.props.layout
    }

    this.cy = Cytoscape(options)
    this.cy.on('tap', 'node, edge', event => {
      if (!event.originalEvent.ctrlKey) {
        this.onElementTapped(event.target)
      }
    })

    this.cy.on('mouseover', 'node, edge', event => {
      this.elementsHovered.add(event.target.id())
      if (this.graphContainerRef.current) {
        this.graphContainerRef.current.style.cursor = 'pointer'
      }
    })

    this.cy.on('mouseout', 'node, edge', event => {
      this.elementsHovered.delete(event.target.id())
      if (this.elementsHovered.size === 0 && this.graphContainerRef.current) {
        this.graphContainerRef.current.style.cursor = ''
      }
    })

    this.cy.on('mousedown', event => {
      if (this.graphContainerRef.current) {
        this.graphContainerRef.current.style.cursor = 'grabbing'
      }
    })

    this.cy.on('mouseup', event => {
      if (this.graphContainerRef.current) {
        if (this.elementsHovered.size == 0) {
          this.graphContainerRef.current.style.cursor = ''
        } else {
          this.graphContainerRef.current.style.cursor = 'pointer'
        }
      }
    })

    if (this.props.cyRef) {
      this.props.cyRef(this.cy)
    }
  }

  onElementTapped = (node: Cytoscape.NodeSingular | Cytoscape.EdgeSingular) => {
    if (this.props.onElementFocused) this.props.onElementFocused(node)
  }

  componentDidUpdate(prevProps: IGraphProps, prevState: IGraphStates) {
    if (!this.cy) return

    if (this.props.layout && !deepCompare(prevProps.layout, this.props.layout)) {
      this.cy.layout(this.props.layout)
    }

    if (typeof this.props.zoomEnabled === 'boolean' && prevProps.zoomEnabled !== this.props.zoomEnabled) {
      this.cy.zoomingEnabled(this.props.zoomEnabled)
    }

    if (this.props.minZoom !== prevProps.minZoom) {
      this.cy.minZoom(this.props.minZoom || 1e-50)
    }

    if (this.props.maxZoom !== prevProps.maxZoom) {
      this.cy.maxZoom(this.props.maxZoom || 1e50)
    }

    if (typeof this.props.panEnabled === 'boolean' && prevProps.panEnabled !== this.props.panEnabled) {
      this.cy.panningEnabled(this.props.panEnabled)
    }

    if (this.props.styles && !deepCompare(prevProps.styles, this.props.styles)) {
      this.cy.style(this.props.styles)
    }
  }

  componentWillUnmount() {
    if (this.cy) {
      this.cy.clearQueue()
      this.cy.unmount()
      this.cy = undefined
    }

    if (this.props.cyRef) {
      this.props.cyRef(null)
    }
  }

  setGraphContainerRef = (node: HTMLElement | null) => {
    this.graphContainerRef.current = node
  }

  render() {
    return (
      <div className="graph-outer" style={this.props.style}>
        <div className="graph-container" ref={this.setGraphContainerRef} />
        <div className="tips-container">{this.props.children}</div>
      </div>
    )
  }
}

export default Graph
