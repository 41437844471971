import { stringToColor, colorToHex } from '../../../utils'
import { paletteLight } from '../../../theme'

interface CustomStylesheet {
  selector: string
  style: {
    [key: string]: string | number
  }
}

export const labelColor = paletteLight.neutralPrimary || ''
export const backgroundColor = paletteLight.white || ''
export const humanNodeSize = 32

export const selectedColorFunc = (hexColor: string): string => {
  const rgb = stringToColor(hexColor)
  rgb.r = rgb.r * 0.8 - 0.1
  rgb.g = rgb.g * 0.8 - 0.1
  rgb.b = rgb.b * 0.8 - 0.1
  return colorToHex(rgb)
}

export const humanColor = '#bbb'
export const humanStyle: CustomStylesheet = {
  selector: 'node.human',
  style: {
    backgroundColor: humanColor
  }
}
export const humanSelectedStyle: CustomStylesheet = {
  selector: 'node.human:selected',
  style: {
    backgroundColor: selectedColorFunc(humanColor)
  }
}

export const virusColor = '#c00'
export const virusStyle: CustomStylesheet = {
  selector: 'node.virus',
  style: {
    shape: 'diamond',
    backgroundColor: virusColor
  }
}
export const virusSelectedStyle: CustomStylesheet = {
  selector: 'node.virus:selected',
  style: {
    shape: 'diamond',
    backgroundColor: selectedColorFunc(virusColor)
  }
}

export const drugColor = '#ffa500'
export const drugTargetStyle: CustomStylesheet = {
  selector: 'node.human[drugs]',
  style: {
    backgroundColor: drugColor
  }
}
export const drugTargetSelectedStyle: CustomStylesheet = {
  selector: 'node.human[drugs]:selected',
  style: {
    backgroundColor: selectedColorFunc(drugColor)
  }
}

export const virusToHumanEdgeColor = '#138'
export const virusToHumanStyle: CustomStylesheet = {
  selector: 'edge.virus-to-human',
  style: {
    lineColor: virusToHumanEdgeColor,
    width: 2
  }
}

export const mistLineStyle: CustomStylesheet = {
  selector: 'edge.virus-to-human[mist]',
  style: {
    lineColor: `mapData(mist, 0.5, 1, ${backgroundColor}, ${virusToHumanEdgeColor})`
  }
}

export const specLineStyle: CustomStylesheet = {
  selector: 'edge.virus-to-human[avgSpec]',
  style: {
    width: 'mapData(avgSpec, 0, 180, 5, 18)'
  }
}

export const humanToHumanEdgeColor = '#333'
export const humanToHumanStyle: CustomStylesheet = {
  selector: 'edge.human-to-human',
  style: {
    lineColor: humanToHumanEdgeColor
  }
}

export const isHumanPPIStyle: CustomStylesheet = {
  selector: 'edge.human-to-human[!isHumanPPI]',
  style: {
    display: 'none'
  }
}

export const otherVirusesBorderColor = '#c00'
export const otherVirusesStyle: CustomStylesheet = {
  selector: 'node.human[otherViruses]',
  style: {
    borderColor: otherVirusesBorderColor,
    borderWidth: '2px'
  }
}

export const otherVirusesCountStyle: CustomStylesheet = {
  selector: 'node.human[otherVirusesCount]',
  style: {
    width: `mapData(otherVirusesCount, 0, 6, ${humanNodeSize}, 80)`,
    height: `mapData(otherVirusesCount, 0, 6, ${humanNodeSize}, 80)`
  }
}

export const phosStyle: CustomStylesheet = {
  selector: 'node.human[phosphorylationSites]',
  style: {
    backgroundImage: '/p_letter.png',
    backgroundFit: 'contain'
  }
}

export const virusPhosStyle: CustomStylesheet = {
  selector: 'node.virus[phosphorylation]',
  style: {
    backgroundImage: '/p_letter.png',
    backgroundWidth: '50%',
    backgroundHeight: '50%',
    'background-offset-y': '50%'
  }
}

export const complexColor = '#ffcf00'
export const processColor = '#00bfff'
export const compoundOpacity = 0.3

const stylesheet: CustomStylesheet[] = [
  // label and label offsets
  {
    selector: 'node',
    style: {
      width: humanNodeSize,
      height: humanNodeSize
    }
  },

  {
    selector: '.hidden',
    style: {
      display: 'none'
    }
  },

  {
    selector: 'node[label]',
    style: {
      label: 'data(label)',
      color: labelColor
    }
  },

  {
    selector: 'node[label][labelOffsetX][labelOffsetY]',
    style: {
      textValign: 'center',
      textHalign: 'center',
      'text-margin-x': 'data(labelOffsetX)',
      'text-margin-y': 'data(labelOffsetY)'
    }
  },

  // additional virus styles, prevent conflict with legend...
  {
    selector: 'node.virus',
    style: {
      width: 96,
      height: 96,
      textValign: 'center',
      textHalign: 'center',
      color: 'white',
      fontSize: '14px',
      fontWeight: 'bold'
    }
  },

  // compound or group selectors
  {
    selector: 'node.virus-compound',
    style: {
      shape: 'roundrectangle',
      backgroundColor: backgroundColor,
      backgroundOpacity: 0.5,
      textValign: 'top',
      textHalign: 'center',
      fontWeight: 'bold',
      fontSize: '80px'
    }
  },

  {
    selector: 'node.complex-or-process',
    style: {
      shape: 'roundrectangle',
      backgroundOpacity: compoundOpacity,
      fontSize: '36px'
    }
  },

  {
    selector: 'node.complex-or-process.Complex',
    style: {
      backgroundColor: complexColor
    }
  },

  {
    selector: 'node.complex-or-process.Process',
    style: {
      backgroundColor: processColor
    }
  },

  // align label positions
  {
    selector: 'node.label-top',
    style: {
      textValign: 'top',
      textHalign: 'center'
    }
  },
  {
    selector: 'node.label-top-right',
    style: {
      textValign: 'top',
      textHalign: 'right'
    }
  },
  {
    selector: 'node.label-right',
    style: {
      textValign: 'center',
      textHalign: 'right'
    }
  },
  {
    selector: 'node.label-bottom-right',
    style: {
      textValign: 'bottom',
      textHalign: 'right'
    }
  },
  {
    selector: 'node.label-bottom',
    style: {
      textValign: 'bottom',
      textHalign: 'center'
    }
  },

  {
    selector: 'node.label-bottom-left',
    style: {
      textValign: 'bottom',
      textHalign: 'left'
    }
  },
  {
    selector: 'node.label-left',
    style: {
      textValign: 'center',
      textHalign: 'left'
    }
  },
  {
    selector: 'node.label-top-left',
    style: {
      textValign: 'top',
      textHalign: 'left'
    }
  },

  {
    selector: 'node.virus-compound:selected',
    style: {
      backgroundColor: '#d2d2d2'
    }
  },

  {
    selector: 'edge',
    style: {
      width: 2
    }
  },

  humanStyle,
  humanSelectedStyle,
  virusStyle,
  virusSelectedStyle,
  drugTargetStyle,
  drugTargetSelectedStyle,

  virusToHumanStyle,
  mistLineStyle,
  specLineStyle,

  humanToHumanStyle,
  isHumanPPIStyle,
  otherVirusesStyle,
  otherVirusesCountStyle,
  phosStyle,
  virusPhosStyle
]

export default stylesheet
