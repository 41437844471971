import React, { useRef, useCallback, useEffect, useContext } from 'react'
import SmilesDrawer from 'smiles-drawer'
import { IGraphDrug } from 'krogan-types'
import { ThemeContext } from '../../contexts/theme'

interface IDrugInfoProps extends IGraphDrug {}

function DrawSmile(canvas: HTMLCanvasElement, tree: SmilesDrawer.ITree, theme?: string) {
  const rect = canvas.getBoundingClientRect()
  const drawer = new SmilesDrawer.Drawer({
    width: rect.width,
    height: rect.height
  })
  drawer.draw(tree, canvas, theme)
}

function DrugInfo(props: IDrugInfoProps) {
  const {
    Compound_Name,
    Target_SpecificProtein_Process,
    Source,
    Drug_Status,
    Activity_Description,
    Activity_Effect,
    Reference,
    Smiles,
    ZINC_ID,
    Purchase_notes
  } = props

  const { theme } = useContext(ThemeContext)
  const canvasElement = useRef<HTMLCanvasElement | null>(null)
  const treeRef = useRef<SmilesDrawer.ITree | null>(null)

  const setCanvasElement = useCallback(
    (node: HTMLCanvasElement | null) => {
      canvasElement.current = node
      if (canvasElement.current && treeRef.current) {
        DrawSmile(canvasElement.current, treeRef.current, theme)
      }
    },
    [treeRef, canvasElement, theme]
  )

  useEffect(() => {
    if (Smiles) {
      SmilesDrawer.parse(Smiles, tree => {
        treeRef.current = tree
        if (canvasElement.current) {
          DrawSmile(canvasElement.current, treeRef.current, theme)
        }
      })
    }
  }, [Smiles])

  useEffect(() => {
    if (canvasElement.current && treeRef.current) {
      DrawSmile(canvasElement.current, treeRef.current, theme)
    }
  }, [theme])

  return (
    <div className="tip-modal-content long-col-name">
      <div className="tip-row">
        <div className="tip-header">Drug Information</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Compound:</div>
        <div className="tip-col-value">{Compound_Name}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Target Specific Protein Process:</div>
        <div className="tip-col-value">{Target_SpecificProtein_Process}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Source:</div>
        <div className="tip-col-value">{Source}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Drug Status:</div>
        <div className="tip-col-value">{Drug_Status}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Activity Description:</div>
        <div className="tip-col-value">{Activity_Description}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Activity Effect:</div>
        <div className="tip-col-value">{Activity_Effect}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Reference:</div>
        <div className="tip-col-value">{Reference}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Smiles:</div>
        <div className="tip-col-value">{Smiles}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">ZINC ID:</div>
        <div className="tip-col-value">{ZINC_ID}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Purchase notes:</div>
        <div className="tip-col-value">{Purchase_notes}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-full">
          <canvas ref={setCanvasElement} style={{ width: '100%', height: '300px' }}></canvas>
        </div>
      </div>
    </div>
  )
}

export default DrugInfo
