import React, { useCallback, useState, useRef } from 'react'
import './index.css'
import DrugTable from './DrugTable'
import { ActionButton, Link } from 'office-ui-fabric-react'
import DrugInfo from './DrugInfo'
import { IGraphDrug } from 'krogan-types'
import { IGraphHumanPhos } from 'krogan-types/dist/Graph'
import PhosphorylationSitesTable from './PhosphorylationSitesTable'

interface IHumanInfoProps {
  preyGene?: string
  preys?: string
  uniprotProteinDescription?: string
  structuresPdb?: string
  uniprotFunction?: string
  uniprotFunctionInDisease?: string
  drugs?: {
    [drugName: string]: IGraphDrug
  }
  phosphorylationSites?: {
    [siteName: string]: IGraphHumanPhos
  }
  otherViruses?: string[]
  isHumanPPI?: boolean
  isComplexProcess?: string
  complexOrProcess?: string
}

function HumanInfo(props: IHumanInfoProps) {
  const {
    preyGene,
    preys,
    uniprotProteinDescription,
    structuresPdb,
    uniprotFunction,
    uniprotFunctionInDisease,
    drugs,
    otherViruses,
    isComplexProcess,
    complexOrProcess,
    phosphorylationSites
  } = props

  const [modalProps, setModalProps] = useState<{ type: string; [key: string]: any }>({
    type: ''
  })

  const infoDivRef = useRef<HTMLDivElement | null>(null)
  const setInfoDivRef = useCallback(
    (node: HTMLDivElement | null) => {
      infoDivRef.current = node
      if (node && node.parentElement) {
        node.parentElement.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    [infoDivRef]
  )

  const onDrugSelected = useCallback(
    item => {
      setModalProps({
        type: 'drug',
        data: item
      })
    },
    [setModalProps]
  )

  const onDismissDrug = useCallback(() => {
    const newModalProps = Object.assign({}, modalProps)
    newModalProps.type = ''
    setModalProps(newModalProps)
  }, [modalProps, setModalProps])

  const renderDrugs = drugs && Object.keys(drugs).length > 0
  const renderSites = phosphorylationSites && Object.keys(phosphorylationSites).length > 0

  const virusFamilies: { [fam: string]: string[] } = {}
  otherViruses?.forEach(virus => {
    const family = virus.split(' ')[0]
    if (!virusFamilies[family]) virusFamilies[family] = []
    virusFamilies[family].push(virus)
  })

  if (modalProps.type === 'drug') {
    return (
      <div ref={setInfoDivRef}>
        <ActionButton
          iconProps={{
            iconName: 'Back'
          }}
          text={`Return to ${preyGene}`}
          onClick={onDismissDrug}
        />
        <DrugInfo {...modalProps.data} />
      </div>
    )
  }

  return (
    <div className="tip-modal-content long-col-name">
      <div className="tip-row">
        <div className="tip-header">Prey Information</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Prey Gene:</div>
        <div className="tip-col-value">{preyGene}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Prey Uniprot ID:</div>
        <div className="tip-col-value">
          <Link target="_blank" href={`https://www.uniprot.org/uniprot/${preys?.trim()}`}>
            {preys}
          </Link>
        </div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Uniprot Protein Description:</div>
        <div className="tip-col-value">{uniprotProteinDescription}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Structures (PBD):</div>
        <div className="tip-col-value">{structuresPdb}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Uniprot Function:</div>
        <div className="tip-col-value">{uniprotFunction}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Uniprot Function in Disease:</div>
        <div className="tip-col-value">{uniprotFunctionInDisease}</div>
      </div>

      <div className="tip-row">
        <div className="tip-col-name">Other Pathogens: </div>
        <div className="tip-col-value">
          {Object.keys(virusFamilies).map(family => {
            return <div key={family}>{virusFamilies[family].join(', ')}</div>
          })}
        </div>
      </div>

      {isComplexProcess && (
        <div className="tip-row">
          <div className="tip-col-name">{isComplexProcess + ': '}</div>
          <div className="tip-col-value">{complexOrProcess}</div>
        </div>
      )}

      {renderDrugs && (
        <div className="tip-row" style={{ paddingTop: '24px' }}>
          <div className="tip-col-full" style={{ fontWeight: 'bold', textAlign: 'center' }}>
            Drugs
          </div>
        </div>
      )}

      {renderDrugs && (
        <div className="tip-row">
          <div className="tip-col-full">{drugs && <DrugTable drugs={drugs} onDrugSelected={onDrugSelected} />}</div>
        </div>
      )}

      {renderSites && (
        <div className="tip-row" style={{ paddingTop: '24px' }}>
          <div className="tip-col-full" style={{ fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center' }}>
            Phosphorylation Sites
          </div>
        </div>
      )}

      {renderSites && (
        <div className="tip-row">
          <div className="tip-col-full" style={{ fontWeight: 'bold', textAlign: 'center' }}>
            Regulated by SARS-CoV-2 Infection (<Link>Bouhaddou et. al., 2020</Link>)
          </div>
        </div>
      )}

      {renderSites && (
        <div className="tip-row">
          <div className="tip-col-full">
            {phosphorylationSites && <PhosphorylationSitesTable phosphorylationSites={phosphorylationSites} />}
          </div>
        </div>
      )}
    </div>
  )
}

export default HumanInfo
