import * as React from 'react'
import './Legend.css'
import Cytoscape, { ElementDefinition } from 'cytoscape'
import { Image, ImageFit } from 'office-ui-fabric-react'

interface INodeLegendArgs {
  classes: string
  type: 'node'
  label?: string
  paddingRight?: number
  style: Cytoscape.Stylesheet
}

interface IEdgeLegendArgs {
  classes: string
  type: 'edge'
  label?: string
  numLines?: number // 1 by default
  valRange?: number[] // no range by default
  valName?: string
  paddingRight?: number
  style: Cytoscape.Stylesheet
}

export type ILegendArgs = INodeLegendArgs | IEdgeLegendArgs

export interface ILegendProps extends React.HTMLAttributes<HTMLDivElement> {
  /** NOTE: for some reason wheel sensitivity cannot be changed after set initially */
  className?: string
  legends: ILegendArgs[]
  labelColor?: string
}

export interface ILegendStates {
  generatedImg?: string
}

export function parseLegends(legends: ILegendArgs[], labelColor?: string) {
  const offsetX = 24
  const nolabelOffsetX = 12
  const lineValueLabelOffset = 24
  const lineLength = 36
  const nodeSize = 32
  const offsetPerChar = 6
  let currentX = 24
  const y = 24

  const elementsArr: Cytoscape.ElementDefinition[][] = legends.map(legend => {
    // node
    if (legend.type === 'node') {
      const ret = [
        {
          data: {
            id: legend.classes,
            label: legend.label
          },
          classes: legend.classes,
          position: {
            x: currentX,
            y: y
          }
        }
      ]

      if (legend.label) currentX += nodeSize + offsetX + offsetPerChar * legend.label.length + (legend.paddingRight || 0)
      else currentX += nodeSize + nolabelOffsetX + (legend.paddingRight || 0)
      return ret
    }

    // edge with a gradation
    if (legend.valName && legend.numLines && legend.numLines > 1 && legend.valRange) {
      const elements: Cytoscape.ElementDefinition[] = []
      currentX += lineValueLabelOffset

      for (let i = 0; i < legend.numLines; i++) {
        const source = legend.classes + '_node_' + i + '_A'
        const target = legend.classes + '_node_' + i + '_B'
        const showLabel = i === Math.floor(legend.numLines / 2)
        const val = legend.valRange[0] + (i * (legend.valRange[1] - legend.valRange[0])) / (legend.numLines - 1)

        const showMin = i === 0
        const showMax = i === legend.numLines - 1

        elements.push(
          {
            data: {
              id: source,
              label: showMin || showMax ? String(val) : undefined
            },
            classes: 'hidden node-start',
            position: {
              x: currentX,
              y: y + (Math.floor(legend.numLines / 2) - i) * 8
            }
          },
          {
            data: {
              id: target,
              label: showLabel ? legend.label : undefined
            },
            classes: 'hidden node-end',
            position: {
              x: currentX + lineLength,
              y: y + (Math.floor(legend.numLines / 2) - i) * 8
            }
          },
          {
            data: {
              source,
              target,
              [legend.valName]: val
            },
            classes: legend.classes
          }
        )
      }

      if (legend.label) {
        currentX += lineLength + offsetPerChar * legend.label.length + offsetX + (legend.paddingRight || 0)
      } else {
        currentX += nolabelOffsetX + (legend.paddingRight || 0)
      }

      return elements
    }

    // edge without gradation
    else {
      const elements: ElementDefinition[] = []
      const source = legend.classes + '_node_A'
      const target = legend.classes + '_node_B'

      elements.push(
        {
          data: {
            id: source
          },
          classes: 'hidden node-start',
          position: {
            x: currentX,
            y
          }
        },
        {
          data: {
            id: target,
            label: legend.label
          },
          classes: 'hidden node-end',
          position: {
            x: currentX + lineLength,
            y
          }
        },
        {
          data: {
            source,
            target
          },
          classes: legend.classes
        }
      )

      if (legend.label) {
        currentX += lineLength + offsetPerChar * legend.label.length + offsetX + (legend.paddingRight || 0)
      } else {
        currentX += nolabelOffsetX + (legend.paddingRight || 0)
      }

      return elements
    }
  })

  const styles: Cytoscape.Stylesheet[] = legends.map(legend => legend.style)
  styles.push(
    {
      selector: 'node',
      style: {
        color: labelColor
      }
    },
    {
      selector: '[label]',
      style: {
        label: 'data(label)',
        'text-valign': 'center',
        'text-halign': 'right',
        'text-margin-x': 4,
        'text-margin-y': 0
      }
    },
    {
      selector: 'node.hidden',
      style: {
        'background-color': 'transparent',
        'background-opacity': 0
      }
    },
    {
      selector: 'node.node-start',
      style: {
        'text-halign': 'left',
        'text-margin-x': 10,
        'font-size': 12
      }
    },
    {
      selector: 'node.node-end',
      style: {
        'text-margin-x': -10
      }
    }
  )

  return {
    elements: elementsArr.reduce((prev, curr) => {
      prev.push(...curr)
      return prev
    }, []),
    styles
  }
}

export function generateLegendsImage(legends: ILegendArgs[], labelColor?: string) {
  return new Promise((resolve: (val: string) => void, reject) => {
    // temp div so that an image can be rendered
    const tempDiv = document.createElement('div')
    document.body.appendChild(tempDiv)

    // create cy
    const parsed = parseLegends(legends, labelColor)
    const options: Cytoscape.CytoscapeOptions = {
      container: tempDiv,
      layout: {
        name: 'preset',
        fit: false
      },
      style: parsed.styles,
      elements: parsed.elements
    }

    // use cy to generate image
    const cy = Cytoscape(options)
    let timeoutRef: NodeJS.Timeout | undefined = undefined
    cy.on('render', () => {
      if (timeoutRef) clearTimeout(timeoutRef)
      timeoutRef = setTimeout(() => {
        const imguri = cy.png({
          output: 'base64uri',
          full: true,
          scale: 8
        })

        // clean up
        cy.clearQueue()
        cy.unmount()
        document.body.removeChild(tempDiv)

        return resolve(imguri)
      }, 500)
    })
  })
}

class Legend extends React.Component<ILegendProps, ILegendStates> {
  constructor(props: ILegendProps) {
    super(props)
    this.state = {
      generatedImg: undefined
    }
  }

  async updateImage() {
    this.setState({
      generatedImg: await generateLegendsImage(this.props.legends, this.props.labelColor)
    })
  }

  componentDidMount() {
    this.updateImage()
  }

  componentDidUpdate(prevProps: ILegendProps, prevState: ILegendStates) {
    if (prevProps.legends !== this.props.legends) {
      this.updateImage()
    }

    if (prevProps.labelColor !== this.props.labelColor) {
      this.updateImage()
    }
  }

  render() {
    const { className, labelColor, legends, ...otherProps } = this.props

    return (
      <div className={['legend-outer', this.props.className].join(' ')} {...otherProps}>
        {this.state.generatedImg && <Image className={'legend-image'} src={this.state.generatedImg} imageFit={ImageFit.contain} />}
      </div>
    )
  }
}

export default Legend
